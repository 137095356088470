import React, { useState, useEffect, useContext } from "react";
import ReactGA from "react-ga4";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer";
import { FormInvestments } from "./Components/Form/FormInvestments";
import { Navbarcomp as Navbar } from "./Components/Navbarcomp";
import { About } from "./Pages/About";
import { FaqPage } from "./Pages/FAQ/FaqPage";
import { Investment } from "./Pages/Investment";
import { InvestmentMarkeplace } from "./Pages/InvestmentMarkeplace";
import { Landing } from "./Pages/Landing";
import NotFound from "./Pages/NotFound";
import { PersonalData } from "./Pages/PersonalData";
import { Portfolio } from "./Pages/Portfolio/Portfolio";
import { InvestmentOpportunity } from "./Pages/Portfolio/InvestmentOpportunity";
import { FormUpdateProfile } from "./Pages/Profile/FormUpdateProfile";
import { Success } from "./Pages/Success";
import { Tyc } from "./Pages/Tyc";
import "./Styles/styles.scss";
import { StoreContext } from "./utils/store";

ReactGA.initialize("G-XTW9PWX0XD");

function App() {
  const { profile } = useContext(StoreContext);

  const [successpage, setSuccesspage] = useState(false);
  const [nonavpage, setNonavpage] = useState(false);
  const [bgfooter, setBgfooter] = useState(" ");
  const [pathname, setPathname] = useState("/");
  const [scroll, setScroll] = useState(0);
  const [transparent, setTransparent] = useState(false);

  useEffect(() => {
    const handlescroll = () => {
      if (pathname === "/") {
        const scrollY = window.scrollY;
        setScroll(scrollY);
        setTransparent(scrollY <= window.innerHeight - 200);
      }
    };
    window.addEventListener("scroll", handlescroll);
    return () => {
      window.removeEventListener("scroll", handlescroll);
    };
  }, [pathname, scroll]);

  useEffect(() => {
    const pathname = window.location.pathname;
    setPathname(pathname);
    if (pathname === "/") {
      setTransparent(true);
    }
    setNonavpage(pathname.indexOf("/form") === 0 || pathname === "/login");
    setSuccesspage(pathname === "/success");
  }, []);
  return (
    <>
      <Navbar
        success={successpage ? "successpage" : "normalpage"}
        nonav={nonavpage ? "nonavpage" : "normalpage"}
        pathname={pathname}
        transparent={transparent}
      />
      {/* <a
        className="whatsapp"
        href="https://wa.me/message/S26MKBHPBLFHG1?src=qr"
        rel="noreferrer"
        target="_blank"
      >
        <div className="icon"></div>
      </a> */}
      {profile.id == 79 && (
        <>
          <chat-window ClientName="Toperty"></chat-window>
          <chat-button></chat-button>
        </>
      )}
      <Routes>
        <Route path="/marketplace" element={<InvestmentMarkeplace />} />
        <Route path="/" element={<Landing />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/tyc" element={<Tyc />} />
        <Route
          path="/investor/:registration_user"
          element={<InvestmentMarkeplace />}
        />
        <Route path="/personal-data" element={<PersonalData />} />
        <Route path="/success" element={<Success />} />
        <Route path="/investments/upload" element={<FormInvestments />} />
        <Route path="/investment/:id" element={<Investment />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio/:registration_user" element={<Portfolio />} />
        <Route
          path="/PortfolioInvestment/:prop_id"
          element={<InvestmentOpportunity />}
        />
        <Route path="/profile" element={<FormUpdateProfile />} />
        <Route
          path="/profile/:registration_user"
          element={<FormUpdateProfile />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer nofoot={nonavpage ? "nofootpage" : "normalpage"} bg={bgfooter} />
    </>
  );
}

export default App;
