import React from 'react';

export function LegalInfo() {
  return (
    <div className='mb-5 w-100'>
      <h4 className='invest-title'> Estructura Legal de la Inversión</h4>

      <p className='gray my-4'>
        Escrituración del inmueble a nombre de Toperty S.A.S u otra Sociedad de
        Toperty S.A.S y firma de contratos de colaboración o cuentas en
        participación con los inversionistas, donde Toperty es el partícipe
        principal y el inversionista el participe oculto. Toperty S.A.S es
        responsable de distribuir de forma mensual los rendimientos que
        corresponden a los inversionistas ocultos.
      </p>
    </div>
  );
}
