import React, { useContext, useState } from 'react';
import { InvestModal } from '../Components/InvestModal';
import { StoreContext } from '../utils/store';

export function Landing() {
  const [showInvest, setShowInvest] = useState(false);
  const { loggedIn, setShowLogin } = useContext(StoreContext);
  const handleLoginShow = () => {
    setShowLogin(true);
  };
  const handleShow = (e) => {
    //console.log("executing...")
    e.preventDefault();
    loggedIn ? setShowInvest(true) : handleLoginShow();
  };
  const handleportfolio = (e) => {
    e.preventDefault();
    loggedIn ? (window.location.href = '/portfolio') : handleLoginShow();
  };
  const closeInvestModal = () => {
    setShowInvest(false);
  };
  return (
    <>
      <div className='land-container'>
        <InvestModal
          show={showInvest}
          handleClose={closeInvestModal}
          type='contact'
        />
        <section className='first-land'>
          <h2 className='welcome'>Invierte con impacto en propiedades.</h2>
          <p className='welcome-sub'>
            Invierte en finca raíz, construye tu portafolio y obtén
            rentabilidades atractivas mientras ayudas a personas a cumplir su
            sueño de tener vivienda propia.
          </p>
          <a href='/marketplace'>
            <button className='btn-rounded-green'>¡Comienza ahora!</button>
          </a>
          {/*<button className='btn-rounded-invert'>Ver Video</button>*/}
          <div className='partners'>
            <a
              href='https://www.brikss.com/'
              target='_blank'
              rel='noreferrer'>
              <div className='pygma'></div>
            </a>
            <a
              href='https://www.pygma.co/'
              target='_blank'
              rel='noreferrer'>
              <div className='brikss'></div>
            </a>
            <a
              href='https://eship.georgetown.edu/georgetownstartupaccelerator/'
              target='_blank'
              rel='noreferrer'>
              <div className='georgetown'></div>
            </a>
          </div>
        </section>
        <section className='second-land'>
          <div className='info'>
            <h3>Mayor rentabilidad</h3>
            <p>
              Nuestras proyecciones de rentabilidad superan lo que rentaron en
              los últimos 5 años otras alternativas en el mercado.
            </p>
          </div>
          <div className='graph' />
        </section>
        <section className='third-land'>
          <h3 className='title'>¿Cómo funciona?</h3>
          <div className='section rent'>
            <div className='icon' />
            <h3>Rent to Own</h3>
            <p>
              Cuando un usuario elige la vivienda de sus sueños, se genera una
            </p>
            <p>oportunidad en el portal de inversionistas.</p>
            <div className='divider' />
          </div>
          <div className='section'>
            <div className='info'>
              <h3>1. Busca</h3>
              <p className='description'>
                Elige entre las diferentes oportunidades de inversión en
                propiedades de nuestros usuarios.
              </p>
            </div>
            <div className='image' />
            <div className='divider' />
          </div>
          <div className='section'>
            <div className='image' />
            <div className='info'>
              <h3>2. Invierte</h3>
              <p className='description'>
                Determina cuánto te gustaría invertir, revisa los términos y
                realiza el pago por tu inversión.
              </p>
            </div>
            <div className='divider' />
          </div>
          <div className='section'>
            <div className='info'>
              <h3>3. Disfruta</h3>
              <p className='description'>
                Recibe tu ingreso mensual y monitorea tu portafolio de
                inversión.
              </p>
            </div>
            <div className='image' />
          </div>
        </section>
        <section className='fourth-land'>
          <h3 className='title'>Los números nos respaldan</h3>
          <div className='stats'>
            <div className='stat'>
              <h1 className='sup'>3</h1>
              <p>Personas y familias que hemos ayudado por el momento.</p>
            </div>
            <div className='stat'>
              <h1 className='sup'>+$900M</h1>
              <p>
                Monto recaudado de personas que están invirtiendo con
                propóosito.
              </p>
            </div>
            <div className='stat'>
              <h1 className='sup'>+10</h1>
              <p>Número de inversionistas que han invertido con nosotros.</p>
            </div>
          </div>
          <div className='portfolio-btn'>
            <button
              className='btn-rounded-green'
              onClick={handleportfolio}>
              Crea tu portafolio
            </button>
          </div>
          <div className='rectangle-container'>
            <div className='rectangle'>
              <div className='side-img'>
                <img
                  src={require('../Img/landing/rectangle-side-img.jpg')}
                  alt='land'
                />
              </div>
              <div className='content'>
                <h3>Ayudamos a personas a cumplir su sueño.</h3>
                <p>Conoce más sobre Toperty.</p>
                <div className='btn-container'>
                  <a href='https://toperty.co/'>
                    <button className='btn-rounded-invert'>Conoce más</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='why'>
            <h3 className='title'>¿Por qué invertir con Toperty?</h3>
            <div className='reason'>
              <div className='icon'>
                <p>💰</p>
              </div>
              <p className='title'>Construye tu patrimonio</p>
              <p className='content'>
                Encuentra oportunidades de inversión respaldadas por inmuebles
                con rentabilidades atractivas y sostenibles.
              </p>
            </div>
            <div className='reason'>
              <div className='icon'>
                <p>✊</p>
              </div>
              <p className='title'>Invierte con propósito</p>
              <p className='content'>
                Aumenta tu patrimonio mientras inviertes en personas y familias
                que quieren cumplir el sueño de tener vivienda propia.
              </p>
            </div>
            <div className='reason'>
              <div className='icon'>
                <p>💡</p>
              </div>
              <p className='title'>Reducimos el riesgo de vacancia</p>
              <p className='content'>
                Nuestros usuarios están comprometidos a permanecer en la
                propiedad ya que la están adquiriendo.
              </p>
            </div>
            <div className='reason'>
              <div className='icon'>
                <p>📈</p>
              </div>
              <p className='title'>Apreciación de la propiedad</p>
              <p className='content'>
                Invertimos por debajo de precio de mercado y pactamos con
                nuestros usuarios una apreciación fija a lo largo del programa.
              </p>
            </div>
            <div className='reason'>
              <div className='icon'>
                <p>✨</p>
              </div>
              <p className='title'>Invierte desde montos bajos</p>
              <p className='content'>
                Invierte en fracciones de propiedades y construye tu propio
                portafolio de inversión.
              </p>
            </div>
            <div className='reason'>
              <div className='icon'>
                <p>🏖️</p>
              </div>
              <p className='title'>Sin preocupaciones</p>
              <p className='content'>
                Nos encargamos de la gestión de la propiedad y del programa
                mientras tu disfrutas de tus rendimientos financieros.
              </p>
            </div>
          </div>
          <div className='portfolio-btn'>
            <button
              className='btn-rounded-green'
              onClick={handleportfolio}>
              Crea tu portafolio
            </button>
          </div>
        </section>
        <section className='fifth-land'>
          <h3 className='title'>Te podrás estar preguntando...</h3>
          <div className='land-faqs'>
            <div className='faq'>
              <p className='question'>
                ¿Cuál es el plazo promedio de la inversión?
              </p>
              <p className='answer'>
                El programa de “Rent to Own” puede durar entre 3 y 5 años,
                tiempo en el cual se liquida la inversión y sus rendimientos.
              </p>
            </div>
            <div className='faq'>
              <p className='question'>
                ¿Cómo se realiza el proceso de inversión?
              </p>
              <p className='answer'>
                Debes crear tu cuenta, completar los datos en tu perfil, firmar
                de forma digital los contratos de inversión de cada oportunidad
                y hacer el abono del monto que desees invertir.
              </p>
            </div>
            <div className='faq'>
              <p className='question'>
                ¿Toperty hace un estudio de crédito a los usuarios?
              </p>
              <p className='answer'>
                Evaluamos ingresos y cobertura frente cuota mensual,
                endeudamiento, score de crédito, entre otros. El estudio de
                crédito se efectúa con proveedores especializados en
                verificación crediticia y de antecedentes.
              </p>
            </div>
            <div className='faq'>
              <p className='question'>
                ¿Cómo se distribuyen los pagos a los inversionistas?
              </p>
              <p className='answer'>
                Los rendimientos son distribuidos de forma mensual en las fechas
                de corte que tenemos establecidas en los contratos del programa
                con los usuarios.
              </p>
            </div>
          </div>
          <div className='faq-btn'>
            <a href='/faq'>
              <button className='btn-rounded-invert'>Ver FAQs</button>
            </a>
          </div>
        </section>
        <section className='sixth-land'>
          <h3 className='title'>
            Conoce lo que otros inversionistas dicen sobre nosotros.
          </h3>

          <div className='test-container'>
            <div className='test-card'>
              <p className='testimonial'>
                Cuando uno busca invertir un dinero lo que busca es que sea
                seguro y que gane rentabilidad y eso fue lo que encontré en
                Toperty. Lo que más me gustó es que ayuda uno a otras familias a
                tener un hogar seguro.
              </p>
              <div className='test-footer'>
                <p className='circle'>OB</p>
                <div className='person'>
                  <p className='name'>Olga Lucía Bernal</p>
                  <p className='info'>Inversionista Puntacana</p>
                </div>
              </div>
            </div>
            <div className='test-card'>
              <p className='testimonial'>
                Toperty me permitió invertir en una fracción de una propiedad
                fácilmente, generando ingresos y apoyando el sueño de otras
                personas. ¡Gran experiencia de inversión!
              </p>
              <div className='test-footer'>
                <p className='circle'>FM</p>
                <div className='person'>
                  <p className='name'>Felipe Maldonado</p>
                  <p className='info'>Inversionista Puntacana</p>
                </div>
              </div>
            </div>
            <div className='test-card'>
              <p className='testimonial'>
                Invertí con Toperty y el proceso es muy fácil. Ni me tengo que
                preocupar, mis rendimientos llegan todos los meses y ellos se
                encargan de todo.
              </p>
              <div className='test-footer'>
                <p className='circle'>JG</p>
                <div className='person'>
                  <p className='name'>Juan Carlos Galindo</p>
                  <p className='info'>Inversionista Veramonte</p>
                </div>
              </div>
            </div>
          </div>
          <div className='purple-card'>
            <h3>¿Qué estás esperando? </h3>
            <p>
              Estamos emocionados de darte la bienvenida a la comunidad Toperty.
              ¡Trae a tus amigos y familiares y co-invierte con ellos!
            </p>
            <button
              className='btn-rounded-green'
              onClick={handleShow}>
              Invertir
            </button>
          </div>
        </section>
      </div>
    </>
  );
}
