import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
	propertyInputsData, tenantInputsData
} from "../.././utils/investmentInputs";
import { Input } from "../Input";
import {
	Validations
} from "./InvestmentFormValidations";
import { successForm } from "./SubmitNewProperty";
import { ImageUploader } from "./UploadFiles";

export function FormInvestments() {
	let successform = new successForm();
	const [validate, setValidate] = useState(false);
	const [searchingInvestmentProject, setSearchingInvestmentProject] = useState(false);
	const [investmentProject, setInvestmentProject] = useState(false);
	const [emptyValidations, setEmptyValidations] =
		useState([]);
	const [inputErrors, setInputErrors] =
		useState([]);
	const [sendingData, setSendingData] = useState(false);
	const [sentPropData, setSentPropData] = useState(false)

	function handleSubmit() {
		setValidate(true);
	}

	async function handleInvestmentName() {
		setSearchingInvestmentProject(true)
		
		await successform.searchInvestmentProperty(propertyData["investment_name"]).then((getResponse) => {
			let temporaryPropData = propertyData;
			propertyInputsData.map((inputElement) => {

				if ((inputElement["inputKey"] in getResponse)) {
					temporaryPropData = {
						...temporaryPropData,
						[inputElement["inputKey"]]: getResponse[inputElement["inputKey"]],
						lastModified: inputElement["inputKey"],
					}
				}
			})
			setPropertyData(
				temporaryPropData
			);
		}).catch(function (error) {
			if (error.response) {
				// Request made and server responded
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			} else if (error.request) {
				// The request was made but no response was received
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
		})
		await successform.searchInvestmentTenant().then((getResponse) => {
			let temporaryTenantData = tenantData;
			tenantInputsData.map((inputElement) => {
				if ((inputElement["inputKey"] in getResponse)) {
					temporaryTenantData = {
						...temporaryTenantData,
						[inputElement["inputKey"]]: getResponse[inputElement["inputKey"]],
						lastModified: inputElement["inputKey"],
					}
				}
			})
			setTenantData(
				temporaryTenantData
			);
		}).catch(function (error) {
			if (error.response) {
				// Request made and server responded
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			} else if (error.request) {
				// The request was made but no response was received
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
		})
		setSearchingInvestmentProject(false)
		setInvestmentProject(true)

	}


	const [propertyData, setPropertyData] = useState({
		investment_name: "",
		down_payment: "",
		principal_payment: "",
		agent: "",
		antiquity: "",
		city: "",
		upz: "",
		address: "",
		type: "",
		market_value: "",
		administration: "",
		acquisition_cost: "",
		toperty_valuation: "",
		economic_classification: "",
		floor: "",
		parking_spaces: "",
		construction_date: "",
		bedrooms: "",
		bathrooms: "",
		total_area: "",
		living_area: "",
		description: "",
		amenities: [],
		photos: [{ image_url: "" }],
		lastModified: "",
		images_number: 0,
	});

	const [tenantData, setTenantData] = useState({
		name: "",
		email: "",
		birthdate: "",
		phone: "",
		education_level: "",
		personal_income: "",
		household_income: "",
		household_savings: "",
		document_number: "",
		document_type: "",
		document_creation_date: "",
		living_address: "",
		living_city: "",
		nationality: "",
		home_status: "",
		marriage_status: "",
		occupation: "",
		working_company: "",
		roommates: "",
		moving_situation: "",
		credit_score: "",
		current_debt: "",
		debt_percentage: "",
		monthly_rent: "",
		assets: [],
		lastModified: "",
	});

	const errorMessages = {
		emptyValue: "Campo requerido.",
		investment_name: "Introduce un nombre válido para el proyecto",
		name: "Introduce un nombre válido.",
		email: "Introduzca una dirección de mail válida",
		birthdate: "Introduzca una fecha válida",
		phone: "Introduzca un número de teléfono válido con diez dígitos, si es un teléfono fijo recuerde agregar el indicativo nacional",
		education_level: "Escoja un nivel de educación válido",
		personal_income: "Introduzca un número entero válido",
		household_income: "Introduzca un número entero válido",
		household_savings: "Introduzca un número entero válido",
		document_type: "Ingrese un tipo de documento válido",
		date: "Ingrese una fecha válida en formato dd/mm/aaaa",
		roommates: "Introduzca un número entero válido de personas",
		dataPolicy:
			"Debes aceptar nuestra política de datos para continuar.",
		city: "Selecciona una ciudad válida",
		neighborhood:
			"Selecciona una opción válida para continuar.",
		address: "Este campo es requerido",
		type: "Selecciona una opción válida para continuar.",
		cost: "El campo es requerido, verifica los datos ingresados.",
		administration:
			"El campo es requerido, verifica los datos ingresados.",
		economic_class:
			"Selecciona una opción válida para continuar.",
		floor:
			"El campo es requerido, verifica los datos ingresados.",
		parkings:
			"El campo es requerido, verifica los datos ingresados.",
		construction_date:
			"El campo es requerido, verifica los datos ingresados.",
		rooms:
			"El campo es requerido, verifica los datos ingresados.",
		bathrooms:
			"El campo es requerido, verifica los datos ingresados.",
		private_area:
			"El campo es requerido, verifica los datos ingresados.",
		living_area:
			"El campo es requerido, verifica los datos ingresados.",
		description:
			"El campo es requerido, verifica los datos ingresados.",
		photos: "",
	};

	const handlePropertyData = (k, v) => {
		if ((k !== "amenities") & (k !== "photos")) {
			setPropertyData({
				...propertyData,
				[k]: v,
				lastModified: k,
			});
		} else if (k === "amenities") {
			const isChecked = v.target.checked;
			if (isChecked) {
				setPropertyData({
					...propertyData,
					[k]: [...propertyData[k], v.target.value],
				});
			} else {
				let index = propertyData[k].indexOf(v.target.value);
				propertyData[k].splice(index, 1);
				setPropertyData({ ...propertyData, [k]: propertyData[k] });
			}
		} else if (k === "photos") {
			setPropertyData({
				...propertyData,
				lastModified: k,
				images_number: propertyData["photos"].length,
			});
		}
	};

	const handleTenantData = (k, v) => {
		if ((k !== "assets")) {
			setTenantData({
				...tenantData,
				[k]: v,
				lastModified: k,
			});
		} else {
			const isChecked = v.target.checked;
			if (isChecked) {
				setTenantData({
					...tenantData,
					[k]: [...tenantData[k], v.target.value],
				});
			} else {
				let index = tenantData[k].indexOf(v.target.value);
				tenantData[k].splice(index, 1);
				setTenantData({ ...tenantData, [k]: tenantData[k] });
			}
		}
	};

	useEffect(() => {
		const keyDownHandler = (event) => {
			if (event.key === "Enter") {
				event.preventDefault();
				document.getElementById("NextBtn").click();
			}
		};
		document.addEventListener("keydown", keyDownHandler);

		return () => {
			document.removeEventListener(
				"keydown",
				keyDownHandler
			);
		};
	}, []);


	useEffect(() => {
		if (validate) {
			let errors = Validations(
				propertyData,
				tenantData
			);
			console.log(errors)
			setValidate(false);
			setInputErrors(errors);
			if (errors.length === 0) {
				setSendingData(true)
				setTimeout(() => {
					successform.main(tenantData, propertyData);
				}, 2000);

			}
		}
	}, [validate]);



	return (
		<Container fluid>
			<Row className="header sellpage"></Row>
			<Row className="justify-content-center content">
				<Col md={6} xs={10}>
					<div className="logo mb-4"></div>

					<h1 className="bolder">
						Formulario subida de oportunidades de inversión
					</h1>

					<p>
						Completando el siguiente formulario se listará un
						nuevo inmueble en nuestro marketplace para
						inversionistas o se modificarán los datos de un
						inmueble que ya está en nuestra base de datos."
					</p>

					<Input
						inputType="text"
						inputKey="investment_name"
						inputLabel={
							<b className="fs-4">Nombre del proyecto</b>
						}

						inputValue={propertyData["investment_name"]}
						formData={propertyData}
						handleFormData={handlePropertyData}
					/>

					<div className="error-msg">
						{inputErrors.includes("investment_name") && (
							<p>
								{errorMessages["investment_name"]}
							</p>
						)}
						{emptyValidations.includes("investment_name") && (
							<p>{errorMessages["emptyValue"]}</p>
						)}
					</div>

					<button
						className="btn-rounded-green mt-3"
						id="NextBtn"
						onClick={handleInvestmentName}
						disabled={investmentProject === true}
					>
						Aceptar
					</button>
					{searchingInvestmentProject && (
						<div>
							<h1 className="bolder">
								Buscando en proyectos existentes
								<div className="lds-ellipsis">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</h1>
						</div>
					)}
					{investmentProject &&
						(<div>
							<h2 className="bolder">
								Datos del inquilino del inmueble
							</h2>
							{tenantInputsData.map((inputElement) => {
								switch (inputElement["inputType"]) {
									case "text":
										return (
											<Input
												inputLabel={inputElement["inputLabel"]}
												inputType="text"
												inputKey={inputElement["inputKey"]}
												inputValue={tenantData[inputElement["inputKey"]]}
												formData={tenantData}
												handleFormData={handleTenantData}
											/>
										)
									case "mail":
										return (
											<Input
												inputLabel={inputElement["inputLabel"]}
												inputType="mail"
												inputKey={inputElement["inputKey"]}
												inputValue={tenantData[inputElement["inputKey"]]}
												formData={tenantData}
												handleFormData={handleTenantData}
											/>
										)
									case "percentage":
										return (
											<Input
												inputLabel={inputElement["inputLabel"]}
												inputType="percentage"
												inputKey={inputElement["inputKey"]}
												inputValue={tenantData[inputElement["inputKey"]]}
												formData={tenantData}
												handleFormData={handleTenantData}
											/>
										)
									case "select":
										return (
											<Input
												inputType="select"
												inputLabel={inputElement["inputLabel"]}
												select={inputElement["options"]}
												inputId={inputElement["inputId"]}
												inputValue={tenantData[inputElement["inputKey"]]}
												inputKey={inputElement["inputKey"]}
												formData={tenantData}
												handleFormData={handleTenantData}
											/>
										)
									case "money":
										return (
											<Input
												inputLabel={inputElement["inputLabel"]}
												inputType="money"
												inputKey={inputElement["inputKey"]}
												inputId={inputElement["inputId"]}
												inputValue={tenantData[inputElement["inputKey"]]}
												formData={tenantData}
												handleFormData={handleTenantData}
											/>
										)
									case "multiCheckbox":
										return (
											<Input
												inputType="multiCheckbox"
												checkbox={inputElement["options"]}
												inputLabel={inputElement["inputLabel"]}
												inputValue={tenantData[inputElement["inputKey"]]}
												inputKey={inputElement["inputKey"]}
												formData={tenantData}
												handleFormData={handleTenantData}
											/>
										)
								}
							})}

							<h2 className="bolder">
								Datos del inmueble
							</h2>

							{propertyInputsData.map((inputElement) => {
								let
									{ errorHtml } = (
										<div className="error-msg">
											{inputErrors.includes(inputElement["inputKey"]) && (
												<p>
													{errorMessages[inputElement["inputKey"]]}
												</p>
											)}
											{emptyValidations.includes(inputElement["inputKey"]) && (
												<p>{errorMessages["emptyValue"]}</p>
											)}
										</div>
									);
								if (inputElement["inputKey"] === "neighborhood") {
									return (
										<>
											<Input
												inputType="text"
												inputLabel={
													<>
														<b className="fs-4">Barrio</b>
														<br />
														consulta{" "}
														<a
															target="_blank"
															rel="noreferrer"
															className="underlined fs-4"
															href="https://es.wikipedia.org/wiki/Anexo:Barrios_de_Bogot%C3%A1"
														>
															acá{" "}
														</a>
														la guía de barrios y unidad de planeamiento
														zonal (UPZ) en Bogotá.
													</>
												}
												inputValue={propertyData["neighborhood"]}
												inputKey="neighborhood"
												formData={propertyData}
												handleFormData={handlePropertyData}
											/>
											{errorHtml}
										</>
									)
								} else if (inputElement["inputKey"] === "") {
									return (
										<>
											<Input
												inputLabel={
													<>
														<b className="fs-4">
															Breve descripción del inmueble
														</b>
														<br />
														Ejemplo: "Espectacular apartamento para
														estrenar. El edificio tiene en el primer piso
														un área de multitrabajo (coworking) y control
														de acceso automatizado, servicio de
														conserjerí­a de lunes a sábado".
													</>
												}
												inputType="textarea"
												inputKey="description"
												inputValue={propertyData["description"]}
												formData={propertyData}
												handleFormData={handlePropertyData}
											/>
											{errorHtml}
										</>)

								}
								switch (inputElement["inputType"]) {
									case "text":
										return (
											<>
												<Input
													inputLabel={inputElement["inputLabel"]}
													inputType="text"
													inputKey={inputElement["inputKey"]}
													inputValue={propertyData[inputElement["inputKey"]]}
													formData={propertyData}
													handleFormData={handlePropertyData}
												/>
												{errorHtml}
											</>
										)
									case "select":
										return (
											<>
												<Input
													inputType="select"
													inputLabel={inputElement["inputLabel"]}
													select={inputElement["options"]}
													inputId={inputElement["inputId"]}
													inputValue={propertyData[inputElement["inputKey"]]}
													inputKey={inputElement["inputKey"]}
													formData={propertyData}
													handleFormData={handlePropertyData}
												/>
												{errorHtml}
											</>
										)
									case "money":
										return (
											<>
												<Input
													inputLabel={inputElement["inputLabel"]}
													inputType="money"
													inputKey={inputElement["inputKey"]}
													inputId={inputElement["inputId"]}
													inputValue={propertyData[inputElement["inputKey"]]}
													formData={propertyData}
													handleFormData={handlePropertyData}
												/>
												{errorHtml}
											</>
										)
									case "multiCheckbox":
										return (
											<>
												<Input
													inputType="multiCheckbox"
													checkbox={inputElement["options"]}
													inputLabel={inputElement["inputLabel"]}
													inputValue={propertyData[inputElement["inputKey"]]}
													inputKey={inputElement["inputKey"]}
													formData={propertyData}
													handleFormData={handlePropertyData}
												/>
												{errorHtml}
											</>
										)
								}
							})}
						</div>)
					}

					{(sendingData && !sentPropData) && (

						<h1 className="bolder">
							Enviando datos
							<div className="lds-ellipsis">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</h1>
					)}
					{sentPropData &&
						<h1 className="bolder">
							Datos enviados correctamente.
							<div className="lds-ellipsis">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</h1>
					}

					<button
						className="btn-rounded-green mt-3"
						id="NextBtn"
						onClick={handleSubmit}
						disabled={investmentProject === false}
					>
						{"Subir"}
						→
					</button>
					{ImageUploader()}
				</Col>
			</Row>
		</Container>
	);
}
