import React, { useContext } from 'react';
/* import ReactGA from 'react-ga4'; */
import { googleLogout } from '@react-oauth/google';
import { StoreContext } from '../utils/store';
import { LoginModal as Modal } from './ModalView';

function collapse() {
  let center = document.getElementById('navbar-center');
  let icon = document.getElementById('toggler-icon');
  if (center) {
    icon.classList.toggle('active');
    center.classList.toggle('active');
  } else console.log('not-ok');
}
export function Navbarcomp(props) {
  const { loggedIn, setLoggedIn, showLogin, setShowLogin } =
    useContext(StoreContext);
  const { user, profile, setProfile } = useContext(StoreContext);
  const handleLogout = () => {
    if (profile) {
      googleLogout();
      setProfile(null);
    }
    setLoggedIn(false);
    window.location.href = '/';
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('profile');
  };

  const closeLoginModal = () => {
    setShowLogin(false);
  };
  const handleLoginShow = () => {
    setShowLogin(true);
  };
  const handleclick = (e) => {
    e.preventDefault();
    loggedIn ? handleLogout() : handleLoginShow();
  };
  return (
    <>
      <div
        className={`${props.nonav} ${
          props.transparent ? 'transparent' : ''
        } nav`}>
        <Modal
          show={showLogin}
          handleClose={closeLoginModal}
        />
        <button
          className='navbar-toggler'
          onClick={collapse}>
          <span
            className='navbar-toggler-icon'
            id='toggler-icon'></span>
        </button>
        <a
          className='navbar-brand'
          href='/'>
          <div className='brand-image'></div>
        </a>
        <div
          id='navbar-center'
          className='navbar-center navbar-collapse'>
          <a
            href='/marketplace'
            className={`${
              props.pathname === '/marketplace' ? 'active' : ''
            } nav-link`}>
            <p>Oportunidades</p>
          </a>
          {loggedIn ? (
            <a
              href='/portfolio'
              className={`${
                props.pathname === '/portfolio' ? 'active' : ''
              } nav-link`}>
              <p>Portafolio</p>
            </a>
          ) : (
            <a
              href='/about'
              className={`${
                props.pathname === '/about' ? 'active' : ''
              } nav-link`}>
              <p>Nosotros</p>
            </a>
          )}
          <a
            href='/faq'
            className={`${props.pathname === '/faq' ? 'active' : ''} nav-link`}>
            <p>Preguntas frecuentes</p>
          </a>
          {loggedIn && (
            <a
              href='/profile'
              className={`${
                props.pathname === '/profile' ? 'active' : ''
              } nav-link`}>
              <p>Perfil</p>
            </a>
          )}
        </div>
        <div className={'nav-buttons ' + props.success}>
          {loggedIn ? (
            <button
              className='nav-link'
              onClick={handleLogout}>
              <p>Cerrar Sesión</p>
            </button>
          ) : (
            <button
              className='btn-rounded-invert'
              onClick={handleclick}>
              Iniciar Sesión
            </button>
          )}
        </div>
      </div>
    </>
  );
}
