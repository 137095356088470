import React from 'react';

export default function Footer(props) {
  return (
    <>
      <div className={`footer  ${props.nofoot + ' ' + props.bg}`}>
        <div className='footer-container'>
          <div className='info'>
            <div className='icon logo'></div>
            <p>Carrera 11A No. 93-67</p>
            <p>Bogotá, Colombia.</p>
          </div>
          <div className='info'>
            <p className='title'>Contacto</p>
            <a href='mailto:info@toperty.co'>
              <p>info@toperty.co</p>
            </a>
            <p>+57 318-750-6913</p>
          </div>
          <div className='info'>
            <p className='title'>Compañía</p>
            <a href='/about'>
              <p>Sobre nosotros</p>
            </a>
            <a href='/personal-data'>
              <p>Política de Privacidad de Datos</p>
            </a>
            <a href='/tyc'>
              <p>Términos y Condiciones</p>
            </a>
            <a href='/faq'>
              <p>FAQ</p>
            </a>
          </div>
          <div className='info media'>
            <a
              href='https://twitter.com/topertyco'
              className='twitter'
              target='_blank'
              rel='noreferrer'>
              <div className='icon'></div>
            </a>
            <a
              href='https://www.facebook.com/Toperty-101079869335912'
              className='facebook'
              target='_blank'
              rel='noreferrer'>
              <div className='icon'></div>
            </a>
            <a
              href='https://instagram.com/toperty.co?igshid=YmMyMTA2M2Y='
              className='instagram'
              target='_blank'
              rel='noreferrer'>
              <div className='icon'></div>
            </a>
            <a
              href='https://www.linkedin.com/company/topertyco/'
              className='linkedin'
              target='_blank'
              rel='noreferrer'>
              <div className='icon'></div>
            </a>
            <a
              href='https://www.tiktok.com/@topertyco'
              className='tiktok'
              target='_blank'
              rel='noreferrer'>
              <div className='icon'></div>
            </a>

            <a
              href='https://www.tiktok.com/@topertyco'
              className='whatsappfoot'
              target='_blank'
              rel='noreferrer'>
              <div className='icon'></div>
            </a>
          </div>

          <p className='copyright'>© Toperty. Todos los derechos reservados.</p>
        </div>
      </div>
    </>
  );
}
