export const tenantInputsData = [
	{
		"inputLabel": "Nombre completo",
		"inputType": "text",
		"inputKey": "name",
	},
	{
		"inputLabel": "Email",
		"inputType": "mail",
		"inputId": "email-in",
		"inputKey": "email",
	},
	{
		"inputLabel": "Fecha de nacimiento",
		"inputType": "text",
		"inputId": "birthdate-in",
		"inputKey": "birthdate",
	},
	{
		"inputLabel": "Celular",
		"inputType": "text",
		"inputId": "phone-in",
		"inputKey": "phone",
	},
	{
		"inputLabel": "Nivel de educación",
		"inputType": "select",
		"inputId": "education_level-in",
		"inputKey": "education_level",
		"options": [
			"Primaria",
			"Bachillerato",
			"Técnico o tecnólogo",
			"Pregrado",
			"Maestría",
			"Doctorado",
		],
	},
	{
		"inputLabel": "Estado Civil",
		"inputType": "select",
		"inputId": "marriage_status-in",
		"inputKey": "marriage_status",
		"options": [
			"Soltero(a)",
			"Casado(a)",
			"Divorciado(a)",
			"Viudo(a)",
			"Unión libre",
		],
	},
	{
		"inputLabel": "Ahorros mensuales del hogar",
		"inputType": "money",
		"inputKey": "monthly_savings",
	},
	{
		"inputLabel": "Tipo de documento",
		"inputType": "select",
		"inputId": "document_type-in",
		"inputKey": "document_type",
		"options": [
			"Cédula de ciudadanía",
			"Pasaporte",
			"Cédula de extranjería",
		],
	},
	{
		"inputLabel": "Número de documento",
		"inputType": "text",
		"inputId": "document_number-in",
		"inputKey": "document_number",
	},
	{
		"inputLabel": "Fecha de expedición del documento de identidad",
		"inputType": "text",
		"inputId": "document_creation_date-in",
		"inputKey": "document_creation_date",
	},
	{
		"inputLabel": "Dirección de vivienda actual",
		"inputType": "text",
		"inputId": "living_address-in",
		"inputKey": "living_address",
	},
	{
		"inputLabel": "Ciudad de vivienda actual",
		"inputType": "text",
		"inputId": "living_city-in",
		"inputKey": "living_city",
	},
	{
		"inputLabel": "Nacionalidad",
		"inputType": "text",
		"inputId": "nationality-in",
		"inputKey": "nationality",
	},
	{
		"inputLabel": "Vivienda actual",
		"inputType": "select",
		"inputId": "home_status-in",
		"inputKey": "home_status",
		"options": [
			"Propia",
			"Arrendada",
			"Familiar",
		],
	},
	{
		"inputLabel": "Ocupación",
		"inputType": "select",
		"inputId": "occupation-in",
		"inputKey": "occupation",
		"options": [
			"Empleado con contrato a término fijo",
			"Empleado con contrato a término indefinido",
			"Desempleado",
			"Independiente",
			"Pensionado",
		],
	},
	{
		"inputLabel": "Empresa en la que trabaja",
		"inputType": "text",
		"inputId": "working_company-in",
		"inputKey": "working_company",
	},
	{
		"inputLabel": "Número de personas en su núcleo familiar",
		"inputType": "text",
		"inputId": "roommates-in",
		"inputKey": "roommates",
	},
	{
		"inputLabel": "Ingresos mensuales personales",
		"inputType": "money",
		"inputKey": "personal_monthly_income",
	},
	{
		"inputLabel": "Ingresos mensuales del hogar",
		"inputType": "money",
		"inputKey": "household_monthly_income",
	},
	{
		"inputLabel": "Deudas actuales",
		"inputType": "money",
		"inputKey": "current_debt",
		"inputId": "current_debt-in",
	},
	{
		"inputLabel": "Porcentaje de endeudamiento (%)",
		"inputType": "percentage",
		"inputKey": "debt_percentage",
		"inputId": "debt_percentage-in",
	},
	{
		"inputLabel": "Pago de renta actual ($)",
		"inputType": "money",
		"inputKey": "monthly_rent",
		"inputId": "monthly_rent-in",
	},
	{
		"inputLabel": "Bienes a nombre del usuario",
		"inputType": "multiCheckbox",
		"inputKey": "assets",
		"options": [
			"Carro",
			"Motocicleta",
			"Vivienda",
			"Inmueble comercial",
			"Otro",
		],
	},
]

export const propertyInputsData = [
	{
		"inputLabel": "Ciudad",
		"inputType": "select",
		"inputId": "city-in",
		"inputKey": "city",
		"options": [
			"Bogotá",
			"Medellín",
			"Cali",
			"Bucaramanga",
			"Cartagena",
			"Pereira",
			"Armenia",
			"Manizales",
			"Ibagué",
			"Santa Marta",
			"Cúcuta",
			"Barranquilla"
		],
	},
	{
		"inputKey": "neighborhood"
	},
	{
		"inputLabel": "Dirección",
		"inputType": "text",
		"inputKey": "address",
	},
	{
		"inputLabel": "Tipo de inmueble",
		"inputType": "select",
		"inputKey": "type",
		"options": ["Casa", "Apartamento"],
	},
	{
		"inputLabel": "Novedad del inmueble",
		"inputType": "select",
		"inputKey": "used",
		"options": ["Nuevo", "Usado"],
	},
	{
		"inputLabel": "Valoración externa del inmueble ($)",
		"inputType": "money",
		"inputKey": "market_value",
	},
	{
		"inputLabel": "Valoración de Toperty del inmueble ($)",
		"inputType": "money",
		"inputKey": "toperty_valuation",
	},
	{
		"inputLabel": "Precio acordado de compra del inmueble ($)",
		"inputType": "money",
		"inputKey": "acquisition_cost",
	},
	{
		"inputLabel": "Valor de la administración",
		"inputType": "money",
		"inputKey": "administration_fee",
	},
	{
		"inputLabel": "Estrato",
		"inputType": "select",
		"inputKey": "economic_classification",
		"options": ["1", "2", "3", "4", "5", "6"],
	},
	{
		"inputLabel": "Piso",
		"inputType": "text",
		"inputKey": "floor",
	},
	{
		"inputLabel": "Número de parqueaderos disponibles",
		"inputType": "text",
		"inputKey": "parking_spaces",
	},
	{
		"inputLabel": "Año de construcción",
		"inputType": "text",
		"inputKey": "antiquity",
	},
	{
		"inputLabel": "Número de habitaciones",
		"inputType": "text",
		"inputKey": "bedrooms",
	},
	{
		"inputLabel": "Número de baños",
		"inputType": "text",
		"inputKey": "bathrooms",
	},
	{
		"inputLabel": "Área privada (m²)",
		"inputType": "text",
		"inputKey": "living_area",
	},
	{
		"inputKey": "living_area",
	},
	{
		"inputKey": "description",
	},
	{
		"inputLabel": "Amenities",
		"inputType": "multiCheckbox",
		"inputKey": "amenities",
		"options": [
			"Ascensor",
			"Seguridad",
			"Depósito",
			"Gimnasio",
			"Terraza",
			"Salón comunal",
			"Parqueadero visitantes",
			"Zonas verdes",
			"Lavandería",
			"Parque infantil",
			"Piscina",
			"Sauna",
			"cancha de squash",
		],
	},
]