import React, { useState } from 'react';
import { Faq } from './Faq';
import { InvestModal as Modal } from '../../Components/InvestModal';

export function FaqPage() {
  const [show, setShow] = useState(false);
  const handleshow = (e) => {
    setShow(true);
  };
  const closeModal = () => {
    setShow(false);
  };
  const [faq, setFaq] = useState('Nosotros');
  const faqs = ['Nosotros', 'Legal', 'Riesgos', 'Finanzas', 'Inversión'];
  return (
    <>
      <Modal
        show={show}
        handleClose={closeModal}
        type='contact'
      />
      <div className='faq-container'>
        <h4>Preguntas Frecuentes</h4>
        <section className='cards'>
          {faqs.map((value) => (
            <button
              key={value}
              className={`faq-card ${value} ${value === faq && 'active'}`}
              onClick={() => setFaq(value)}>
              <div className='icon' />
              <p className='title'>{value}</p>
            </button>
          ))}
        </section>
        <section className='faqs'>
          <Faq title={faq} />
        </section>
        <section className='articles'>
          <h4 className='title'>Artículos</h4>
          <h2>Articulos seleccionados</h2>
        </section>
        <section className='faq-contact'>
          <h3 className='title'>Aca estamos para resolver todas tus dudas!</h3>
          <p>
            Si no encontraste las preguntas que necesitabas aclarar, contactanos
            y te sacaremos de todas tus dudas
          </p>
          <button
            className='btn-rounded-green'
            onClick={handleshow}>
            Contáctanos
          </button>
        </section>
      </div>
    </>
  );
}
