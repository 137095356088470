import React from "react";

export function CalcCards(props) {
  let COPLocale = Intl.NumberFormat("es-CO");
  return (
    <div className="calc-card">
      <div className="card-title">Cuota {props.cuota}</div>
      <div className="body">
        <div className="data">
          <div className="title">Abono a capital</div>
          <div className="info">${COPLocale.format(props.abonoK)}</div>
        </div>
        <div className="data">
          <div className="title">Pago de arriendo</div>
          <div className="info">${COPLocale.format(props.arriendo)}</div>
        </div>
        <div className="data">
          <div className="title">Pago total</div>
          <div className="info">${COPLocale.format(props.total)}</div>
        </div>
        <div className="data">
          <div className="title">Rendimiento por arriendo</div>
          <div className="info">${COPLocale.format(props.rentas)}</div>
        </div>
        <div className="data">
          <div className="title">Adquisición($)</div>
          <div className="info">${COPLocale.format(props.ownPrice)}</div>
        </div>
        <div className="data">
          <div className="title">Adquisición(%)</div>
          <div className="info">{props.ownPerc}%</div>
        </div>
      </div>
    </div>
  );
}

export function FinancialCalcCards(props) {
  let COPLocale = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });

  return (
    <div className="calc-card">
      <div className="card-title">Cuota {props.cuota}</div>
      <div className="body">
        <div className="data">
          <div className="title">Ingreso por arriendo</div>
          <div className="info">{COPLocale.format(props.renta)}</div>
        </div>
        <div className="data">
          <div className="title">Venta parcial inmueble</div>
          <div className="info">{COPLocale.format(props.compra)}</div>
        </div>
        <div className="data">
          <div className="title">Ingreso total</div>
          <div className="info">{COPLocale.format(props.total)}</div>
        </div>
        <div className="data">
          <div className="title">Costos</div>
          <div className="info">{COPLocale.format(props.costos)}</div>
        </div>
        <div className="data">
          <div className="title">Flujo distribuible</div>
          <div className="info">{COPLocale.format(props.utilities)}</div>
        </div>
      </div>
    </div>
  );
}
