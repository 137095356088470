import React from 'react';
import { Carousel, Col } from 'react-bootstrap';

export function PropCarousel(props) {
  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }
  return (
    <>
      {props.type !== 'invest-carousel' && <p className='bold fs-5'>Galería</p>}
      <Carousel className={props.type}>
        {range(1, props.images_number).map((image_num) => {
          return (
            <Carousel.Item
              className='mb-3'
              key={image_num}>
              <img
                src={`${props.images_location}${image_num}.jpg`}
                alt='first-slide'
                className='d-block'
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
}
