import React, { useState } from 'react';

export function Faq(props) {
  const preguntas = {
    Nosotros: {
      '¿A qué se dedica Toperty? ':
        'Toperty es una startup colombiana que tiene como propósito ayudar a personas que no tienen los ahorros para una cuota inicial a que puedan comprar vivienda. Esto lo hacemos a través de un programa de “Rent to Own” (compra mientras alquilas) donde nosotros, en conjunto con inversionistas, adquirimos la vivienda y la persona nos la va comprando mes a mes a través de pagos mensuales mientras la arrienda. El objetivo del programa es que la persona pueda adquirir una participación en el inmueble equivalente a la cuota inicial y pueda adquirir un crédito de vivienda al finalizar el programa con el que termina de comprarnos su vivienda (entre 3 y 5 años máximo). ',
      '¿Cómo funciona el modelo de negocio de Toperty?':
        'En Toperty buscamos comprar propiedades por debajo del precio de mercado a través de alianzas con constructoras e inmobiliarias, ofreciendo pagos de contado a propietarios y a través de algoritmos que nos permiten identificar oportunidades en inmuebles atractivos. Adicional, generamos una rentabilidad fija a través del canon de arriendo, y por último pactamos una apreciación fija con nuestros futuros propietarios logrando, al finalizar el periodo del programa, venderlo por un mayor valor. ',
      '¿Cuál es el plazo promedio de los programas de Rent to Own?':
        'El programa de “Rent to Own” puede durar entre 3 y 5 años máximo.',
    },
    Legal: {
      '¿Cuál es el vehículo legal de la inversión?':
        'Escrituración del inmueble a nombre de Toperty S.A.S u otra Sociedad de Toperty S.A.S y firma de contratos de colaboración o cuentas en participación con los inversionistas, donde Toperty es el partícipe principal y el inversionista el participe oculto. Otros vehículos de inversión pueden incluir patrimonios autónomos o fiducias. Puedes encontrar el vehículo de inversión para cada oportunidad en su respectiva página bajo “estructura legal de la inversión”',
      '¿Quién es el dueño del inmueble durante el programa?':
        'esta es la respuesta 6',
      '¿Cómo puedo estar seguro de que mi inversión está respaldada por un inmueble real?':
        'esta es la respuesta 7',
    },
    Riesgos: {
      '¿Cuáles son los criterios que Toperty utiliza para hacer el estudio de crédito a los usuarios?':
        'Hacemos un estudio de crédito minucioso donde revisamos en detalle los ingresos de los usuarios para verificar capacidad de pago, endeudamiento actual y cobertura sobre la cuota mensual del programa. Este proceso lo hacemos a través de proveedores especializados en verificación crediticia y de antecedentes.',
      '¿Toperty hace un estudio de antecedentes a sus usuarios?':
        'Si, contamos con proveedores expertos que nos ayudan a consultar antecedentes e identificar posibles riesgos que pueda presentar potenciales usuarios. Solo aceptamos usuarios que pasen una debida diligencia que incluye un estudio de SARLAFT, lavado de activos, listas restrictivas, entre otros.',
      '¿Qué sucede si el usuario no puede acceder a un crédito al final del programa de Rent to Own?':
        'Buscamos a través de lo largo del programa preparar a nuestros usuarios para el momento en que tengan que solicitar un crédito, haciendo esfuerzos en temas de educación financiera. En caso que no le sea posible completar la compra de la vivienda, debemos poner en venta el inmueble y retornar el dinero por la participación de los inversionistas en el activo. En Toperty cobramos una penalidad al usuario con la que podemos cubrir tiempo de vacancia mientras se vende el inmueble.',
      '¿Qué pasa si Toperty no encuentra los inversionistas suficientes para financiar un inmueble?':
        'En caso de no completar el financiamiento a través de inversionistas externos o con capital propio, el programa no inicia y devolvemos el dinero a las personas que invirtieron en la oportunidad.',
      '¿Toperty tiene algún tipo de garantía o seguro en caso de que ocurra algún imprevisto o siniestro en el inmueble?':
        'Contamos con seguro todo riesgo para los diferentes inmuebles en los que invertimos.',
    },
    Finanzas: {
      '¿Cómo se determina el retorno esperado de mi inversión?':
        'Los flujos futuros distribuibles al inversionista son proyecciones sobre los pagos mensuales que hace el usuario, los cuales se componen del arrendamiento del inmueble y un aporte a la compra parcial del mismo. Estos pagos del usuario dependen de la inflación, por lo que el retorno esperado de la inversión son estimaciones que podrán cambiar de acuerdo a condiciones de mercado.',
      '¿De qué se componen los rendimientos que recibo durante el programa?':
        'Los rendimientos provienen del pago mensual que hacen los usuarios durante el programa. El pago mensual se compone de un arrendamiento del inmueble y un aporte mensual a la compra de la vivienda. Estos rendimientos podrán variar a lo largo del programa ya que están sujetos a condiciones del mercado como la inflación, y también el usuario podrá hacer aportes extraordinarios en cuyo caso se le distribuirá a los inversionistas el mayor valor pagado por el usuario a pro rata de la participación que tenga en la oportunidad de inversión.4',
      '¿Cómo se determina el precio de compra del inmueble?':
        'En Toperty hacemos un avalúo interno del inmueble usando algoritmos y tecnología propia que hemos construido, y adicional este valor lo contrastamos con un avalúo de un tercero experto para determinar el valor de mercado de los inmuebles en los que invertimos. Nuestra estrategia es identificar y comprar activos inmobiliarios que estén por debajo del precio de mercado para maximizar los retornos esperados.',
      '¿Cómo se pacta el precio de venta final del inmueble con el usuario?':
        'El precio de venta con el usuario se pacta desde el inicio a través de los contratos del programa. El precio del inmueble depende de variables macroeconómicas como la inflación y otros factores con los que buscamos apreciar de forma transparente y justa el inmueble.',
      '¿Cómo puedo estar seguro de que Toperty no está inflando el valor del inmueble?':
        'Nuestro objetivo y único propósito es que los usuarios puedan comprarnos la vivienda al finalizar el programa, y para esto deben poder adquirir un crédito de vivienda para financiar el porcentaje del inmueble que le hace falta por comprar. Somos conscientes que si el inmueble se valoriza excesivamente por encima del precio de mercado nuestro usuario no va a lograr acceder al crédito, lo cual perjudica a todas las partes involucradas.',
    },
    Inversión: {
      '¿Cómo se realiza el proceso de inversión?':
        'Debes crear tu cuenta, completar los datos en tu perfil, firmar de forma digital los contratos de inversión de cada oportunidad y hacer el abono del monto que desees invertir.',
      '¿Cómo puedo ver las oportunidades de inversión disponibles?':
        'En oportunidades podrás ver todas los inmuebles que tenemos disponibles para inversión y para cada uno podrás ver el detalle financiero, del inmueble e información del usuario.',
      '¿Hay algún monto mínimo de inversión?':
        'Cada oportunidad tiene un monto mínimo de inversión.',
      '¿Puedo invertir en más de un inmueble a la vez?':
        'Puedes seleccionar uno o más inmuebles en los que desees invertir y en tu portafolio aparecerán las distintas oportunidades que tienes asociadas. Debes completar el proceso de forma separada para cada inversión en la que participes. ',
      '¿Cuál es el plazo mínimo de inversión y cómo puedo retirar mi inversión?':
        'El plazo mínimo de inversión está determinado por la duración del programa de “Rent to Own” que Toperty pacta directamente con los usuarios y que finaliza cuando el usuario adquiere el crédito de vivienda con el que termina de comprar el inmueble. Si bien el programa tiene una duración inicial estimada, esta podrá acortarse si el usuario hace aportes extraordinarios durante el programa que le permitan acceder a un crédito de forma anticipada.',
      '¿Puedo vender mi inversión antes de que finalice el programa de Rent to Own?':
        'No es posible vender de forma anticipada la inversión antes de que finalice el programa de “Rent to Own”. ',
      '¿Cómo puedo hacer seguimiento a mi inversión y ver los pagos y el progreso del usuario en el programa de Rent to Own?':
        'Puedes hacer seguimiento a los rendimientos distribuibles y al detalle del progreso de cada oportunidad de inversión a través de nuestro portal de inversionistas en la sección de portafolio.',
      '¿Cómo se distribuyen los rendimientos a los inversionistas?':
        'Los rendimientos son distribuidos de forma mensual en las fechas de corte que tenemos establecidas en los contratos del programa con los usuarios.',
      '¿Cuánto cobra Toperty como comisión por la inversión y la gestión de la inversión?':
        'Toperty cobra una comisión por el manejo de los activos y gestión del programa equivalente al XX%. Esta comisión es deducida de forma mensual de los rendimientos distribuibles al inversionista. ',
      '¿Qué gastos operativos tiene un inmueble en el que invertí?':
        'Los gastos operativos de los inmuebles incluyen seguros (todo riesgo y arrendamiento), impuesto predial, y reparaciones que puedan surgir.',
      '¿Quién paga los gastos operativos del inmueble?':
        'Los gastos operativos del inmueble son asumidos en proporciones acorde a la participación del usuario y los inversionistas en el momento en el que se causen los gastos.',
      '¿Quién paga la escrituración del inmueble?':
        'Los inversionistas deben asumir los costos de la primera escrituración del inmueble, es decir cuando se adquiere el inmueble antes de que inicie el programa. Los costos de escrituración en el momento de finalización del programa (cuando el usuario termina de comprar el inmueble a los inversionistas) son asumidos por el usuario.',
      '¿Cuál es el proceso para salir de mi inversión una vez que se ha completado el programa de Rent to Own?':
        'La inversión finaliza cuando el usuario termina de adquirir el inmueble. La duración del programa está sujeta a este evento y se puede dar antes de los tiempos estimados al inicio debido a que el usuario puede adquirir de forma anticipada el inmueble a través de un crédito o con recursos propios. En el momento en que lo anterior ocurre, Toperty transfiere al inversionista de forma proporcional a su participación.',
    },
  };
  function Questions(props) {
    const [activeQuestion, setActiveQuestion] = useState(null);

    function handleClick(event) {
      const questionId = event.target.dataset.questionId;
      setActiveQuestion(questionId === activeQuestion ? null : questionId);
    }

    const preguntasrespuestas = Object.entries(props.pregresp);
    return (
      <>
        {preguntasrespuestas.map((pregResp) => (
          <div key={pregResp[0]}>
            <button
              className={`question ${
                pregResp[0] === activeQuestion ? 'active' : ''
              }`}
              onClick={handleClick}
              aria-expanded={pregResp[0] === activeQuestion ? 'true' : 'false'}
              aria-controls={`answer-${pregResp[0]}`}
              data-question-id={pregResp[0]}>
              <p>{pregResp[0]}</p>
            </button>
            <div
              className={`answer ${
                pregResp[0] === activeQuestion ? 'active' : ''
              }`}
              id={`answer-${pregResp[0]}`}
              aria-hidden={pregResp[0] !== activeQuestion}>
              <p>{pregResp[1]}</p>
            </div>
          </div>
        ))}
      </>
    );
  }
  return (
    <>
      <h4 className='title'>{props.title}</h4>
      <div className='faq-list'>
        <Questions pregresp={preguntas[props.title]} />
      </div>
    </>
  );
}
