export function Validations(propertyData, tenantData) {
  let errors = [];
  const tenantKeys = Object.keys(tenantData)
  const propertyKeys = Object.keys(propertyData)

  const namevalidation = new RegExp("^$|^[A-Za-zÀ-ÖØ-öø-ÿs\\s]+$");
  if (!namevalidation.test(tenantData["name"])) {
    errors.push("name")
  }

  const emailValidation = new RegExp(
    "^$|^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
  );
  if (!emailValidation.test(tenantData["email"])) {
    errors.push("email")
  }

  const documentValidation = new RegExp("^$|^[0-9]{7,12}$");
  if (!documentValidation.test(tenantData["document_number"])) {
    errors.push("document_number")
  }

  const phoneValidation = new RegExp("^$|^[0-9]{10}$");
  if (!phoneValidation.test(tenantData["phone"])) {
    errors.push("phone")
  }

  const dateValidation = new RegExp("^$|^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$");
  let dateKeys = tenantKeys.filter(function (tenantKey) {
    return tenantKey.includes("date")
  });
  for (const dateKey of dateKeys) {
    if (!dateValidation.test(tenantData[dateKey])) {
      errors.push(dateKey)
    }
  }

  const roommatesValidation = new RegExp("^$|^[0-9]{1,2}$");
  if (!roommatesValidation.test(tenantData["roommates"])) {
    errors.push("roommates")
  }

  const moneyValidation = new RegExp("^[0-9]*$");
  let tenantMoneyKeys = tenantKeys.filter(function (tenantKey) {
    return tenantKey.includes("savings") || tenantKey.includes("income") ||
      tenantKey.includes("rent") || tenantKey.includes("debt");
  });

  const creditScoreValidation = new RegExp("^$|^[0-9]{3,4}$");
  if (!creditScoreValidation.test(tenantData["credit_score"])) {
    errors.push("credit_score")
  }

  for (const moneyKey of tenantMoneyKeys) {
    if (!moneyValidation.test(tenantData[moneyKey])) {
      errors.push(moneyKey)
    }
  }

  let propertyMoneyKeys = propertyKeys.filter(function (propertyKey) {
    return propertyKey.includes("payment") || propertyKey.includes("value") ||
      propertyKey.includes("cost") || propertyKey.includes("invested");
  });

  for (const moneyKey of propertyMoneyKeys) {
    if (!moneyValidation.test(propertyData[moneyKey])) {
      errors.push(moneyKey)
    }
  }

  const economicClassificationValidation = new RegExp("^$|^[1-6]{1}$");
  if (!economicClassificationValidation.test(propertyData["economic_classification"])) {
    errors.push("economic_classification")
  }

  const parkingSpacesValidation = new RegExp("^$|^[1-9]{1,2}$");
  if (!parkingSpacesValidation.test(propertyData["parking_spaces"])) {
    errors.push("parking_spaces")
  }

  const floorValidation = new RegExp("^$|^[1-9]{1,2}$");
  if (!floorValidation.test(propertyData["floor"])) {
    errors.push("floor")
  }

  const antiquityValidation = new RegExp("^$|^[1-9]{4}$");
  if (!antiquityValidation.test(propertyData["antiquity"])) {
    errors.push("antiquity")
  }

  return errors
}


