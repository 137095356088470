export const personalData = [
	{
		inputLabel: 'Nombre completo',
		"inputId": "name-in",
		inputType: 'text',
		inputKey: 'name',
	},
	{
		inputLabel: 'Celular',
		inputType: 'text',
		inputId: 'phone-in',
		inputKey: 'phone',
	},
	{
		inputLabel: 'Fecha de nacimiento',
		inputType: 'date',
		inputId: 'birthdate-in',
		inputKey: 'birthdate',
	},
	{
		inputLabel: 'Tipo de documento',
		inputType: 'select',
		inputId: 'document_type-in',
		inputKey: 'document_type',
		options: ['Cédula de ciudadanía', 'Pasaporte', 'Cédula de extranjería'],
	},
	{
		inputLabel: 'Número de documento',
		inputType: 'text',
		inputId: 'document_number-in',
		inputKey: 'document_number',
	},
	{
		inputLabel: 'Fecha de expedición documento',
		inputType: 'date',
		inputId: 'document_creation_date-in',
		inputKey: 'document_creation_date',
	},
];

export const bankingData = [
	{
		inputLabel: 'Titular de la cuenta',
		inputType: 'text',
		inputKey: 'account_holder',
	},
	{
		inputLabel: 'Tipo de documento',
		inputType: 'select',
		inputKey: 'account_holder_document_type',
		options: ['Cédula de ciudadanía', "NIT", 'Pasaporte', 'Cédula de extranjería'],
	},
	{
		inputLabel: 'Número de documento',
		inputType: 'text',
		inputKey: 'account_holder_document_number',
	},
	{
		inputLabel: 'Banco',
		inputType: 'select',
		inputKey: 'bank',
		options: [
			"Ban100",
			"Bancamia S.A.",
			"Banco Agrario",
			'Banco AV Villas',
			'BBVA',
			'Banco Caja Social',
			'Banco Cooperativo Coopcentral',
			'Davivienda',
			'Banco de Bogotá',
			'Banco de Occidente',
			'Banco Falabella',
			'Banco Finandina',
			'GNB Sudameris',
			'Itaú Corpbanca',
			'Bancolombia',
			'Banco Pichincha',
			'Banco Popular',
			'Banco Santander',
			'Banco Serfinanza',
			'Banco Union',
			'Bancoomeva',
			'CFA Cooperativa Financiera',
			'Citibank',
			'Coltefinanciera',
			'Confiar Cooperativa Financiera',
			'COOFINEP Cooperativa Financiera',
			'COTRAFA',
			'Iris',
			'Lulo Bank',
			'Banco J.P Morgan',
			'Scotiabank Colpatria',
			'Banco Mundo Mujer',
			'Mibanco',
			'Banco Credifinanciera',
			'Banco W',
			'Banco BTG Pactual',
		],
	},
	{
		inputLabel: 'Tipo de cuenta',
		inputType: 'select',
		inputKey: 'bank_account_type',
		options: ['Ahorros', 'Corriente'],
	},
	{
		inputLabel: 'Cuenta bancaria',
		inputType: 'text',
		inputKey: 'bank_account',
	},
];

export const credentialsData = [
	{
		inputLabel: 'Email',
		inputType: 'mail',
		inputId: 'email-in',
		inputKey: 'email',
	},
];
