import { createContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { labels } from '../utils/checkboxes';

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [downPayment, setDownPayment] = useState(0);
  const [show, setShow] = useState(false);
  const [simulatedPayments, setSimulatedPayments] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const [cardsData, setCardsData] = useState(0);
  const [checkedState, setCheckedState] = useState(
    new Array(labels.length).fill(false)
  );
  const [maxMinValues, setMaxMinValues] = useState({});
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem('loggedIn') || false
  );
  const [showLogin, setShowLogin] = useState(false);
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem('profile')) || {}
  );

  const store = {
    downPayment,
    setDownPayment,
    show,
    setShow,
    simulatedPayments,
    setSimulatedPayments,
    isMobile,
    isDesktop,
    cardsData,
    setCardsData,
    checkedState,
    setCheckedState,
    maxMinValues,
    setMaxMinValues,
    loggedIn,
    setLoggedIn,
    showLogin,
    setShowLogin,
    user,
    setUser,
    profile,
    setProfile,
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
