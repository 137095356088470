export const tenantFeatures = {
  personal_data: [
    {
      name: "Edad",
      apiName: "birthdate",
    },
    {
      name: "Estado Civil",
      apiName: "marriage_status",
    },
    {
      name: "Nacionalidad",
      apiName: "nationality",
    },
    {
      name: "Ocupación",
      apiName: "occupation",
    },
    // {
    //     name: "Cargo en la empresa",
    //     apiName: "job_position"
    // },
    {
      name: "Nivel de estudios",
      apiName: "education_level",
    },
    // {
    //     name: "Co-aplicantes",
    //     apiName: "co_applicant"
    // },
    // {
    //     name: "Personas a cargo",
    //     apiName: "roommates"
    // }
  ],
  financial_data: [
    // {
    //     name: "Ingresos mensuales individuales",
    //     apiName: "personal_monthly_income"
    // },
    {
      name: "Ingresos mensuales",
      apiName: "household_monthly_income",
    },
    // {
    //     name: "Porcentaje de endeudamiento",
    //     apiName: "debt_percentage"
    // },
    {
      name: "Ingresos / cuota mensual",
      apiName: "household_monthly_income",
    },
    // {
    //     name: "Pasivos",
    //     apiName: "current_debt"
    // },
    {
      name: "Score de crédito",
      apiName: "credit_score",
    },
    {
      name: "Fecha de último score de crédito",
      apiName: "last_credit_score_date",
    },
  ],
  tax_data: [
    {
      name: "Año de última declaración",
      apiName: "last_tax_return_date",
    },
    {
      name: "Patrimonio bruto",
      apiName: "gross_patrimony",
    },
    {
      name: "Ingresos brutos",
      apiName: "annual_incomes",
    },
    {
      name: "Deudas",
      apiName: "debt_report",
    },
  ],
};
