import React from 'react';
import images from '../Img/About/Founders';

import {
  Parallax,
  ParallaxProvider,
  ParallaxBanner,
} from 'react-scroll-parallax';

function Card(props) {
  return (
    <div className='about-card-container'>
      <div className='about-card'>
        <div className='image'>
          <img
            src={images[`${props.img}`]}
            alt='sin imagen'
          />
        </div>
        <div className='content'>
          <p className='name'>{props.name}</p>
          <p className='description'>{props.description}</p>
        </div>
      </div>
    </div>
  );
}

export function About() {
  const persons = [
    {
      name: 'Nicolas Albornoz',
      description:
        '+10 años de experiencia en fondos de capital privado de “Real Estate” con inversiones en  en espacios residenciales, comerciales, logísticos y de oficina.',
      img: 'Albornoz',
    },
    {
      name: 'Nicolas Maldonado',
      description:
        '2X Startup Founder con experiencia en gestión de productos digitales relacionados con ciencia de datos.  ',
      img: 'Maldonado',
    },
    {
      name: 'Julián Uribe',
      description:
        'Abogago con más de 10 años de experiencia asesorando empresas en aspectos fiscales multijurisdiccionales, fusiones y adquisiciones, y reorganizaciones.',
      img: 'Uribe',
    },
    {
      name: 'Pedro Botero',
      description:
        '+10 años de experiencia en finanzas en valoración de empresas y estructuración y gestión de portafolios de inversión.',
      img: 'Botero',
    },
  ];
  const team = [
    {
      name: 'Manuel Casado',
      description: 'Tecnología',
      img: 'Casado',
    },
    {
      name: 'Jose Rodriguez',
      description: 'Tecnología',
      img: 'Rodriguez',
    },
    {
      name: 'Yilberth Arredondo',
      description: 'Tecnología',
      img: 'Arredondo',
    },
    {
      name: 'Mario Santanilla',
      description: 'Tecnología',
      img: 'Santanilla',
    },
  ];

  return (
    <ParallaxProvider>
      <div className='about-page'>
        <div className='about-container'>
          <section className='first'>
            <ParallaxBanner
              layers={[
                {
                  speed: -30,
                  children: (
                    <div className='title'>
                      <h4>
                        Queremos facilitar el acceso a la finca raíz, ofreciendo
                        programas de acceso a vivienda y oportunidades de
                        inversión.
                      </h4>
                    </div>
                  ),
                },
                {
                  speed: -20,
                  children: <div className='graph' />,
                },
                {
                  speed: -40,
                  children: (
                    <div className='description'>
                      <p>
                        Nuestro propósito es ofrecer soluciones prácticas y
                        rentables, mientras trabajamos para transformar la forma
                        en que las personas compran e invierten en propiedades
                        en Latino América.
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </section>
          <section className='founders'>
            <h3 className='title'>Fundadores</h3>
            {persons.map((founder) => (
              <Parallax
                translateY={[100, -20, 'ease']}
                key={founder.name}>
                <Card
                  img={founder.img}
                  name={founder.name}
                  description={founder.description}
                />
              </Parallax>
            ))}
          </section>
          <section className='team'>
            <h3 className='title'>Nuestro Equipo</h3>
            {team.map((teamate) => (
              <Parallax
                translateY={[100, -20, 'ease']}
                key={teamate.name}>
                <Card
                  img={teamate.img}
                  name={teamate.name}
                  description={teamate.description}
                />
              </Parallax>
            ))}
          </section>
        </div>
      </div>
    </ParallaxProvider>
  );
}
