import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate, useParams } from "react-router-dom";
import { LoginModal } from "../Components//ModalView";
import { PropCarousel as Carousel } from "../Components/Carousel";
import { InvestModal as Modal } from "../Components/InvestModal";
import { FinancialModal } from "../Components/ModalView";
import { StoreContext } from "../utils/store";
import { DueDiligence } from "./InvestmentMultitab/DueDiligence";
import { InfoFinancial } from "./InvestmentMultitab/InfoFinancial";
import { InfoProp } from "./InvestmentMultitab/InfoProp";
import { InfoUser } from "./InvestmentMultitab/InfoUser";
import { LegalInfo } from "./InvestmentMultitab/LegalInfo";

export function Investment() {
  const { loggedIn } = useContext(StoreContext);
  const { showLogin, setShowLogin } = useContext(StoreContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [confirmPaymentsShow, setConfirmPaymentsShow] = useState(false);
  const [paymentsShow, setPaymentsShow] = useState(false);
  const closeModal = () => {
    setShow(false);
  };
  const handleback = () => {
    navigate(-1);
  };
  const [propData, setPropData] = useState(0);
  const params = useParams();
  const [tenantData, setTenantData] = useState(0);
  const [investment, setInvestment] = useState(0);
  const [simulatedPayments, setSimulatedPayments] = useState(false);
  const [incomeRatio, setIncomeRatio] = useState(false);
  const [descriptionSplit, setDescriptionSplit] = useState([]);
  const [paymentsData, setPaymentsData] = useState([]);
  const investmentsPropertiesEndpoint = "/investments_api/investments";
  const investmentsTenantsEndpoint = "/investments_api/tenants";
  const moneyFormatter = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });
  const percentageFormatter = Intl.NumberFormat("es-CO", {
    style: "percent",
    maximumFractionDigits: 2,
  });

  const handleInvestmentChange = (v) => {
    setInvestment(v);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handlePaymentsShow = () => {
    ReactGA.event({
      action: "investment_modal_viewed",
      category: "webpage",
      label: "application",
      value: "viewed",
    });
    async function getPayments() {
      await axios
        .get(
          investmentsPropertiesEndpoint +
            "/property_payments?prop_id=" +
            params.id +
            "&investment_value=" +
            investment
        )
        .then((response) => {
          setSimulatedPayments(response.data);
          return response.data;
        });
    }
    getPayments();
    setConfirmPaymentsShow(true);
  };

  const handlePaymentsClose = () => {
    setPaymentsShow(false);
    setConfirmPaymentsShow(false);
  };

  const closeLoginModal = () => {
    setShowLogin(false);
  };
  const handleLoginShow = () => {
    setShowLogin(true);
  };

  useEffect(() => {
    if (investment !== 0 && confirmPaymentsShow) {
      setPaymentsShow(true);
    }
  }, [simulatedPayments]);

  useEffect(() => {
    async function getData() {
      await axios
        .get(
          investmentsPropertiesEndpoint +
            "/property_payments?prop_id=" +
            params.id
        )
        .then((response) => {
          setSimulatedPayments(response.data);
          return response.data;
        });

      await axios
        .get(
          investmentsPropertiesEndpoint +
            "/property_payments?prop_id=" +
            params.id
        )
        .then((response) => {
          setPaymentsData(response.data);
        });
      await axios
        .get(investmentsPropertiesEndpoint + "?id=" + params.id)
        .then((response) => {
          setPropData(response.data);
          return response.data;
        })
        .then(async (data) => {
          await axios
            .get(
              investmentsTenantsEndpoint +
                "?tenant_id=" +
                data[0]["InvestmentProperty"].tenant_id
            )
            .then((res) => {
              setTenantData(res.data);
              return res.data[0];
            });
        });
    }
    getData();

    try {
      setDescriptionSplit(propData[0].description.split("\r\n"));
    } catch (e) {}
  }, [params]);

  useEffect(() => {
    if (propData) {
      setIncomeRatio(
        simulatedPayments[simulatedPayments.length - 1][
          "Utilidades acumuladas"
        ] / propData[0]["InvestmentProperty"]["money_invested"]
      );

      setInvestment(
        Math.ceil(
          (propData[0]["InvestmentProperty"]["money_invested"] -
            propData[0]["InvestmentProperty"]["money_invested"] *
              (propData[0]["InvestmentProperty"]["money_invested"] /
                propData[0]["InvestmentProperty"]["acquisition_cost"]) +
            Math.ceil(
              propData[0]["InvestmentProperty"]["money_invested"] / 19 / 100000
            ) *
              100000) /
            2
        )
      );
    }
  }, [propData]);

  if (!propData || investment === 0) return null;

  var min =
    Math.ceil(
      propData[0]["InvestmentProperty"]["money_invested"] / 19 / 100000
    ) * 100000;

  var max =
    propData[0]["InvestmentProperty"]["money_invested"] -
    propData[0]["InvestmentProperty"]["money_invested"] *
      (propData[0]["InvestmentProperty"]["money_invested"] /
        propData[0]["InvestmentProperty"]["acquisition_cost"]);
  var percentage =
    (((investment - min) * 100) / (max - min)).toFixed(4).toString() + "%";
  return (
    <div className="w-100">
      <LoginModal
        show={showLogin}
        handleClose={closeLoginModal}
        handleInvestmentShow={handleShow}
      />
      <Modal show={show} handleClose={closeModal} type="contact" />
      {paymentsShow && (
        <FinancialModal
          simulatedPayments={simulatedPayments}
          investmentValue={investment}
          show={paymentsShow}
          handleClose={handlePaymentsClose}
        ></FinancialModal>
      )}
      <div className="flex wrap content-center my-5 ">
        <button className="btn-transparent-grey back" onClick={handleback}>
          <span className="icon"></span>Atrás
        </button>
        <div className="investment-header">
          <Carousel
            type="invest-carousel"
            images_number={propData[0]["InvestmentProperty"]["images_number"]}
            images_location={
              propData[0]["InvestmentProperty"]["images_location"]
            }
          />

          {propData[0]["InvestmentProperty"]["acquisition_cost"] >
            propData[0]["InvestmentProperty"]["money_invested"] && (
            <div className="prop-invest-card">
              <div className="title"></div>
              <div className="range-estimated">
                <h4 className="bold text-center">
                  {moneyFormatter.format(investment * (incomeRatio - 1))}
                </h4>
                <h5 className="dark-purple p5">rendimientos estimados</h5>
              </div>
              <div className="range-container">
                <output className="bubble-range" style={{ left: percentage }}>
                  {moneyFormatter.format(investment)}
                </output>
                <input
                  className="mb-0"
                  type="range"
                  min={min}
                  max={max}
                  step="50000"
                  value={investment}
                  style={{
                    background: `linear-gradient(to right, #00C1AF 0%, #00C1AF ${percentage}, #d9d9d9 ${percentage}, #d9d9d9 100%)`,
                  }}
                  onChange={(e) => handleInvestmentChange(e.target.value)}
                />
                <div className="subtitle mt-0">
                  <p className="p7">Inversion</p>
                  <p className="p7">Max.</p>
                </div>
              </div>
              <div className="buttons">
                <button
                  className="btn-rounded-invert p4"
                  onClick={handlePaymentsShow}
                >
                  Detalles
                </button>
                <button
                  className="btn-rounded-green p4"
                  onClick={() => {
                    //if (loggedIn) {
                    handleShow();
                    // } else {
                    //   handleLoginShow();
                    // }
                  }}
                >
                  Invertir
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="investment-container">
          <h4 className="invest-title">
            {propData[0]["InvestmentProperty"]["investment_name"]}{" "}
          </h4>
          {propData[0]["InvestmentProperty"]["acquisition_cost"] >
            propData[0]["InvestmentProperty"]["money_invested"] && (
            <InfoFinancial propData={propData} propPayments={paymentsData} />
          )}

          <InfoProp propData={propData} propPayments={paymentsData} />

          <InfoUser tenantData={tenantData} propData={propData} />

          {propData[0]["InvestmentProperty"]["acquisition_cost"] >
            propData[0]["InvestmentProperty"]["money_invested"] && (
            <>
              <LegalInfo />

              {/* <DueDiligence /> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
