export const amenities = [
    {
        title: "Ascensor",
        apiName: "lift",
    },
    {
        title: "Seguridad Privada",
        apiName: "residential_security",
    },
    {
        title: "Depósito Privado",
        apiName: "private_deposit",
    },
    {
        title: "Gimnasio",
        apiName: "gym",
    },
    {
        title: "Terraza",
        apiName: "terrace",
    },
    {
        title: "Salón Comunal",
        apiName: "community_room",
    },
    {
        title: "Parqueadero Visitantes",
        apiName: "visitor_parking",
    },
    {
        title: "Zonas Verdes",
        apiName: "urban_green_spaces",
    },
    {
        title: "Lavandería",
        apiName: "laundry_room",
    },
    {
        title: "Zona Infantil",
        apiName: "childrens_area",
    },
    {
        title: "Piscina",
        apiName: "pool",
    },
    {
        title: "Turco / Sauna",
        apiName: "sauna",
    },
    {
        title: "Cancha de Squash",
        apiName: "squash_court",
    },
    {
        title: 'Conjunto Cerrado',
        apiName: 'gated_complex'
    },
    {
        title: 'Mostrar conjunto en el Detalle',
        apiName: 'have_complex_details'
    },
    {
        title: '¿Para Remodelar?',
        apiName: 'remodeling_needed'
    },
    {
        title: 'Amoblado',
        apiName: 'furnished'
    },
    {
        title: 'Terraza privada',
        apiName: 'private_terrace'
    },
    {
        title: 'Planta eléctrica',
        apiName: 'power_plant'
    },
    {
        title: 'Dúplex',
        apiName: 'duplex'
    },
    {
        title: 'Balcón',
        apiName: 'balcony'
    },
    {
        title: 'Zona de ropas',
        apiName: 'clothing_area'
    },
    {
        title: 'Estudio',
        apiName: 'study'
    },
    {
        title: 'Cuarto de servicio',
        apiName: 'service_room'
    },
    {
        title: 'Baño de servicio',
        apiName: 'service_bathroom'
    },
    {
        title: 'Estar de T.V',
        apiName: 'tv_room'
    },
    {
        title: 'Salón de juegos',
        apiName: 'game_room'
    },
    {
        title: 'Teatrino',
        apiName: 'theater'
    },
    {
        title: 'Salón de reuniones',
        apiName: 'meeting_room'
    },
    {
        title: 'Caldera',
        apiName: 'boiler'
    },
    {
        title: 'Salón de empleados',
        apiName: 'employments_room'
    },
    {
        title: 'Cancha de tennis',
        apiName: 'tennis_court'
    },
    {
        title: 'Jaula de golf',
        apiName: 'golf_cage'
    },
    {
        title: 'Jacuzzi',
        apiName: 'jacuzzi'
    },
    {
        title: 'Cafetería',
        apiName: 'coffee_shop'
    },
    {
        title: 'Sala(s) de juntas',
        apiName: 'boardroom'
    },
    {
        title: 'Auditorio',
        apiName: 'auditorium'
    },
    {
        title: 'Baños Comunales',
        apiName: 'communal_bathrooms'
    },
    {
        title: 'Sala de lactancia',
        apiName: 'lactation_room'
    },
    {
        title: 'Sala(s) de descanso',
        apiName: 'rest_room'
    },
    {
        title: 'Cabinas telefónicas',
        apiName: 'phone_booths'
    },
    {
        title: 'Cocineta',
        apiName: 'kitchenette'
    }
]