export function emailValidation(email) {
  const emailValidation = new RegExp(
    "^$|^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
  );
  if (!emailValidation.test(email)) {
    return false;
  }
  return true;
}


