const images = {
  Albornoz: require('./Albornoz.png'),
  Maldonado: require('./Maldonado.png'),
  Arredondo: require('./Arredondo.png'),
  Botero: require('./Botero.png'),
  Casado: require('./Casado.png'),
  Uribe: require('./Uribe.png'),
  Rodriguez: require('./Rodriguez.png'),
  Santanilla: require('./Santanilla.png'),
};
export default images;
