import React from "react";
import { tenantFeatures } from "../../utils/tenantFeatures";
import { FinancialCard } from "../../Components/FinancialCard";

export function InfoUser(props) {
  const moneyFormatter = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });
  const percentageFormatter = Intl.NumberFormat("es-CO", {
    style: "percent",
    maximumFractionDigits: 2,
  });
  const tenantData = props.tenantData;
  const propData = props.propData;
  const monthlyPayment =
    propData[0]["InvestmentProperty"]["rent_payment"] +
    propData[0]["InvestmentProperty"]["principal_payment"];
  return (
    <>
      <div>
        <h4 className="invest-title"> El Inquilino</h4>
        <p className="invest-subtitle"> Resumen</p>
        <p className="gray">
          Los usuarios son personas con un buen historial crediticio y trabajos
          estables. En Toperty nos aseguramos que tengan la suficiente capacidad
          de pago para cumplir con las obligaciones del programa y tengan altas
          probabilidades de obtener un crédito al final del programa para
          completar la compra del inmueble.
        </p>
      </div>
      <p className="invest-subtitle">Información general</p>
      <div className="financial-card-container">
        {tenantFeatures["personal_data"].map(({ name, apiName }) => {
          if (
            tenantData[apiName] ||
            apiName === "co_applicant" ||
            apiName === "roommates"
          ) {
            let value = tenantData[apiName];

            if (apiName === "birthdate") {
              value =
                String(
                  new Date(new Date() - new Date(value)).getFullYear() - 1970
                ) + " años";
            }
            return <FinancialCard key={name} title={name} value={value} />;
          } else {
            return null;
          }
        })}
      </div>
      <p className="invest-subtitle">Finanzas núcleo familiar</p>
      <div className="financial-card-container ">
        {tenantFeatures["financial_data"].map(({ name, apiName }) => {
          if (tenantData[apiName]) {
            let value = tenantData[apiName];
            if (apiName === "last_credit_score_date") {
              let objectDate = new Date(value);
              let day = objectDate.getDate();
              let month = objectDate.getMonth();

              let year = objectDate.getFullYear();
              value = year + "/" + (month + 1) + "/" + day;
            } else if (
              (apiName === "personal_monthly_income" ||
                apiName === "household_monthly_income") &&
              name !== "Ingresos / cuota mensual"
            ) {
              value = moneyFormatter.format(value);
            } else if (apiName === "debt_percentage") {
              value = percentageFormatter.format(value);
            } else if (name === "Ingresos / cuota mensual") {
              value = String((value / monthlyPayment).toFixed(1)) + "x";
            }
            return <FinancialCard key={name} title={name} value={value} />;
          } else {
            return null;
          }
        })}
      </div>
    </>
  );
}
