import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";

function Body({ step }) {
  return (
    <Row className="justify-content-center">
      <Col lg={12}>
        <div className={`_${step} icon`} />
      </Col>
      <Col lg={9} className="tutorial-info">
        {step >= 1 && (
          <h3 className="bold text-center">
            {step === 1
              ? "Oportunidades"
              : step === 2
              ? "Rentabilidad"
              : step === 3
              ? "Finca Raíz"
              : "error"}
          </h3>
        )}
        <p className="fs-5 text-center ">
          {step === 0
            ? "Con el portal de Inversionistas podrás crecer tu patrimonio invirtiendo en un mejor futuro para personas y familias que quieren cumplir su sueño de tener casa propia y monitorear tu inversión."
            : step === 1
            ? "En este portal podrás ver las oportunidades de inversión en inmuebles que tenemos disponibles."
            : step === 2
            ? "Para cada oportunidad incluimos análisis detallados de la rentabilidad y potencial de valorización de cada activo."
            : step === 3
            ? "Tu inversión está respaldada por inmuebles que son cuidadosamente seleccionados por nosotros."
            : "error"}
        </p>
      </Col>
    </Row>
  );
}

export function InvestModal(props) {
  const show = props.show;
  const handleClose = props.handleClose;
  const [step, setStep] = useState(0);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={`invest-modal ${props.type}`}
      centered
    >
      <Modal.Header closeButton>
        {props.type === "contact" ? (
          <h4>Contáctanos</h4>
        ) : (
          step >= 1 && (
            <button className="btn-back" onClick={() => setStep(step - 1)} />
          )
        )}
      </Modal.Header>
      <Modal.Body>
        {props.type === "tutorial" ? (
          <>
            <Body step={step} />
            <button
              className="btn-rounded-blue my-3 next-step"
              onClick={step < 3 ? () => setStep(step + 1) : handleClose}
            >
              {step === 0
                ? "Comenzar"
                : step >= 1 && step < 3
                ? "Siguiente"
                : "Ver oportunidades"}
            </button>
            {step >= 1 && (
              <div className="step-selector">
                <button
                  className={`${step === 1 && "active"}`}
                  onClick={() => setStep(1)}
                ></button>
                <button
                  className={`${step === 2 && "active"}`}
                  onClick={() => setStep(2)}
                ></button>
                <button
                  className={`${step === 3 && "active"}`}
                  onClick={() => setStep(3)}
                ></button>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="section">
              <div className="info">
                <p className="title">Llamada</p>
                <p className="fs-5">Agenda una video llamada con nosotros</p>
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://calendly.com/toperty_founders/toperty-founders"
              >
                <button className="btn-rounded-invert">Agendar</button>
              </a>
            </div>
            <div className="section">
              <div className="info">
                <p className="title">WhatsApp</p>
                <p className="fs-5">Escríbenos</p>
              </div>
              <a
                href="https://api.whatsapp.com/send/?phone=573154092293&text&type=phone_number&app_absent=0"
                rel="noreferrer"
                target="_blank"
              >
                <button className="btn-rounded-invert">Abrir</button>
              </a>
            </div>
          </>
        )}
      </Modal.Body>
      {props.type === "contact" && (
        <Modal.Footer>
          <p className="fs-6">Síguenos en nuestras redes</p>
          <div className="media">
            <a
              href="https://twitter.com/topertyco"
              className="twitter"
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon"></div>
            </a>
            <a
              href="https://www.facebook.com/Toperty-101079869335912"
              className="facebook"
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon"></div>
            </a>
            <a
              href="https://instagram.com/toperty.co?igshid=YmMyMTA2M2Y="
              className="instagram"
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon"></div>
            </a>
            <a
              href="https://www.linkedin.com/company/topertyco/"
              className="linkedin"
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon"></div>
            </a>
            <a
              href="https://www.tiktok.com/@topertyco"
              className="tiktok"
              target="_blank"
              rel="noreferrer"
            >
              <div className="icon"></div>
            </a>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}
