import React from 'react';
import { Amenities } from '../../Components/Amenities';
import { InfoPropCard } from '../../Components/InfoPropCard';
import { PropMap } from '../../Components/PropMap';
import { amenities } from '../../utils/amenities';
import { titles } from '../../utils/propFeatures';

export function InfoProp(props) {
  const propData = props.propData;
  return (
    <div className='px-2 mb-5'>
      <h4 className='invest-title'> El Inmueble</h4>
      <p className='invest-subtitle'> Resumen</p>
      <p className='gray my-4'>
        En Toperty seleccionamos minuciosamente cada uno de nuestros inmuebles,
        teniendo en cuenta factores clave como los datos del mercado, sus
        características y su ubicación para elegir oportunidades que tengan alto
        potencial de valorización.
      </p>
      <div className='info-prop'>
        <div className='prop-cards-container'>
          <div className='info-lg-container'>
            {titles.map(({ row, name, apiName }) => {
              const propTypes = { 1: 'Apartamento', 2: 'Casa' };
              if (
                propData[0]['InvestmentProperty'][apiName] ||
                apiName === 'bedrooms' ||
                apiName === 'prop_type'
              ) {
                return (
                  <InfoPropCard
                    invest='true'
                    key={name}
                    column='4'
                    row={row}
                    title={name}
                    concept={apiName}
                    info={
                      apiName === 'prop_type'
                        ? propTypes[
                            propData[0]['InvestmentProperty']['prop_type_id']
                          ]
                        : propData[0]['InvestmentProperty'][apiName]
                    }
                  />
                );
              } else {
                return null;
              }
            })}
            <InfoPropCard
              invest='true'
              column='4'
              row='administration_fee'
              title='Administración'
              concept='administration_fee'
              info={propData[0]['InvestmentProperty']['administration_fee']}
            />
          </div>
          <Amenities
            amenities={amenities}
            propData={propData[0]['InvestmentProperty']}
          />
        </div>
        <PropMap
          lg='12'
          sm='12'
          invest='true'
          latitude={propData[0]['InvestmentProperty']['latitude']}
          longitude={propData[0]['InvestmentProperty']['longitude']}
        />
      </div>
    </div>
  );
}
