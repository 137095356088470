import React from "react";
import { Col, Row } from "react-bootstrap";

export function PasswordForgotten(props) {
  return (
    <div className="login-container">
      <div className="login-form">
        <h4 className="title">Inicia sesión o regístrate</h4>
        <Row className="justify-content-center">
          <Col className="">
            <div className="logo-purple" />
            <p className="bold p2 dark-purple">¡Revisa tu correo!</p>
            <p className="p2 dark-purple">
              Te hemos enviado un correo para activar tu cuenta. Puede estar en
              tu bandeja de entrada o tu bandeja de spam.
            </p>
          </Col>
        </Row>
        <button
          className="btn-rounded-invert w-100 py-3"
          onClick={() => props.handleClose()}
        >
          Cerrar
        </button>
      </div>
    </div>
  );
}
