import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { Login } from "../Pages/Login/Login";
import { StoreContext } from "../utils/store";
import { financialColumns } from "../utils/tableColumns";
import { FinancialCalcCards } from "./CalcCards";
import { FinancialTable } from "./Table";

function tooltip(id, text) {
  return (
    <ReactTooltip
      id={id}
      place="top"
      effect="solid"
      type="info"
      className="tooltip"
    >
      {text}
    </ReactTooltip>
  );
}

export function FinancialModal(props) {
  let COPLocale = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });
  const { isMobile, isDesktop } = useContext(StoreContext);
  const show = props.show;
  const investmentValue = props.investmentValue;

  const simulatedPayments = props.simulatedPayments;
  const handleClose = props.handleClose;
  if (Array.isArray(simulatedPayments)) {
    return (
      <Modal show={show} onHide={handleClose} className="financial-modal">
        <Modal.Header closeButton>
          <h4 className="bold mb-0">Resultados simulación:</h4>
          <h5 className="gray">Valores en pesos</h5>
          <div className="result">
            <div className="final-value block" data-tip data-for="valorTip">
              <div className="icon"></div>
              <p className="title bold">Ingresos totales</p>
              {tooltip(
                "valorTip",
                "Suma del valor nominal de todos los flujos de caja " +
                  "recibidos durante el programa."
              )}
              <p className="data">
                {COPLocale.format(
                  simulatedPayments[simulatedPayments.length - 1][
                    "Utilidades acumuladas"
                  ].toFixed(0)
                )}
              </p>
            </div>
            <div
              className="monthly-payment block"
              data-tip
              data-for="precioTip"
            >
              <div className="icon"></div>
              <p className="title bold">Inversión inicial</p>
              <p className="data">{COPLocale.format(investmentValue)}</p>
              {tooltip(
                "precioTip",
                "Valor de la inversión hecha al inicio del proyecto."
              )}
            </div>
            <div
              className="monthly-payment block"
              data-tip
              data-for="pagomensualTip"
            >
              <div className="icon"></div>
              <p className="title bold">Ingreso mensual neto</p>
              <p className="data">
                {COPLocale.format(
                  simulatedPayments[1]["Utilidades"].toFixed(0)
                )}
              </p>
              {tooltip(
                "pagomensualTip",
                "Ingresos mensuales obtenidos durante el programa (incluye arriendo y ahorro)."
              )}
            </div>
            <div className="final-month block" data-tip data-for="mesTip">
              <div className="icon"></div>
              <p className="title bold">Duración</p>
              <p className="data">{simulatedPayments.length} meses</p>
              {tooltip(
                "mesTip",
                "Mes en el que finaliza el programa y el inquilino completa el % mínimo de adquisición."
              )}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {isMobile && (
            <div className="calc-cards-container">
              {simulatedPayments.map((payment) => {
                return (
                  <FinancialCalcCards
                    cuota={payment["Mes"]}
                    renta={payment["Pago de arriendo"].toFixed(0)}
                    compra={payment["Abono a capital"].toFixed(0)}
                    total={payment["Pago total"].toFixed(0)}
                    costos={payment["Costos"].toFixed(0)}
                    utilities={payment["Utilidades"].toFixed(0)}
                  ></FinancialCalcCards>
                );
              })}
            </div>
          )}
          {isDesktop && (
            <FinancialTable
              columns={financialColumns}
              data={simulatedPayments}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <p className="sm bold dark-purple">Información legal</p>
          <p className="sm">
            Toperty, pone a disposición el presente Simulador, el cual tiene
            fines informativos y no comporta oferta o promesa de contratar. Este
            simulador es indicativo y está destinado a proporcionar información
            y estimaciones de carácter general basadas en la información
            proporcionada por el usuario. El resultado de esta simulación no
            comprende una certificación o recomendación comercial, contable,
            tributaria o legal. Los términos de esta simulación son
            suministrados con base en las condiciones comerciales y de mercado
            que han sido establecidas para la fecha en que se realiza, y sujeta
            a disponibilidad de los inmuebles. Los datos suministrados en el
            presente simulador serán tratados conforme la política de
            tratamiento de datos de Toperty, la cual podrá ser consultada en{" "}
            <a href="/personaldata">
              <u>nuestra política de tratamiento de datos</u>
            </a>
          </p>
        </Modal.Footer>
      </Modal>
    );
  }
}

export function LoginModal(props) {
  const show = props.show;
  const handleClose = props.handleClose;

  return (
    <Modal show={show} onHide={handleClose} className="login-modal">
      <Modal.Body>
        <Login handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
}

export function NotificationModal(props) {
  const show = props.show;
  const handleClose = props.handleClose;
  const message = props.message;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>{/* TODO */}</Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>{/* TODO */}</Modal.Footer>
    </Modal>
  );
}

export function LoadingModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="loading-modal"
      centered
    >
      <Modal.Body>
        <h4>un momento por favor</h4>
        <h3>Actualizando Perfil</h3>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
