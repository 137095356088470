import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Alert from "../../Components/Alert";
import { ProfilePicture } from "../../Components/ProfileImage";
import { StoreContext } from "../../utils/store";
import { ProfileInteractions } from "./ProfileInteractions";
import { BankingInfo } from "./ProfileMultitab/BankingInfo";
import { Credentials } from "./ProfileMultitab/Credentials";
import { PersonalInfo } from "./ProfileMultitab/PersonalInfo";
import { RestorePassword } from "./ProfileMultitab/RestorePassword";
import { Validations } from "./ProfileValidations";
import { LoginModal, LoadingModal } from "../../Components/ModalView";

export function FormUpdateProfile() {
  const params = useParams();
  const { profile, setProfile } = useContext(StoreContext);
  const [page, setPage] = useState("personal");
  const [validate, setValidate] = useState(false);
  const [inputErrors, setInputErrors] = useState([]);
  const [passwordError, setPasswordError] = useState(false);
  const [dataChange, setDataChange] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [sentInvestorData, setSentInvestorData] = useState(false);
  const { setLoggedIn } = useContext(StoreContext);
  const investmentsInvestorsEndpoint = "/investments_api/investors";
  const [showChangedProfile, setShowChangedProfile] = useState(false);

  useEffect(() => {
    if (params.registration_user) {
      if (params.registration_user === "success") {
        setShowChangedProfile(true);
      } else {
        console.log("registration_user:");
        console.log(params.registration_user);
        async function getData() {
          await axios
            .get(
              investmentsInvestorsEndpoint +
                "/confirm_signup?registration_user=" +
                params.registration_user
            )
            .then((response) => {
              setLoggedIn(true);
              localStorage.setItem("loggedIn", true);
              localStorage.setItem("profile", JSON.stringify(response.data));
              setProfile(response.data);
              setPage("forgot_password");
              return response.data;
            });
        }
        getData();
      }
    }
  }, [params]);

  const errorMessages = {
    name: "Introduce un nombre válido.",
    email: "Introduce una dirección de mail válida",
    phone:
      "Introduzca un número de teléfono válido con diez dígitos, si es un teléfono fijo recuerde agregar el indicativo nacional",
    document_type: "Ingrese un tipo de documento válido",
    document_number: "Ingrese un número de documento válido",
    password: "Las contraseñas no coinciden.",
    wrong_password: "La contraseña actual es incorrecta.",
    bank_account_holder: "Ingrese un nombre válido",
    bank_account: "Ingrese una cuenta de banco válida",
    bank_account_type: "Ingrese un tipo de cuenta válido",
  };

  const closeLoading = () => {
    setSendingData(false);
  };

  function handleSubmit() {
    setValidate(true);
  }

  function showPasswordError() {
    setSendingData(false);
    setPasswordError(true);
  }

  function handleSentData() {
    setSendingData(false);
    window.location.href = "/profile/success";
  }

  let successform = new ProfileInteractions({
    handleSentData: handleSentData,
    showPasswordError: showPasswordError,
  });

  const handleInvestorData = (k, v) => {
    setDataChange(true);
    if (v === "") {
      delete profile[k];
    } else {
      setProfile({
        ...profile,
        [k]: v,
      });
    }
  };

  useEffect(() => {
    if (validate) {
      setInputErrors([]);
      let errors = Validations(profile);
      console.log(errors);
      setValidate(false);
      setInputErrors(errors);
      if (errors.length === 0) {
        setSendingData(true);
        setTimeout(() => {
          successform.updateProfile(profile, setSentInvestorData);
        }, 2000);
      }
    }
  }, [validate]);

  return (
    <div className="profile-container">
      {showChangedProfile && (
        <Alert alert={"Tu perfil se ha actualizado correctamente"} />
      )}
      <LoginModal />
      <LoadingModal show={sendingData} handleClose={closeLoading} />

      <ProfilePicture />
      <div className="picture-info">
        <p className="picture-name">{profile["name"]}</p>
        <p className="picture-email">{profile["email"]}</p>
      </div>
      <div className="profile-card">
        <div className="profile-slider">
          <button
            className={page === "personal" ? "active" : ""}
            onClick={() => setPage("personal")}
          >
            Datos personales
          </button>
          <button
            className={page === "bank" ? "active" : ""}
            onClick={() => setPage("bank")}
          >
            Datos bancarios
          </button>
          <button
            className={
              page === "credentials" || page === "forgot_password"
                ? "active"
                : ""
            }
            onClick={() => setPage("credentials")}
          >
            Credenciales
          </button>
        </div>
        <div className="profile-content-container">
          {page === "personal" ? (
            <PersonalInfo
              investorData={profile}
              handleInvestorData={handleInvestorData}
              inputErrors={inputErrors}
              errorMessages={errorMessages}
            />
          ) : page === "bank" ? (
            <BankingInfo
              investorData={profile}
              handleInvestorData={handleInvestorData}
              inputErrors={inputErrors}
              errorMessages={errorMessages}
            />
          ) : page === "forgot_password" ? (
            <RestorePassword
              investorData={profile}
              handleInvestorData={handleInvestorData}
              inputErrors={inputErrors}
              errorMessages={errorMessages}
            />
          ) : (
            <Credentials
              investorData={profile}
              handleInvestorData={handleInvestorData}
              inputErrors={inputErrors}
              errorMessages={errorMessages}
              showPasswordError={passwordError}
            />
          )}

          {page !== "forgot_password" ? (
            <button
              className="btn-rounded-green"
              id="NextBtn"
              onClick={handleSubmit}
              disabled={dataChange === false}
            >
              {"Guardar"}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
