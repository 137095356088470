import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProfileInteractions } from "../ProfileInteractions";

export function RestorePassword(props) {
  const params = useParams();
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)
  const [sendingData, setSendingData] = useState(false)
  const [sentData, setSentData] = useState(false)

  const handlePasswordChange = (newPassword) => {
    setPassword(newPassword);
  }

  const handleConfirmPasswordChange = (newPassword) => {
    setConfirmPassword(newPassword);
  }

  const handleRestoredPassword = () => {
    setSentData(true);
    window.location.href = "/profile/success";
  }

  let successform = new ProfileInteractions({});

  async function restorePassword(e) {
    e.preventDefault();
    setConfirmPasswordError(false);
    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      return
    }
    setSendingData(true);
    await successform.restorePassword({
      registration_user: params.registration_user,
      password: password,
      handleRestoredPassword: handleRestoredPassword
    });
  }


  return (
    <div className='profile-content'>
      <div className='profile-input-container'>
        <div className='input-container'>
          <b className='p4'>{"Nueva contraseña"} </b>
          <input
            autoFocus
            className='form-control'
            type="password"
            id="password-in"
            value={password}
            onChange={(e) =>
              handlePasswordChange(e.target.value)
            }
          />
        </div>

        <div className='input-container'>
          <b className='p4'>{"Confirmar contraseña"} </b>
          <input
            className='form-control'
            type="password"
            id={"confirmPassword-in"}
            value={confirmPassword}
            onChange={(e) =>
              handleConfirmPasswordChange(e.target.value)
            }
          />
        </div>

        {confirmPasswordError && (
          <div className="error-msg">
            <p>
              Las contraseñas no son iguales, reingrésalas e inténtalo de nuevo
            </p>
          </div>)}
        {sendingData && !sentData && (
          <h4 className='bolder'>
            Actualizando contraseña
            <div className='lds-ellipsis'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </h4>
        )}
      </div>

      <button
        className='btn-rounded-green'
        onClick={restorePassword}>
        Cambiar contraseña
      </button>

    </div>
  );
}
