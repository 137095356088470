import Tooltip from "@mui/material/Tooltip";
import React from "react";

export function FinancialCard(props) {
  const moneyFormatter = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });
  const percentageFormatter = Intl.NumberFormat("es-CO", {
    style: "percent",
    maximumFractionDigits: 2,
  });
  var value = props.value;

  if (props.type === "money") {
    value = moneyFormatter.format(value);
  } else if (props.type === "percentage") {
    value = (value * 100).toFixed(2) + "%";
    if (props.title === "Retorno estimado (Post-tax)") {
      value = value + " e.a";
    } else if (value.length <= 3) {
      value = value.split("%")[0] + ".00" + "%";
    }
  } else if (props.type === "multiple_percentage") {
    value =
      percentageFormatter.format(value) +
      " / " +
      percentageFormatter.format(value * 12);
  } else if (props.type === "months") {
    value = value + " meses";
  }
  return (
    <>
      <div className="info-financial-card">
        <div className="title p4">
          {props.title}
          {props.tooltip && (
            <Tooltip
              title={props.tooltip}
              placement="right"
              className="card-tooltip"
            >
              <div className="info">
                <div className="icon"></div>
              </div>
            </Tooltip>
          )}
        </div>
        <p className="value p1 bold dark-purple m-0">{value}</p>
      </div>
    </>
  );
}
