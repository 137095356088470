import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export function PersonalData() {
  return (
    <>
      <Container fluid>
        <Col md={12}>
          <Row className="justify-content-center">
            <Col xs={11} md={8} className="text-justify my-5">
              <h3 className="bold text-center my-5">
                Política de Privacidad y Tratamiento de Datos Personales de la
                Plataforma Toperty
              </h3>
              <h5 className="bold">Introducción</h5>
              <p>
                TOPERTY S.A.S., identificada con el NIT. (901.621.275-1), con
                domicilio principal en la dirección Carrera 11A # 93-67 Oficina
                503, Bogotá, Colombia (en adelante “Toperty”), reconoce la
                importancia de la seguridad, privacidad y confidencialidad de
                los datos personales de sus clientes, usuarios, colaboradores,
                proveedores, aliados y en general de todos sus grupos de interés
                respecto de los cuales ejerce tratamiento de información
                personal, por lo que en cumplimiento de las disposiciones
                constitucionales y legales, adoptó la presente Política de
                Privacidad y Tratamiento de Datos Personales (la “Política”).
              </p>
              <p>
                Toperty está comprometida con el cumplimiento de la regulación
                en materia de protección de Datos Personales y con el respeto de
                los derechos de los Titulares de la información. Por esta razón,
                adopta la presente Política de obligatoria aplicación en todas
                las actividades que involucren el Tratamiento de Datos
                Personales y de obligatorio cumplimiento por parte de Toperty,
                sus Administradores, Colaboradores y los terceros con quien éste
                se relacione contractual o comercialmente. El uso de los
                Servicios ofrecidos en nuestra Plataforma (en adelante “los
                Servicios”) y/o la aceptación expresa e inequívoca de la
                presente Política, implica la aceptación de los titulares de la
                presente Política y su autorización para los usos y otros
                tratamientos que aquí se describen.
              </p>
              <h5 className="bold">Objetivo</h5>
              <p>
                El objetivo de la presente Política es garantizar los derechos
                que en virtud de la ley y la constitución tienen los Titulares
                de los Datos Personales, identificar las finalidades para las
                que serán tratados los Datos Personales, así como la definición
                de los lineamientos para la atención de consultas y reclamos de
                los Titulares de los Datos Personales.
              </p>
              <h5 className="bold">Alcance</h5>
              <p>
                Esta Política es de obligatorio y estricto cumplimiento por
                parte de Toperty, sus directores, administradores, colaboradores
                y los demás terceros quienes la representan o actúan por ella, o
                con los que Toperty tiene algún tipo de vínculo, sea legal,
                comercial o convencional.
              </p>
              <h5 className="bold">Definiciones</h5>
              <p>
                Los términos aquí definidos serán usados a lo largo del presente
                documento y su significado será el que se establece a
                continuación:
              </p>
              <ul>
                <li>
                  <p>
                    <b>Aviso de privacidad: </b>es una de las opciones de
                    comunicación verbal o escrita que brinda Toperty para darle
                    a conocer a los titulares de la información, la existencia y
                    las formas de acceder a las políticas de tratamiento de la
                    información y el objetivo de su recolección y uso
                  </p>
                </li>
                <li>
                  <p>
                    <b>Autorización: </b>consentimiento previo, expreso e
                    informado del Titular del dato para llevar a cabo el
                    Tratamiento. Esta puede ser i) escrita; ii) verbal o; iii)
                    conductas inequívocas que permitan concluir de forma
                    razonable que el Titular aceptó el Tratamiento de sus datos.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Base de Datos: </b>conjunto organizado de Datos
                    Personales que sean objeto de Tratamiento, electrónico o no,
                    cualquiera que fuere la modalidad de su formación,
                    almacenamiento, organización y acceso.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Consulta: </b>solicitud del Titular del Dato Personal, de
                    las personas autorizadas por éste, o las autorizadas por
                    ley, para conocer la información que reposa sobre él en las
                    Bases de Datos de Toperty.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Dato Personal: </b>cualquier información vinculada o que
                    pueda asociarse a una o varias personas naturales,
                    determinadas o determinables.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Dato Personal Privado: </b>dato que por su naturaleza
                    íntima o reservada sólo es relevante para el Titular.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Dato Personal Público: </b>dato calificado como tal según
                    los mandatos de la ley o de la Constitución Política y todos
                    aquellos que no sean semiprivados, privados o sensibles. Por
                    ejemplo: los datos contenidos en documentos públicos,
                    registros públicos, gacetas y boletines oficiales y
                    sentencias judiciales debidamente ejecutoriadas que no estén
                    sometidos a reserva, los relativos al estado civil de las
                    personas, a su profesión u oficio y a su calidad de
                    comerciante o de servidor público. Son públicos los Datos
                    Personales existentes en el registro mercantil de las
                    Cámaras de Comercio. Asimismo, son datos públicos, los que,
                    en virtud de una decisión del Titular o de un mandato legal,
                    se encuentren en archivos de libre acceso y consulta. Estos
                    datos pueden ser obtenidos y ofrecidos sin reserva alguna y
                    sin importar si hacen alusión a información general, privada
                    o personal.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Dato personal semiprivado: </b>dato que no tiene
                    naturaleza íntima, reservada, ni pública y cuyo conocimiento
                    o divulgación puede interesar no sólo a su Titular sino a
                    cierto sector o grupo de personas, o a la sociedad en
                    general. Por ejemplo: el dato referente al cumplimiento e
                    incumplimiento de las obligaciones financieras o los datos
                    relativos a las relaciones con las entidades de la seguridad
                    social.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Dato personal sensible: </b>dato que afecta la intimidad
                    de la persona o cuyo uso indebido puede generar su
                    discriminación. Por ejemplo: aquellos que revelen el origen
                    racial o étnico, la orientación política, las convicciones
                    religiosas o filosóficas, la pertenencia a sindicatos,
                    organizaciones sociales, de derechos humanos o que promueva
                    intereses de cualquier partido político o que garanticen los
                    derechos y garantías de partidos políticos de oposición,
                    datos relativos a la salud, a la vida sexual y los datos
                    biométricos (huellas dactilares, íris, etc), entre otros.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Encargado: </b>persona natural o jurídica que realiza el
                    Tratamiento de datos por cuenta del Responsable del
                    Tratamiento.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Incidente de seguridad: </b>Se refiere a la violación de
                    los códigos de seguridad o la pérdida, robo y/o acceso no
                    autorizado de información de una base de datos.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Información financiera y comercial: </b>e refiere a la
                    inforamción de carácter financiera y comercial que el
                    Titular de manera voluntaria quiere proveer a Toperty para
                    identificar la viabilidad de la prestación de servicios.
                  </p>
                </li>
                <li>
                  <p>
                    <b>
                      Información con respecto a aportes y contribuciones al
                      sistema de seguridad social:
                    </b>
                    El Titular autoriza que se consulte cualquier información
                    contenida en bases de datos disponibles relacionadas con los
                    aportes al régimen de seguridad social que el Titular ha
                    realizado a título de empleado, trabajador independiente,
                    rentista de capital y cualquier otra calidad, por concepto
                    de pensión, salud, y demás aportes que de manera obligatoria
                    y voluntaria haya realizado. Por lo cual, Toperty tendrá
                    autorización para consultar el estado de aportes al sistema
                    de seguridad social y cualquier información relacionada.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Plataforma: </b>Toperty identificada con el dominio
                    toperty.co.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Reclamo: </b>solicitud del Titular del dato o las
                    personas autorizadas por éste o por la ley para corregir,
                    actualizar o suprimir sus Datos Personales o cuando
                    adviertan que existe un presunto incumplimiento del régimen
                    de protección de datos.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Responsable: </b>Persona natural o jurídica, pública o
                    privada, que por sí misma o en asocio con otros, decida
                    sobre la base de datos y/o el Tratamiento de los datos
                  </p>
                </li>
                <li>
                  <p>
                    <b>Requisito de procedibilidad: </b>paso previo que debe
                    surtir el Titular antes de interponer una queja ante la
                    Superintendencia de Industria y Comercio. Este consiste en
                    una reclamación directa al Encargado o Responsable de sus
                    Datos Personales.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Titular del dato: </b>significa la persona natural cuyos
                    datos personales sean objeto de Tratamiento.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Tratamiento: </b>cualquier operación o conjunto de
                    operaciones sobre Datos Personales como, la recolección, el
                    almacenamiento, el uso, la circulación, transferencia,
                    transmisión, actualización o supresión de los Datos
                    Personales, entre otros. El Tratamiento puede ser nacional
                    (dentro de Colombia) o internacional (fuera de Colombia).
                  </p>
                </li>
                <li>
                  <p>
                    <b>Transmisión: </b>Tratamiento de datos personales que
                    implica la comunicación de los mismos dentro o fuera del
                    territorio de la República de Colombia cuando tenga por
                    objeto la realización de un Tratamiento por el Encargado por
                    cuenta del Responsable.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Transferencia: </b>Tratamiento de Datos Personales que
                    tiene lugar cuando el Responsable y/o Encargado del
                    Tratamiento de Datos Personales, envía los Datos Personales
                    a un receptor, que a su vez es Responsable del Tratamiento y
                    se encuentra dentro o fuera del país.
                  </p>
                </li>
              </ul>
              <h5 className="bold">
                Principios para la Recolección de los Datos Personales
              </h5>
              <ul>
                <li>
                  <p className="bold">Principio de libertad</p>
                  <ul>
                    <li>
                      <p>
                        Salvo norma legal en contrario, la recolección de los
                        datos sólo puede ejercerse con la autorización previa,
                        expresa e informada del Titular. Los datos personales no
                        podrán ser obtenidos o divulgados sin el previo
                        consentimiento del Titular, o en ausencia de mandato
                        legal o judicial que releve el consentimiento.{" "}
                      </p>
                    </li>
                    <li>
                      <p>
                        Se deberá informar al titular del dato de manera clara,
                        suficiente y previa acerca de la finalidad de la
                        información suministrada y, por tanto, no podrá
                        recopilarse datos sin la especificación sobre la
                        finalidad de los mismos.{" "}
                      </p>
                    </li>
                    <li>
                      <p>
                        No se podrán utilizar medios engañosos o fraudulentos
                        para recolectar y realizar Tratamiento de datos
                        personales.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p className="bold">
                    Principio de limitación de la recolección
                  </p>
                  <ul>
                    <li>
                      Sólo deben recolectarse los Datos Personales que sean
                      estrictamente necesarios para el cumplimiento de las
                      finalidades del Tratamiento, de tal forma que se encuentra
                      prohibido el registro y divulgación de datos que no
                      guarden estrecha relación con el objetivo del Tratamiento.
                      En consecuencia, debe hacerse todo lo razonablemente
                      posible para limitar el procesamiento de datos personales
                      al mínimo necesario. Es decir, los datos deberán ser: (i)
                      adecuados, (ii) pertinentes y (iii) acordes con las
                      finalidades para las cuales fueron previstos.
                    </li>
                  </ul>
                </li>
              </ul>
              <h5 className="bold">
                Principios para el Tratamiento de Datos Personales
              </h5>
              <p>
                En el desarrollo, interpretación y aplicación de la presente
                Política, se aplicarán los siguientes principios:
              </p>
              <ul>
                <li>
                  <p className="bold">Legalidad</p>
                  <p>
                    El tratamiento de datos personales es una actividad reglada
                    que debe sujetarse a lo establecido en la ley y en las demás
                    disposiciones que la desarrollen.
                  </p>
                </li>
                <li>
                  <p className="bold">Finalidad</p>
                  <p>
                    El tratamiento de datos personales debe obedecer a una
                    finalidad legítima de acuerdo con la Constitución y la ley,
                    la cual debe ser informada al Titular.
                  </p>
                </li>
                <li>
                  <p className="bold">Libertad</p>
                  <p>
                    El tratamiento de datos personales solo puede ejercerse con
                    el consentimiento, previo, expreso e informado del Titular.
                    Los datos personales no podrán ser obtenidos o divulgados
                    sin previa autorización, o en ausencia de mandato legal o
                    judicial que releve el consentimiento.
                  </p>
                </li>
                <li>
                  <p className="bold">Veracidad o calidad</p>
                  <p>
                    La información personal sujeta a tratamiento debe ser veraz,
                    completa, exacta, actualizada, comprobable y comprensible,
                    en este sentido, se prohíbe el tratamiento de datos
                    parciales, incompletos, fraccionados o que induzcan a error.
                  </p>
                </li>
                <li>
                  <p className="bold">Transparencia</p>
                  <p>
                    En el tratamiento de datos personales debe garantizarse el
                    derecho del Titular a obtener del Responsable del
                    Tratamiento o del Encargado del Tratamiento, en cualquier
                    momento y sin restricciones, información acerca de la
                    existencia de datos que le conciernan.
                  </p>
                </li>
                <li>
                  <p className="bold">Acceso y circulación restringida</p>
                  <p>
                    El tratamiento de datos personales está sujeto a los límites
                    que se derivan de la naturaleza de los mismos, de las
                    disposiciones de ley y la Constitución. En este sentido, su
                    tratamiento solo podrá hacerse por personas autorizadas por
                    el Titular y/o por las personas previstas en ley. Los datos
                    personales, salvo la información pública, no podrán estar
                    disponibles en Internet u otros medios de divulgación o
                    comunicación masiva, salvo que el acceso sea técnicamente
                    controlable para brindar un conocimiento restringido solo a
                    los Titulares o terceros autorizados conforme a la ley.
                  </p>
                </li>
                <li>
                  <p className="bold">Seguridad</p>
                  <p>
                    La información personal sujeta a tratamiento por el
                    Responsable del Tratamiento o Encargado del Tratamiento a
                    que se refiere la ley, se deberá manejar con las medidas
                    técnicas, humanas y administrativas que sean necesarias para
                    otorgar seguridad a los registros evitando su adulteración,
                    pérdida, consulta, uso o acceso no autorizado o fraudulento.
                  </p>
                </li>
                <li>
                  <p className="bold">Confidencialidad</p>
                  <p>
                    Todas las personas que intervengan en el tratamiento de
                    datos personales que no tengan la naturaleza de públicos
                    están obligadas a garantizar la reserva de la información,
                    inclusive después de finalizada su relación con alguna de
                    las labores que comprende el tratamiento, pudiendo solo
                    realizar suministro o comunicación de datos personales
                    cuando ello corresponda al desarrollo de las actividades
                    autorizadas en la ley y en los términos de la misma.
                  </p>
                </li>
                <li>
                  <p className="bold">Temporalidad</p>
                  <p>
                    Los datos personales se conservarán únicamente por el tiempo
                    razonable y necesario para cumplir la finalidad del
                    tratamiento y las exigencias legales o instrucciones de las
                    autoridades de vigilancia y control u otras autoridades
                    competentes. Los datos serán conservados cuando ello sea
                    necesario para el cumplimiento de una obligación legal o
                    contractual. Para determinar el término del tratamiento se
                    considerarán las normas aplicables a cada finalidad y los
                    aspectos administrativos, contables, fiscales, jurídicos e
                    históricos de la información.
                  </p>
                </li>
                <li>
                  <p className="bold">No discriminación</p>
                  <p>
                    Queda prohibido realizar cualquier acto de discriminación
                    por las informaciones recaudadas en las bases de datos o
                    archivos.
                  </p>
                </li>
                <li>
                  <p className="bold">Reparación</p>
                  <p>
                    Es obligación indemnizar los perjuicios causados por las
                    posibles fallas en el tratamiento de datos personales.
                  </p>
                </li>
              </ul>
              <h5 className="bold">Datos Personales suministrados</h5>
              <ul>
                <li>
                  <p>
                    Toperty podrá pedir expresamente a los titulares o
                    recolectar de su comportamiento los datos que sean
                    necesarios para cumplir las finalidades de las bases de
                    datos, los cuales son -entre otros- los siguientes:
                  </p>
                  <ul>
                    <li>
                      <p>Nombre y apellidos;</p>
                    </li>
                    <li>
                      <p>Nacionalidad;</p>
                    </li>
                    <li>
                      <p>Número de identificación;</p>
                    </li>
                    <li>
                      <p>Fecha y lugar de nacimiento;</p>
                    </li>
                    <li>
                      <p>Dirección de correspondencia y/o residencia;</p>
                    </li>
                    <li>
                      <p>Teléfono de contacto;</p>
                    </li>
                    <li>
                      <p>Correo electrónico;</p>
                    </li>
                    <li>
                      <p>Referencias de cualquier naturaleza;</p>
                    </li>
                    <li>
                      <p>
                        Antecedentes comerciales o información biográfica,
                        financiera, judicial, disciplinaria y familiar, y la
                        relacionada con otras compañías o con entidades
                        públicas;
                      </p>
                    </li>
                    <li>
                      <p>Fotografías;</p>
                    </li>
                    <li>
                      <p>Sexo;</p>
                    </li>
                    <li>
                      <p>Género identitario;</p>
                    </li>
                    <li>
                      <p>Datos de geolocalización;</p>
                    </li>
                    <li>
                      <p>Enlaces de perfiles de redes sociales;</p>
                    </li>
                    <li>
                      <p>Sitio Web;</p>
                    </li>
                    <li>
                      <p>Historial laboral;</p>
                    </li>
                    <li>
                      <p>
                        Datos de medios transaccionales tales como tarjetas de
                        crédito o cuentas bancarias, únicamente para efectos de
                        realizar los cobros por los Servicios de la Plataforma,
                        en el evento en que se cobren, o para poner a
                        disposición de los usuarios de la Plataforma medios
                        transaccionales;
                      </p>
                    </li>
                    <li>
                      <p>
                        Autorizaciones para desarrollar actividades relacionadas
                        con los servicios ofrecidos en la Plataforma;
                      </p>
                    </li>
                    <li>
                      <p>Historial de uso de Servicios;</p>
                    </li>
                    <li>
                      <p>
                        Historial de casos abiertos con servicio al cliente;
                      </p>
                    </li>
                    <li>
                      <p>Contenido favorito;</p>
                    </li>
                    <li>
                      <p>Historial de preguntas a expertos en el sitio web;</p>
                    </li>
                    <li>
                      <p>
                        Historial crediticio y capacidad de pago y de aportes al
                        sistema de seguridad social;
                      </p>
                    </li>
                    <li>
                      <p>
                        Permisos y autorizaciones requeridos para proveer
                        servicios;
                      </p>
                    </li>
                    <li>
                      <p>
                        Razón o denominación social de la empresa con la que se
                        encuentra vinculado;
                      </p>
                    </li>
                    <li>
                      <p>Identificación tributaria;</p>
                    </li>
                    <li>
                      <p>
                        Información financiera para verificar la viabilidad de
                        la prestación de servicios por parte de Toperty;
                      </p>
                    </li>
                    <li>
                      <p>
                        Cualquier otro dato que fuere necesario para lograr las
                        finalidades descritas;
                      </p>
                    </li>
                    <li>
                      <p>
                        Cualquier otro dato relevante para la prestación de los
                        Servicios que ofrece Toperty.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <h5 className="bold">
                Tratamiento al cual serán sometidos los Datos Personales
              </h5>
              <p>
                Toperty realizará el Tratamiento de los Datos Personales de
                acuerdo con las condiciones establecidas por el Titular, la ley
                o las entidades públicas para el cumplimiento de las actividades
                propias de su objeto social como pueden ser la contratación o
                ejecución de los Servicios que ésta ofrece.
              </p>
              <p>
                El Tratamiento de los Datos Personales se podrá realizar a
                través de medios físicos, automatizados o digitales de acuerdo
                con el tipo y forma de recolección de la información.
              </p>
              <h5 className="bold">Finalidades como Responsable</h5>
              <p>
                Toperty, en su calidad de Responsable, también podrá tratar los
                Datos Personales, entre otros, para los siguientes fines:
              </p>
              <ul>
                <li>
                  <p>
                    Conocer su comportamiento financiero, comercial y el
                    cumplimiento de sus obligaciones legales.
                  </p>
                </li>
                <li>
                  <p>
                    Prevenir el lavado de activos, la financiación del
                    terrorismo, así como detectar el fraude, corrupción, y otras
                    actividades ilegales.
                  </p>
                </li>
                <li>
                  <p>
                    Realizar, validar, autorizar o verificar transacciones,
                    incluyendo, cuando sea requerido, la consulta y reproducción
                    de datos sensibles tales como la huella digital, imagen o
                    voz, entre otros.
                  </p>
                </li>
                <li>
                  <p>
                    Realizar todas las gestiones necesarias tendientes a
                    confirmar y actualizar la información del cliente.
                  </p>
                </li>
                <li>
                  <p>
                    Validar y verificar la identidad del cliente para el
                    ofrecimiento y administración de productos y servicios, así
                    mismo para compartir la información con diversos actores del
                    mercado.
                  </p>
                </li>
                <li>
                  <p>
                    Establecer una relación contractual, así como mantener y
                    terminar una relación contractual.
                  </p>
                </li>
                <li>
                  <p>
                    Ofrecer y prestar productos o servicios a través de
                    cualquier medio o canal de acuerdo con el perfil del cliente
                    y los avances tecnológicos.
                  </p>
                </li>
                <li>
                  <p>
                    Ejercer su derecho de conocer de manera suficiente al
                    Titular con quien se propone entablar relaciones, prestar
                    servicios y valorar el riesgo presente o futuro de las
                    mismas relaciones y servicios.
                  </p>
                </li>
                <li>
                  <p>
                    Efectuar las gestiones pertinentes para el desarrollo de la
                    etapa precontractual, contractual y post contractual con
                    Toperty, respecto de cualquiera de los Servicios ofrecidos
                    por ésta, que haya o no adquirido o respecto de cualquier
                    relación negocial subyacente que tenga con ella, así como
                    dar cumplimiento a la ley colombiana o extranjera y a las
                    órdenes de autoridades judiciales o administrativas;
                  </p>
                </li>
                <li>
                  <p>
                    Realizar actividades de mercadeo, ventas y promocionales,
                    telemarketing (mercadeo telefónico), servicio al cliente,
                    actividades de activación de marca, premios y promociones,
                    directamente o a través de terceros derivados de alianzas
                    comerciales o de cualquier vínculo;
                  </p>
                </li>
                <li>
                  <p>
                    Implementar estrategias de relacionamiento con clientes,
                    proveedores, accionistas y otros terceros con los cuales
                    Toperty tenga relaciones contractuales o legales;
                  </p>
                </li>
                <li>
                  <p>
                    Realizar invitaciones a eventos, mejorar servicios, y todas
                    aquellas actividades asociadas a la relación comercial o
                    vínculo existente con Toperty, o aquel que llegare a tener;
                  </p>
                </li>
                <li>
                  <p>
                    Gestionar trámites (solicitudes, quejas, reclamos), efectuar
                    encuestas de satisfacción respecto de los bienes y servicios
                    ofrecidos por Toperty, o empresas vinculadas y los aliados
                    comerciales de ésta;
                  </p>
                </li>
                <li>
                  <p>
                    Dar a conocer, Datos Personales dentro y fuera del país a
                    las compañías matrices, filiales o subsidiarias de Toperty,
                    como consecuencia de la ley que así lo requiera o para
                    implementar servicios de computación en la nube;
                  </p>
                </li>
                <li>
                  <p>
                    Ordenar, catalogar, clasificar, dividir o separar la
                    información suministrada por los Titulares de datos.
                    Verificar, corroborar, comprobar, validar, investigar o
                    comparar la información suministrada por los Titulares de
                    datos, con cualquier información de que disponga
                    legítimamente, como relaciones comerciales.
                  </p>
                </li>
                <li>
                  <p>
                    Acceder, consultar, comparar y evaluar toda la información
                    que sobre el Titular se encuentre almacenada en las bases de
                    datos de cualquier central de riesgo crediticio, financiero,
                    de antecedentes judiciales o de seguridad, de naturaleza
                    estatal o privada, nacional o extranjera, o cualquier base
                    de datos comercial o de servicios, que permita establecer de
                    manera integral e histórica completa, el comportamiento que
                    como deudor, usuario, cliente, garante, endosante, afiliado,
                    beneficiario, suscriptor, contribuyente y/o como Titular de
                    servicios financieros, comerciales o de cualquier otra
                    índole;
                  </p>
                </li>
                <li>
                  <p>
                    Para fines de seguridad, mejoramiento del servicio, la
                    experiencia en los servicios, los Datos Personales podrán
                    ser utilizados, entre otros, como prueba en cualquier tipo
                    de proceso;
                  </p>
                </li>
                <li>
                  <p>
                    Conocer, almacenar y procesar toda la información
                    suministrada por los Titulares de datos en una o varias
                    bases de datos, en el formato que estime más conveniente;
                  </p>
                </li>
                <li>
                  <p>
                    Registrar, almacenar y actualizar la información y los Datos
                    Personales en sus Bases de Datos;
                  </p>
                </li>
                <li>
                  <p>
                    Realizar análisis e informes estadísticos (incluso de los
                    datos sensibles) e investigaciones comerciales, de riesgos,
                    de mercado, interbancarias y financieras;
                  </p>
                </li>
                <li>
                  <p>
                    Consultar, solicitar o verificar la información y Datos
                    Personales del Titular en bases de datos de entidades
                    públicas o privadas, o con personas naturales o jurídicas, o
                    en buscadores públicos, redes sociales o publicaciones
                    físicas o electrónicas, bien fuere en Colombia o en el
                    exterior;
                  </p>
                </li>
                <li>
                  <p>Procesar solicitudes de los usuarios de la Plataforma;</p>
                </li>
                <li>
                  <p>
                    Realizar todas las gestiones de orden tributario, contable,
                    fiscal y de facturación;
                  </p>
                </li>
                <li>
                  <p>
                    Cumplir el objeto de la relación comercial o civil que se
                    hubiere adquirido con los titulares;
                  </p>
                </li>
                <li>
                  <p>
                    El cumplimiento del objeto social de Toperty y de las
                    funcionalidades de la Plataforma;
                  </p>
                </li>
                <li>
                  <p>
                    Cumplir con la propuesta de valor y el nivel de servicio
                    ofrecido a los Usuarios;
                  </p>
                </li>
                <li>
                  <p>
                    Crear y administrar las cuentas dentro de la Plataforma;
                  </p>
                </li>
                <li>
                  <p>
                    Efectuar convenios o alianzas con entidades gubernamentales,
                    de la sociedad civil, internacionales y no gubernamentales
                    que puedan potencializar los servicios y beneficios para los
                    usuarios.
                  </p>
                </li>
              </ul>
              <h5 className="bold">Finalidades como Encargado</h5>
              <p>
                Toperty actuará como Encargada del tratamiento de Datos
                Personales que conozca a través de sus aliados estratégicos,
                para lo cual estos últimos se comprometen a solicitar la
                correspondiente Autorización de Tratamiento de Datos Personales
                por parte de los Titulares.
              </p>
              <p>
                El tratamiento y finalidad de los datos que Toperty realiza en
                nombre de los Responsables (aliados estratégicos) será el
                establecido en sus Políticas de Tratamiento de Datos Personales
                y el siguiente:
              </p>
              <ol>
                <li>
                  <p>
                    El desarrollo, ejecución y cumplimiento del objeto social de
                    Toperty y el propósito de la Plataforma
                  </p>
                </li>
                <li>
                  <p>
                    El cumplimiento de deberes legales, contables, comerciales y
                    regulatorios.
                  </p>
                </li>
                <li>
                  <p>El acceso a los Servicios prestados por la Plataforma.</p>
                </li>
                <li>
                  <p>
                    La obtención de la autorización estará inicialmente en
                    cabeza de los Responsables.
                  </p>
                </li>
              </ol>
              <h5 className="bold">
                Uso de Datos Personales bajo esta Política
              </h5>
              <p>
                Toperty solo tratará los datos personales y otra información de
                los Titulares para las finalidades descritas y los usos
                autorizados en esta Política o en las leyes vigentes.
              </p>
              <h5 className="bold">
                Limitaciones temporales al Tratamiento de Datos
              </h5>
              <p>
                Toperty solo podrá recolectar, almacenar, usar o circular los
                datos personales durante el tiempo que sea razonable y
                necesario, de acuerdo con las finalidades que justificaron el
                tratamiento, atendiendo a las disposiciones aplicables a la
                materia de que se trate y a los aspectos administrativos,
                contables, fiscales, jurídicos e históricos de la información.
              </p>
              <p>
                Una vez cumplida la o las finalidades del tratamiento y sin
                perjuicio de normas legales que dispongan lo contrario,Toperty
                deberá proceder a la supresión de los datos personales en su
                posesión. No obstante lo anterior, los datos personales deberán
                ser conservados cuando así se requiera para el cumplimiento de
                una obligación legal o contractual.
              </p>
              <h5 className="bold">Derechos de los Titulares de los datos</h5>
              <p> Los Titulares de los Datos personales, tienen derecho a:</p>
              <ul>
                <li>
                  <p>
                    Conocer, actualizar y rectificar sus Datos Personales frente
                    a los Responsables del Tratamiento o Encargados del
                    Tratamiento. Este derecho se podrá ejercer, entre otros
                    frente a datos parciales, inexactos, incompletos,
                    fraccionados, que induzcan a error, o aquellos cuyo
                    Tratamiento esté expresamente prohibido o no haya sido
                    autorizado. Para el efecto es necesario establecer
                    previamente la identificación de la persona para evitar que
                    terceros no autorizados accedan a los datos del Titular.
                  </p>
                </li>
                <li>
                  <p>
                    Solicitar prueba de la autorización otorgada a Toperty,
                    salvo que se trate de uno de los casos en los que no es
                    necesaria la autorización, de conformidad con lo previsto en
                    el artículo 10 de la ley 1581 de 2012.
                  </p>
                </li>
                <li>
                  <p>
                    Ser informado por Toperty, previa solicitud, respecto del
                    uso que le ha dado a sus Datos Personales.
                  </p>
                </li>
                <li>
                  <p>
                    Presentar ante la Superintendencia de Industria y Comercio
                    quejas por infracciones a lo dispuesto en la ley y las demás
                    normas que la modifiquen, adicionen o complementen.
                  </p>
                </li>
                <li>
                  <p>
                    Revocar la autorización y/o solicitar la supresión del dato
                    cuando en el Tratamiento no se respeten los principios,
                    derechos y garantías constitucionales y legales.
                  </p>
                </li>
                <li>
                  <p>
                    La solicitud de supresión de la información y la revocatoria
                    de la autorización no procederá cuando el Titular tenga un
                    deber legal o contractual de permanecer en la base de datos
                    del Responsable o Encargado.
                  </p>
                </li>
                <li>
                  <p>
                    Acceder en forma gratuita a sus Datos Personales que hayan
                    sido objeto de Tratamiento.
                  </p>
                </li>
              </ul>
              <h5 className="bold">
                Deberes de Toperty cuando obra como Responsable o Encargado del
                Tratamiento de Datos Personales
              </h5>
              <p>
                Toperty está obligada a cumplir los deberes impuestos por la
                ley. Por ende, debe obrar de tal forma que cumpla con los
                siguientes deberes:
              </p>
              <ul>
                <li>
                  <p>
                    Garantizar al Titular, en todo tiempo, el pleno y efectivo
                    ejercicio de los derechos mencionados en esta Política.
                  </p>
                </li>
                <li>
                  <p>
                    Observar los principios establecidos en esta política en el
                    Tratamiento de Datos Personales
                  </p>
                </li>
                <li>
                  <p>
                    Conservar la información bajo las condiciones de seguridad
                    necesarias para impedir su adulteración, pérdida, consulta,
                    uso o acceso no autorizado o fraudulento.
                  </p>
                </li>
                <li>
                  <p>Actualizar la información cuando sea necesario.</p>
                </li>
                <li>
                  <p>
                    Rectificar los Datos Personales cuando ello sea procedente.
                  </p>
                </li>
                <li>
                  <p>
                    Suministrar al Encargado del Tratamiento únicamente los
                    Datos Personales cuyo Tratamiento esté previamente
                    autorizado.
                  </p>
                </li>
                <li>
                  <p>
                    Garantizar que la información que se suministre al Encargado
                    del Tratamiento sea veraz, completa, exacta, actualizada,
                    comprobable y comprensible.
                  </p>
                </li>
                <li>
                  <p>
                    Comunicar de forma oportuna al Encargado del Tratamiento,
                    todas las novedades respecto de los datos que previamente le
                    haya suministrado y adoptar las demás medidas necesarias
                    para que la información suministrada a este se mantenga
                    actualizada.
                  </p>
                </li>
                <li>
                  <p>
                    Informar de manera oportuna al Encargado del Tratamiento las
                    rectificaciones realizadas sobre los Datos Personales para
                    que éste proceda a realizar los ajustes pertinentes.
                  </p>
                </li>
                <li>
                  <p>
                    Exigir al Encargado del Tratamiento en todo momento, el
                    respeto a las condiciones de seguridad y privacidad de la
                    información del Titular.
                  </p>
                </li>
                <li>
                  <p>
                    Informar al Encargado del Tratamiento cuando determinada
                    información se encuentra en discusión por parte del Titular,
                    una vez se haya presentado la reclamación y no haya
                    finalizado el trámite respectivo.
                  </p>
                </li>
                <li>
                  <p>
                    Informar a la Superintendencia de Industria y Comercio
                    cuando se presenten violaciones a los códigos de seguridad y
                    existan riesgos en la administración de la información de
                    los Titulares.
                  </p>
                </li>
                <li>
                  <p>
                    Cumplir las instrucciones y requerimientos que imparta la
                    Superintendencia de Industria y Comercio.
                  </p>
                </li>
              </ul>
              <h5 className="bold">De la autorización</h5>
              <p className="bold">
                Autorización para el Tratamiento de Datos y forma de obtención.
              </p>
              <p>
                Los obligados a cumplir esta Política deberán obtener de parte
                del Titular su autorización previa, expresa e informada para
                recolectar y tratar sus Datos Personales. Esta obligación no es
                necesaria cuando se trate de datos de naturaleza pública.
              </p>
              <p>
                Para obtener la autorización, es necesario informarle al Titular
                de los Datos Personales de forma clara y expresa lo siguiente:
              </p>
              <ul>
                <li>
                  <p>
                    El Tratamiento al cual serán sometidos sus Datos Personales
                    y la finalidad del mismo;
                  </p>
                </li>
                <li>
                  <p>
                    El carácter facultativo de la respuesta a las preguntas que
                    le sean hechas, cuando estas versen sobre datos sensibles o
                    sobre los datos de las niñas, niños y adolescentes;
                  </p>
                </li>
                <li>
                  <p>
                    Los derechos que le asisten como Titular previstos en el
                    Artículo 8 de la Ley 1581 de 2012;
                  </p>
                </li>
                <li>
                  <p>
                    La identificación, dirección física o electrónica de
                    Toperty.
                  </p>
                </li>
              </ul>
              <p>
                La Autorización del Titular debe obtenerse a través de cualquier
                medio que pueda ser objeto de consulta posterior, tal como la
                página web, formularios, formatos, actividades, concursos,
                presenciales o en redes sociales, formato de PQR, mensajes de
                datos o Apps, email, teléfono, registro o autenticación en la
                Plataforma, entre otras.
              </p>
              <p>
                La autorización también podrá obtenerse a partir de conductas
                inequívocas del Titular del Dato que permitan concluir de manera
                razonable que éste otorgó su consentimiento para el Tratamiento.
                Dichas conductas deben ser muy claras de manera que no admitan
                duda o equivocación sobre la voluntad de autorizar el
                Tratamiento.
              </p>
              <p className="bold">Manifestaciones de los Titulares.</p>
              <p>Los titulares manifiestan que:</p>
              <ul>
                <li>
                  <p>
                    Mediante el suministro voluntario de alguno de los datos
                    personales en la forma señalada en el numeral anterior, la
                    autorización expresa verbal o por escrito, el registro o
                    creación de un perfil o cuenta en la Plataforma, o la
                    autenticación o ingreso a la Plataforma por medio de la
                    autenticación con la cuenta del titular suscrita a un
                    tercero, incluyendo, pero sin limitarse a Microsoft, Google
                    o Facebook, el titular autoriza expresa e inequívocamente a
                    Toperty para recolectar datos personales y cualquier otra
                    información que suministre, así como para realizar el
                    tratamiento sobre sus datos personales, de conformidad con
                    esta Política y la ley.
                  </p>
                </li>
                <li>
                  <p>
                    Se les informó acerca de las finalidades para las cuales se
                    utilizarán los datos sensibles recolectados, los cuales se
                    encuentran descritos en el título I de esta Política.
                  </p>
                </li>
                <li>
                  <p>
                    Entienden que son datos sensibles aquellos que afectan la
                    intimidad del titular o cuyo uso indebido puede generar
                    discriminación, así como los de origen racial o étnico,
                    orientación política, convicciones religiosas o filosóficas,
                    pertenencia a sindicatos, organizaciones sociales, datos
                    relacionados con el estado de salud, la vida sexual y los
                    datos biométricos.
                  </p>
                </li>
                <li>
                  <p>
                    Comprenden las medidas de seguridad que Toperty implementa
                    para brindar protección a los datos personales que recolecta
                    y, por tanto, aceptan las mismas.
                  </p>
                </li>
              </ul>
              <p className="bold">
                Autorización para Tratamiento de Datos Sensibles.
              </p>
              <p>
                Cuando se trate de la recolección de datos sensibles se deben
                cumplir los siguientes requisitos:
              </p>
              <ul>
                <li>
                  <p>La autorización debe ser explícita;</p>
                </li>
                <li>
                  <p>
                    Se debe informar al Titular que no está obligado a autorizar
                    el Tratamiento de dicha información;
                  </p>
                </li>
                <li>
                  <p>
                    Se debe informar de forma explícita y previa al Titular
                    cuáles de los datos que serán objeto de Tratamiento son
                    sensibles y la finalidad del mismo;
                  </p>
                </li>
                <li>
                  <p>
                    Se requiere autorización por parte del representante o tutor
                    para el Tratamiento de datos de niños, niñas y adolescentes.
                  </p>
                </li>
              </ul>
              <p className="bold">
                Autorización para Tratamiento de datos de niños, niñas y
                adolescentes
              </p>
              <p>
                Cuando se trate de la recolección y Tratamiento de datos de
                niños, niñas y adolescentes se deben cumplir los siguientes
                requisitos:
              </p>
              <ul>
                <li>
                  <p>
                    La autorización debe ser otorgada por personas que estén
                    facultadas para representar los niños, niñas o adolescentes.
                    El representante de los niños, niñas o adolescentes deberá
                    garantizarles el derecho a ser escuchados y valorar su
                    opinión del Tratamiento teniendo en cuenta la madurez,
                    autonomía y capacidad de los niños, niñas y adolescentes
                    para entender el asunto;
                  </p>
                </li>
                <li>
                  <p>
                    Se debe informar que es facultativo responder preguntas
                    sobre datos de los niños, niñas y adolescentes;
                  </p>
                </li>
                <li>
                  <p>
                    Toperty debe asegurar que el Tratamiento de los Datos
                    Personales de los niños, niñas y adolescentes será realizado
                    respetando sus derechos, razón por la cual, en las
                    actividades comerciales y de mercadeo que realice, deberá
                    contar con la autorización previa, expresa e informada del
                    padre o la madre o del representante legal del niño, niña o
                    adolescente.
                  </p>
                </li>
              </ul>
              <p>
                Sin perjuicio de lo anterior, Toperty únicamente tratará los
                datos de los menores de edad para los fines exclusivos de la
                prestación de los Servicios, o cualquier otro fin que sea
                estrictamente necesario para desarrollar el objeto social de
                Toperty.
              </p>
              <p className="bold">Autorización para nuevos usos</p>
              <p>
                Toperty podrá solicitar autorización de los titulares para
                nuevos usos de sus datos o información, para lo cual publicará
                los cambios en la presente Política de Tratamiento en su página
                web o en cualquier medio que estime conveniente según el caso.
              </p>
              <h5 className="bold">Almacenamiento de Datos Personales</h5>
              <p>
                El Titular autoriza expresamente a Toperty para que almacene los
                datos de la forma que considere más oportuna y cumpla con la
                seguridad requerida para la protección de los datos de los
                titulares.
              </p>
              <h5 className="bold">
                Transferencias nacionales o internacionales de Datos Personales
              </h5>
              <p>
                Toperty no realizará transferencias de datos a otros
                Responsables del Tratamiento salvo cuando sea requerido por la
                ley o por un mandato administrativo o judicial.
              </p>
              <p>
                Cuando la transferencia sea internacional, Toperty garantizará
                que el país receptor tendrá los estándares adecuados de
                protección de datos personales.
              </p>
              <p>
                Para todos los efectos se entiende que con la aceptación de esta
                política los titulares consienten que Toperty transfiera
                internacionalmente los datos a sus proveedores de servicios
                computacionales en la nube, frente a los cuales Toperty
                certifica que se encuentran ubicados en una jurisdicción con un
                nivel adecuado de protección (i.e., Estados Unidos en virtud del
                marco Safe Harbor).
              </p>
              <h5 className="bold">
                Transmisiones nacionales o internacionales de Datos Personales
              </h5>
              <p>
                Toperty enviará o transmitirá datos cuando esté autorizado por
                el Titular de la información, o cuando sea requerido por la ley
                o por un mandato administrativo o judicial.
              </p>
              <p>
                Para todos los efectos se entiende que con la aceptación de esta
                política los titulares consienten que Toperty transmita
                internacionalmente los datos a sus proveedores de servicios
                computacionales en la nube, frente a los cuales Toperty
                certifica que se encuentran ubicados en una jurisdicción con un
                nivel adecuado de protección (i.e., Estados Unidos en virtud del
                marco Safe Harbor).
              </p>
              <p>
                Toperty transmitirá datos únicamente a sus proveedores y otras
                partes interesadas, únicamente para las siguientes finalidades:
              </p>
              <ul>
                <li>
                  <p>
                    Realizar servicios en nombre de la Plataforma, incluido el
                    mantenimiento o mantenimiento de cuentas, brindar servicio
                    al cliente, procesar o cumplir con pedidos y transacciones,
                    verificar la información del Usuario, procesar pagos,
                    brindar servicios de publicidad o marketing, brindar
                    servicios analíticos o brindar servicios similares en nombre
                    de la empresa o proveedor de servicio.
                  </p>
                </li>
                <li>
                  <p>
                    Realizar auditorías relacionadas con una interacción actual
                    y transacciones simultáneas, que incluyen, entre otras, el
                    recuento de impresiones de anuncios para visitantes únicos,
                    la verificación del posicionamiento y la calidad de las
                    impresiones de anuncios y la auditoría del cumplimiento de
                    esta especificación y otros estándares.
                  </p>
                </li>
                <li>
                  <p>
                    Detectar incidentes de seguridad, protegerse contra
                    actividades maliciosas, engañosas, fraudulentas o ilegales y
                    enjuiciar a los responsables de esa actividad.
                  </p>
                </li>
                <li>
                  <p>
                    Depuración para identificar y reparar errores que perjudican
                    la funcionalidad prevista existente.
                  </p>
                </li>
                <li>
                  <p>
                    Realización de investigaciones internas para el desarrollo y
                    la demostración tecnológica.
                  </p>
                </li>
                <li>
                  <p>
                    Realizar actividades para verificar o mantener la calidad o
                    seguridad de un servicio o dispositivo que poseemos,
                    fabricamos o está asociado a los servicios que presta la
                    plataforma.
                  </p>
                </li>
              </ul>
              <h5 className="bold">
                Procedimiento para que los Titulares puedan ejercer sus derechos
              </h5>
              <p>
                Para el ejercicio pleno y efectivo de los derechos que le
                asisten a los Titulares de los Datos Personales, o a las
                personas legitimadas por ley para tal efecto, Toperty dispone
                del correo electrónico indicado más adelante, a través del cual
                podrán presentar todas las consultas, quejas y/o reclamos
                asociados al derecho de Habeas Data.
              </p>
              <p>
                Todas las solicitudes que realicen las personas legitimadas para
                conocer los Datos Personales que reposan en Toperty se
                canalizarán a través del mencionado correo electrónico en el
                cual constará la fecha de recibo de la consulta y la identidad
                del solicitante.
              </p>
              <p>
                El reclamo debe dirigirse a Toperty y contener como mínimo la
                siguiente información:
              </p>
              <ul>
                <li>
                  <p>
                    Nombre e identificación del Titular del dato o la persona
                    legitimada.
                  </p>
                </li>
                <li>
                  <p>
                    Descripción precisa y completa de los hechos que dan lugar
                    al reclamo.
                  </p>
                </li>
                <li>
                  <p>Pretensiones.</p>
                </li>
                <li>
                  <p>
                    Dirección física o electrónica para remitir la respuesta e
                    informar sobre el estado del trámite.
                  </p>
                </li>
                <li>
                  <p>
                    Documentos y demás pruebas pertinentes que quiera hacer
                    valer.
                  </p>
                </li>
              </ul>
              <p>
                Una vez recibida la solicitud se procederá con la verificación
                de la identidad del peticionario o de la legitimación de éste
                para tal fin. La respuesta a la consulta deberá comunicarse al
                solicitante en un término máximo de diez (10) días hábiles
                contados a partir de la fecha de recibo de la misma. Cuando no
                fuere posible atender la consulta dentro de dicho término, se
                deberá informar al zinteresado los motivos de la demora y la
                fecha en que se atenderá su consulta, la cual en ningún caso
                podrá superar los cinco (5) días hábiles siguientes al
                vencimiento del primer término.
              </p>
              <p>
                Si la solicitud o el reclamo resulta incompleto, se requerirá al
                interesado dentro de los cinco (5) días siguientes a su
                recepción para que subsane las fallas. Transcurridos dos (2)
                meses desde la fecha del requerimiento, sin que el solicitante
                presente la información requerida, se entenderá que ha desistido
                del reclamo.
              </p>
              <h5 className="bold">
                Persona o área responsable de la protección de los Datos
                Personales
              </h5>
              <p>
                El área encargada de atender las peticiones, consultas y
                reclamos de los titulares para ejercer sus derechos a conocer,
                actualizar, rectificar y suprimir sus datos y revocar su
                autorización es el área de atención al usuario. Las peticiones,
                consultas y reclamos podrán ser remitidas al correo electrónico
                <a href="mailto:info@toperty.co">info@toperty.co</a>
              </p>
              <h5 className="bold">Datos del Responsable del Tratamiento</h5>
              <p>Razón social: Toperty S.A.S.</p>
              <p>NIT: 901.621.275-1</p>
              <p>Dirección: Carrera 11A # 93-67 Oficina 503</p>
              <p>
                Correo Electrónico{" "}
                <a href="mailto:info@toperty.co">info@toperty.co</a>
              </p>
              <p>Teléfono: (601) 4724688</p>
              <p>Página web: www.toperty.co</p>
              <h5 className="bold">Políticas de seguridad de la información</h5>
              <p>
                Toperty adoptará las medidas técnicas, administrativas y humanas
                necesarias para procurar la seguridad de los Datos Personales a
                los que les da Tratamiento, protegiendo la confidencialidad,
                integridad, uso, acceso no autorizado y/o fraudulento a éstos.
                Para tal fin, ha implementado protocolos de seguridad de
                obligatorio cumplimiento para todo el Personal que tenga acceso
                a estos datos y/o a los sistemas de información.
              </p>
              <p>
                Las políticas internas de seguridad bajo las cuales se conserva
                la información del Titular para impedir su adulteración,
                pérdida, consulta, uso o acceso no autorizado o fraudulento
                serán incluidas en el Programa Integral de Gestión de Datos
                Personales de Toperty. El titular acepta expresamente esta forma
                de protección y declara que la considera conveniente y
                suficiente para todos los propósitos.
              </p>
              <p>
                Toperty protege la información mediante varios mecanismos como,
                entre otros, los siguientes:
              </p>
              <ul>
                <li>
                  <p>
                    Protección de acceso a los datos mediante contraseñas y
                    roles de diferentes niveles de autoridad.
                  </p>
                </li>
                <li>
                  <p>Protección de las contraseñas.</p>
                </li>
                <li>
                  <p>
                    Aseguramiento del nivel de complejidad de las contraseñas de
                    los usuarios
                  </p>
                </li>
                <li>
                  <p>
                    Rastreo de todas las actividades realizadas con las
                    plataformas y sus datos.
                  </p>
                </li>
                <li>
                  <p>Procedimientos de recuperación y redundancia.</p>
                </li>
                <li>
                  <p>Almacenamiento de las copias de respaldo.</p>
                </li>
                <li>
                  <p>
                    Cifrado y protección por contraseña de los computadores
                    desde los que se realiza la manipulación de los datos.
                  </p>
                </li>
              </ul>
              <p>
                Adicionalmente, Toperty cuenta con una política de seguridad de
                información y datos personales de obligatorio cumplimiento
              </p>
              <h5 className="bold">
                Quejas ante la Superintendencia de Industria y Comercio
              </h5>
              <p>
                El Titular, sus causahabientes o apoderados, deberán agotar el
                trámite de consulta ante Toperty o quien éste designe, con
                anterioridad a la presentación de cualquier queja ante la
                Superintendencia de Industria y Comercio.
              </p>
              <h5 className="bold">Término de la Autorización</h5>
              <p>
                El término del Tratamiento de los Datos Personales será desde
                que se otorga la autorización hasta el día en que Toperty se
                disuelva y se liquide o hasta que se termine la finalidad para
                la cual fueron recolectados los Datos Personales.
              </p>
              <h5 className="bold">
                Ajustes a la política de Tratamiento de Datos Personales
              </h5>
              <p>
                Con miras a mantener la vigencia de la política, Toperty puede
                ajustarla y modificarla, indicando la fecha de la actualización
                en la Plataforma o mediante el empleo de otros medios, como
                mensajes de datos, materiales físicos, etc.
              </p>
              <h5 className="bold">Legislación Vigente</h5>
              <ul>
                <li>
                  <p>Artículo 15 de la Constitución Política de Colombia</p>
                </li>
                <li>
                  <p>Ley Estatutaria 1266 de 2008</p>
                </li>
                <li>
                  <p>Ley 1273 de 2009</p>
                </li>
                <li>
                  <p>Ley Estatutaria 1581 de 2012</p>
                </li>
                <li>
                  <p>Decreto 1377 de 2013</p>
                </li>
                <li>
                  <p>Decreto 886 de 2014</p>
                </li>
                <li>
                  <p>Decreto 1074 de 2015</p>
                </li>
                <li>
                  <p>
                    Título V de la Circular Única de la Superintendencia de
                    Industria y Comercio
                  </p>
                </li>
              </ul>
              <p className="">
                TÉRMINOS Y CONDICIONES DEL OFRECIMIENTO, PRECIO Y FORMA DE PAGO
                REFERENTE AL INMUEBLE Y COMISIÓN
              </p>
              <p>
                El PROPIETARIO encarga a Toperty para que, utilizando sus
                conocimientos profesionales, realice las labores de
                intermediación y corretaje tendientes a obtener la venta y/o el
                arrendamiento del INMUEBLE y autoriza lo siguiente:
              </p>
              <ol>
                <li>
                  <p>
                    Inmueble para arrendamiento: El Inmueble será ofrecido para
                    arrendamiento, con una contraprestación equivalente al VALOR
                    ARRIENDO indicado en el formulario. En caso que Toperty
                    encuentre un cliente para el arrendamiento del Inmueble y
                    logre el perfeccionamiento del negocio, ésta tendrá derecho
                    a una comisión que será proporcional al termino del contrato
                    asi: i) para contratos entre 1 y 2 años BRIKSS tendrá
                    derecho a un (1) VALOR ARRIENDO más IVA, ii) para contratos
                    entre 3 y 5 años BRIKSS tendrá derecho a dos (2) montos del
                    VALOR ARRIENDO más IVA, iii) para contratos de 6 años en
                    adelante BRIKSS tendrá derecho a tres (3) montos del VALOR
                    ARRIENDO más IVA, comision(es) que será(n) cancelada(s) por
                    el PROPIETARIO, en la cuenta que BRIKSS le indique, previa
                    presentación de la factura correspondiente, dentro los
                    primeros cinco (5) calendario posteriores al pago del(los)
                    VALOR(ES) ARRIENDO por parte del cliente. BRIKSS recomienda
                    que el PROPIETARIO siempre tome póliza de seguro de
                    arrendamiento con el fin de asegurar el pago del VALOR
                    ARRIENDO. No obstante, es claro que esto corresponde a una
                    simple recomendación y que en caso que el PROPIETARIO decida
                    no tomarla, lo hará bajo su propio riesgo, y acepta que
                    BRIKSS no será responsable ni por el pago efectivo del VALOR
                    ARRIENDO, ni por el proceso legal para el cobro de la deuda
                    y/o restitución del inmueble. 2. INMUEBLE CONSIGNADO PARA
                    VENTA: El INMUEBLE será ofrecido para la venta al público
                    por un monto equivalente al PRECIO DE VENTA. En caso de que
                    BRIKSS encuentre un cliente para la venta del INMUEBLE y
                    cierre el negocio, ésta tendrá derecho a una comisión
                    equivalente al TRES POR CIENTO (3%) del PRECIO DE VENTA, más
                    el IVA, que será cancelada, por el PROPIETARIO, en la cuenta
                    que BRIKSS le indique, previa presentación de la factura
                    correspondiente a más tardar cinco (5) días hábiles, en dos
                    partes: i) 1.5% a la suscripción del contrato de promesa de
                    compraventa y; ii)1,5% el día en que se suscriba la
                    escritura pública de compraventa. PARÁGRAFO I: Si la
                    negociación se hiciere por un valor menor y/o mayor al
                    indicado en el VALOR ARRIENDO y/o el PRECIO DE VENTA, la
                    comisión a la que tiene derecho BRIKSS, se liquidarán sobre
                    el valor real de la negociación. PARÁGRAFO II: Si el
                    PROPIETARIO no cancelare a BRIKSS la comisión aquí señalada,
                    deberá reconocerle sobre lo adeudado, mensualmente,
                    intereses moratorios liquidados a la tasa máxima permitida
                    por la Superintendencia Financiera, sin perjuicio de los
                    derechos de BRIKSS para exigir el pago de lo adeudado. 3.
                    SERVICIOS. En caso de venta y/o arrendamiento, BRIKSS se
                    compromete a elaborar los respectivos documentos para
                    formalizar el negocio. No obstante, con la finalidad de que
                    el cliente conserve su autonomía en materia tributaria y
                    fiscal, BRIKSS se abstendrá de hacer recomendaciones o
                    sugerencias sobre estos temas, y será responsabilidad única
                    del PROPIETARIO la lectura, aprobación y firma de los
                    documentos que perfeccionen el negocio de venta o arriendo.
                    4. DOCUMENTOS: EL PROPIETARIO se compromete a entregar a
                    BRIKSS: (I) Certificado de tradición y libertad con una
                    vigencia no superior a 3 meses, (II) fotocopia del documento
                    de identidad el PROPIETARIO, junto con el poder
                    correspondiente, en caso de que aplique, y (III) paz y salvo
                    de administración, (IV) recibos de pago de impuestos y (V)
                    demás documentos necesarios. 5. PROPIEDAD INTELECTUAL: EL
                    PROPIETARIO declara conocer que las fotografías y el tour
                    360
                  </p>
                </li>
              </ol>
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  );
}
