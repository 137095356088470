import axios from "axios";
import React from "react";

export class loginInteractions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.investorsEndpoint = "/investments_api/investors";
		this.showRegister = props.showRegister;
		this.showLogin = props.showLogin;
		this.confirmLogin = props.confirmLogin;
		this.showLoginError = props.showLoginError;
		this.showLoginConfirmationError = props.showLoginConfirmationError;
		this.finishForgotPassword = props.finishForgotPassword;
		this.finishRegsitry = props.finishRegsitry;
		this.showLoginAPIError = props.showLoginAPIError;

	}

	async login(email, password) {
		let searchUrl = `${this.investorsEndpoint}/login?email=
			${email}&password=${encodeURIComponent(password)}`;
		var success = null;

		try {
			success = await (axios.get(searchUrl)).then((response) => {
				success = true;
				this.confirmLogin(response.data);
				return response.data;
			});
		} catch (error) {

			if (error.response) {
				success = error.response.status;
			}
			else {
				success = 500;
			}

		}
		if (success === 401) {
			this.showLoginError();
		} else if (success === 409) {
			this.showLoginConfirmationError();
		} else if (success === 500) {
			this.showLoginAPIError()
		}
	}

	async checkEmail(email) {
		let searchUrl = `${this.investorsEndpoint}/check?email=
			${email}`;
		var success = null;
		try {
			success = await (axios.get(searchUrl)).then((response) => {
				this.showLogin();
				return response.data;
			})
		}
		catch (error) {
			if (error.response) {
				// Request made and server responded
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			} else if (error.request) {
				// The request was made but no response was received
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
		};
		if (success === null) {
			this.showRegister();
		}
	}

	async forgotPassword(email) {
		let searchUrl = `${this.investorsEndpoint}/forgot_password?email=
			${email}`;
		var success = null;
		try {
			success = await (axios.get(searchUrl)).then((response) => {
				this.finishForgotPassword();
				return response.data;
			})
		}
		catch (error) {
			if (error.response) {
				// Request made and server responded
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			} else if (error.request) {
				// The request was made but no response was received
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
		};
		if (success === null) {
			//TO-DO
		}
	}


	async register(investorData) {
		const tenantData = await axios
			.post(`${this.investorsEndpoint}/signup`, investorData)
			.then((response) => {
				this.finishRegsitry()
				return response.data;
			}).catch(function (error) {
				if (error.response) {
					// Request made and server responded
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
			});
		return tenantData;
	}

	generateString() {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!"#$%&/()=!¡?¿';
		const length = 20;
		let result = ' ';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}

		return result;
	}

	async storeEmail(email) {
		let investorData = { "email": email, "password": this.generateString() };
		const tenantData = await axios
			.post(`${this.investorsEndpoint}/google_sign_in`, investorData)
			.then((response) => {
				localStorage.setItem('profile', JSON.stringify(response.data));
				return response.data;
			}).catch(function (error) {
				if (error.response) {
					// Request made and server responded
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
			});
		return tenantData;
	}

}
