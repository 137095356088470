import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  commercialMetrics,
  generalMetrics,
} from "../../utils/financialFeatures";
import { FinancialCard } from "../../Components/FinancialCard";

export function InfoFinancial(props) {
  const propData = props.propData;
  const propPayments = props.propPayments;
  const investmentsPropertiesEndpoint = "/investments_api/investments";
  const params = useParams();
  const [investmentValue, setInvestmentValue] = useState(
    Math.ceil(
      propData[0]["InvestmentProperty"]["acquisition_cost"] / 19 / 100000
    ) * 100000
  );
  const [simulatedPayments, setSimulatedPayments] = useState(false);
  const [outOfRange, setOutOfRange] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [validation, setValidation] = useState(false);
  const [generalShow, setGeneralShow] = useState(false);

  useEffect(() => {
    if (validation) {
      if (isNaN(parseFloat(investmentValue))) {
        setErrorMsg(true);
        setValidation(false);
        return;
      }
      if (
        parseFloat(investmentValue) <
          Math.ceil(
            propData[0]["InvestmentProperty"]["acquisition_cost"] / 19 / 100000
          ) *
            100000 ||
        parseFloat(investmentValue) >
          propData[0]["InvestmentProperty"]["acquisition_cost"] -
            propData[0]["InvestmentProperty"]["money_invested"]
      ) {
        setOutOfRange(true);
        setErrorMsg(false);
        setValidation(false);
        return;
      }
      async function getPayments() {
        await axios
          .get(
            investmentsPropertiesEndpoint +
              "/property_payments?prop_id=" +
              params.id +
              "&investment_value=" +
              investmentValue
          )
          .then((response) => {
            setSimulatedPayments(response.data);
            return response.data;
          });
      }
      setOutOfRange(false);
      setErrorMsg(false);
      getPayments();
      setValidation(false);
    }
  }, [validation]);

  useEffect(() => {
    if (simulatedPayments) {
      setGeneralShow(true);
    }
  }, [simulatedPayments]);

  return (
    <div className="mb-5">
      <p className="invest-title-2"> Información Financiera</p>
      <p className="invest-subtitle"> Información general</p>
      <div className="financial-card-container">
        {generalMetrics.map(({ name, apiName, type, tooltip }) => {
          if (propData[0]["InvestmentProperty"][apiName]) {
            return (
              <FinancialCard
                key={name}
                title={name}
                type={type}
                tooltip={tooltip}
                value={propData[0]["InvestmentProperty"][apiName]}
              />
            );
          } else {
            return null;
          }
        })}

        <FinancialCard
          title="Duración estimada"
          value={propPayments[propPayments.length - 1]["Mes"]}
          type="months"
          tooltip={
            "Tiempo estimado hasta que finaliza el programa " +
            "(hasta que el inquilino adquiere un porcentaje de la vivienda que" +
            "equivale a la cuota inicial)."
          }
        />
      </div>

      <p className="invest-subtitle">Avalúo comercial y valor de compra</p>

      <div className="financial-card-container">
        {commercialMetrics.map(({ name, apiName, type, tooltip }) => {
          if (propData[0]["InvestmentProperty"][apiName]) {
            return (
              <FinancialCard
                key={name}
                title={name}
                type={type}
                tooltip={tooltip}
                value={propData[0]["InvestmentProperty"][apiName]}
              />
            );
          } else {
            return null;
          }
        })}
        {/* {
    name: "Inversión total",
    apiName: "acquisition_cost",
    type: "money",
    tooltip:
      "Total a invertir para este proyecto, se calcula como: precio" +
      " pactado del inmueble más gastos de cierre de la compra del inmueble menos" +
      " cuota inicial aportada por el usuario.",
  }, */}
        <FinancialCard
          title="Descuento en la compra"
          type="percentage"
          value={
            (propData[0]["InvestmentProperty"]["market_value"] -
              propData[0]["InvestmentProperty"]["toperty_valuation"]) /
            propData[0]["InvestmentProperty"]["market_value"]
          }
          tooltip="Porcentaje de descuento de la compra del inmueble con respecto al avalúo comercial"
        />
        <FinancialCard
          title="Gastos de escrituración"
          type="money"
          value={
            propData[0]["InvestmentProperty"]["toperty_valuation"] * 0.028 +
            propData[0]["InvestmentProperty"]["toperty_valuation"] * 0.004
          }
          tooltip="Gastos en los que se incurrirán para hacer el traspaso de la propiedad"
        />
      </div>

      <p className="invest-subtitle">Valor de venta usuario</p>

      <div className="financial-card-container">
        <FinancialCard
          title="Valor inmueble (final programa)"
          value={propPayments[propPayments.length - 1]["Precio del inmueble"]}
          type="money"
          tooltip="Precio al cual se le venderá el inmueble al inquilino al final del proyecto"
        />

        <FinancialCard
          title="Venta parcial durante programa"
          value={propPayments[propPayments.length - 2]["Ownership($)"]}
          type="money"
          tooltip="Suma de todos los aportes que el inquilino habrá dado para la compra del inmueble durante el programa"
        />

        <FinancialCard
          title="Venta final (saldo a pagar)"
          value={
            propPayments[propPayments.length - 1]["Precio del inmueble"] -
            propPayments[propPayments.length - 2]["Ownership($)"]
          }
          type="money"
          tooltip="Valor que el inquilino pagará con un crédito para la compra del inmueble al final del programa para la compra del inmueble"
        />
      </div>
    </div>
  );
}
