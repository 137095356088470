import React from "react";
import { Row, Col, Container } from "react-bootstrap";

export default function NotFound() {
	return (
		<Container fluid className="success-container">
			<Row className="justify-content-center align-items-center">
				<Col md={5}>
					<h1 className="bold">404</h1>
					<h1>La pagina que esás buscando no está aquí</h1>
				</Col>
			</Row>
		</Container>
	);
}
