import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';
import Map, { Marker } from 'react-map-gl';

export function PropMap(props) {
  return (
    <div className='prop-map-container'>
      <Map
        initialViewState={{
          longitude: parseFloat(props.longitude),
          latitude: parseFloat(props.latitude),
          zoom: 15,
        }}
        mapStyle='mapbox://styles/mapbox/streets-v9'
        style={{ width: '100%', height: '100%' }}>
        <Marker
          key={props.id}
          longitude={parseFloat(props.longitude)}
          latitude={parseFloat(props.latitude)}></Marker>
      </Map>
    </div>
  );
}
