import React from "react";

export default function Alert(props) {
  return (
    <div id="alert-container">
      <div className={`alert ${props.type}`}>
        <p>{props.alert}</p>
      </div>
    </div>
  );
}
