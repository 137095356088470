import React, { useState } from 'react';
import { Col, Row } from "react-bootstrap";
import axios from 'axios';

export function ImageUploader() {
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageUpload = (event) => {
    const newImages = Array.from(event.target.files);
    setImages(newImages);
  }

  const handleUpload = () => {
    const currentImage = images[currentImageIndex];
    const formData = new FormData();
    formData.append('image', currentImage);

    axios.post('/investments_api/investments/upload_image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log(response.data);
      if (currentImageIndex < images.length - 1) {
        setCurrentImageIndex(currentImageIndex + 1);
        handleUpload();
      }
    })
    .catch(error => {
      console.log(error);
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    handleUpload();
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={12}>
            <p className="fs-5 dark-purple">
              <b className="fs-4">Cargar imágenes: </b>
            </p>
          </Col>
          <Col md={12} className="mform-form-container">
            <input type="file" onChange={handleImageUpload} />
            <button className="btn-rounded-green mt-3" type="submit">
              Cargar imagen
            </button>
          </Col>
			</Row>

      </form>
    </div>
  );
}
