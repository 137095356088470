import React from 'react';
import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';

export function DueDiligence() {
  const params = useParams();

  return (
    <div className='mb-5 w-100'>
      <h4 className='invest-title'> Debida Diligencia</h4>

      <div className='due-diligence'>
        <a
          href={`https://toperty-investments.s3.amazonaws.com/properties/${params.id}/Pagos+Inquilino.xlsx`}
          target='_blank'
          rel='noreferrer'
          onClick={(e) => {
            ReactGA.event({
              action: 'tenant_payments_viewed',
              category: 'info_financial',
              label: 'application',
              value: 'viewed',
            });
            const url = `https://toperty-investments.s3.amazonaws.com/properties/${params.id}/Flujo+de+caja+inversionistas.xlsx`;
            window.open(url, '_blank');
          }}>
          Flujos de caja inversionistas
        </a>

        <a
          href={`https://toperty-investments.s3.amazonaws.com/properties/${params.id}/Avalu%CC%81o+comercial.pdf`}
          target='_blank'
          rel='noreferrer'>
          Avalúo comercial
        </a>

        <a
          href={`https://toperty-investments.s3.amazonaws.com/properties/${params.id}/Informe+de+inspección+técnica.pdf`}
          target='_blank'
          rel='noreferrer'>
          Informe de inspección técnica
        </a>

        <a
          href={`https://toperty-investments.s3.amazonaws.com/properties/${params.id}/Estudio+pre-juridico.pdf`}
          target='_blank'
          rel='noreferrer'>
          Estudio pre-juridico
        </a>

        <a
          href={`https://toperty-investments.s3.amazonaws.com/properties/${params.id}/Certificado+de+tradición+y+libertad.pdf`}
          target='_blank'
          rel='noreferrer'>
          Certificado de tradición y libertad
        </a>

        <a
          href={`https://toperty-investments.s3.amazonaws.com/properties/${params.id}/Estudio+de+títulos.pdf`}
          target='_blank'
          rel='noreferrer'>
          Estudio de títulos
        </a>
      </div>
    </div>
  );
}
