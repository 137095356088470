export const titles = [
  {
    name: "Baños",
    apiName: "bathrooms",
    row: "bathrooms",
  },
  {
    name: "Habitaciones",
    apiName: "bedrooms",
    row: "rooms",
  },
  {
    name: "Area Construida (m²)",
    apiName: "total_area",
    row: "area",
  },
  {
    name: "Estrato",
    apiName: "economic_classification",
    row: "economic_class",
  },
  {
    name: "Parqueadero",
    apiName: "parking_spaces",
    row: "parking",
  },
  {
    name: "Tipo de Propiedad",
    apiName: "prop_type",
    row: "prop-type",
  },
  {
    name: "Piso",
    apiName: "floor",
    row: "floor",
  },
  {
    name: "Administración",
    apiName: "administration_cost",
    row: "admin-cost",
  },
  {
    name: "Antigüedad",
    apiName: "antiquity",
    row: "antiquity",
  },
];

export const prop_types = {
  "apartment":"Apartamento",
  "house": "Casa"
}