import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Card } from "../../Components/Card";
import { FinancialCard } from "../../Components/FinancialCard";
import { InvestorsTable } from "../../Components/Table";
import { colors, monthsDict } from "../../utils/colorsPallete";
import { StoreContext } from "../../utils/store";
import { useParams } from "react-router-dom";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { EffectCoverflow, Pagination } from "swiper";

function PrepareTableData(data) {
  const arrayOfObjects = data.date.map((_, index) => {
    let obj = {};
    for (let key in data) {
      obj[key] = data[key][index];
    }
    return obj;
  });
  return arrayOfObjects;
}
export function Portfolio() {
  const SummaryEndpoint = "/investments_api/investors/payments";
  const EstimatedReturnsEndpoint =
    "/investments_api/investors/portfolio_details";
  const UnsoldPortfolioEndpoint = "/investments_api/investors/portfolio_unsold";
  const AssociatedPropertiesEndpoint =
    "/investments_api/investors/investments_info";
  const PortfolioSalesEndpoint =
    "/investments_api/investors/portfolio_completion";
  const AvailableYearsEndpoint =
    "/investments_api/investors/investment/available_years";
  const investmentsInvestorsEndpoint = "/investments_api/investors";

  const params = useParams();
  const initColumns = [
    {
      Header: "Mes",
      accessor: "date",
    },
  ];
  const [summaryDataColumns, setSummaryDataColumns] = useState(initColumns);
  const [summaryData, setSummaryData] = useState(null);
  const [availableYears, setAvailableYears] = useState(null);
  const [incomesChartData, setIncomesChartData] = useState({
    ChartLabels: null,
    values: null,
    associatedColors: null,
  });
  const [enabledArrows, setEnabledArrows] = useState({
    right_arrow: false,
    left_arrow: true,
  });
  const { loggedIn, setLoggedIn } = useContext(StoreContext);
  const { profile, setProfile } = useContext(StoreContext);
  const [cardsData, setCardsData] = useState({
    DividendsReceived: 0,
    EstimatedReturn: 0,
  });
  const [associatedProperties, setAssociatedProperties] = useState(null);
  const [portfolioSalesData, setPortfolioSalesData] = useState(null);
  const [unsoldPortfolioValue, setUnsoldPortfolioValue] = useState(null);
  const [yearData, setYearData] = useState(null);
  let COPLocale = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });

  const GenerateChartData = (idx, associatedColors) => {
    let SerieValues = [];
    for (const [key, values] of Object.entries(summaryData)) {
      if (key !== "date") {
        SerieValues.push({
          value: values[summaryData["date"].indexOf(idx)],
          color: associatedColors[key],
        });
      }
    }
    return SerieValues;
  };
  const hasYear = (value) => {
    return availableYears.some((item) => value === item);
  };
  const increaseYear = () => {
    if (hasYear(yearData + 1)) {
      setYearData(yearData + 1);
    }
  };
  const decreaseYear = () => {
    if (hasYear(yearData - 1)) {
      setYearData(yearData - 1);
    }
  };

  useEffect(() => {
    if (params.registration_user) {
      async function getData() {
        await axios
          .get(
            investmentsInvestorsEndpoint +
              "/confirm_signup?registration_user=" +
              params.registration_user
          )
          .then((response) => {
            setLoggedIn(true);
            localStorage.setItem("loggedIn", true);
            localStorage.setItem("profile", JSON.stringify(response.data));
            setProfile(response.data);
            return response.data;
          });
      }
      getData();
    }
  }, [params]);

  useEffect(() => {
    const getUnsoldValue = async () => {
      axios
        .get(UnsoldPortfolioEndpoint + "?id=" + profile.id)
        .then((response) => {
          setUnsoldPortfolioValue(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (profile.id) {
      getUnsoldValue();
    }
  }, [profile.id]);

  useEffect(() => {
    if (summaryData) {
      let associatedColors = {};
      let idx = 0;
      let data = [];
      let emptyMonths = [];
      for (const key of Object.keys(summaryData)) {
        if (key !== "date") {
          associatedColors[key] = colors[idx];
          idx += 1;
        }
      }
      Object.keys(summaryData).forEach((element, index) => {
        if (element !== "date") {
          emptyMonths.push({ value: 0, color: colors[index] });
        }
      });

      for (let i = 1; i <= Object.keys(monthsDict).length; i++) {
        let processedData = {};
        if (summaryData["date"].includes(i)) {
          processedData = {
            name: i,
            values: GenerateChartData(i, associatedColors),
          };
        } else {
          processedData = {
            name: i,
            values: emptyMonths,
          };
        }
        data.push(processedData);
      }

      setIncomesChartData({
        ...incomesChartData,
        ["associatedColors"]: associatedColors,
        ["values"]: data,
        ["ChartLabels"]: summaryData["date"],
      });
    }
  }, [summaryData]);

  useEffect(() => {
    const GetAvailableYears = async () => {
      await axios
        .get(AvailableYearsEndpoint + "?id=" + profile.id)
        .then((response) => {
          console.log(profile.id);
          setAvailableYears(response.data["response"]);
          setYearData(
            response.data["response"][response.data["response"].length - 1]
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const GetPropertiesData = async () => {
      await axios
        .get(AssociatedPropertiesEndpoint + "?id=" + profile.id)
        .then((response) => {
          setAssociatedProperties(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const GetPortfolioSalesData = async () => {
      await axios
        .get(PortfolioSalesEndpoint + "?id=" + profile.id)
        .then((response) => {
          setPortfolioSalesData(response.data[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const GetEstimatedReturn = async () => {
      await axios
        .get(EstimatedReturnsEndpoint + "?id=" + profile.id)
        .then((response) => {
          setCardsData({
            ...cardsData,
            ["EstimatedReturn"]: response.data.reduce(function (
              accumulator,
              currentValue
            ) {
              return accumulator + currentValue["portfolio_estimated_return"];
            },
            0),
            ["InvestedAmount"]: response.data[0]["total_investment"],
            ["DividendsReceived"]:
              response.data[0]["total_dividends"].toFixed(0),
            ["LastPaymentDate"]: response.data[0]["last_payment_date"],
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    GetAvailableYears();
    GetPropertiesData();
    GetEstimatedReturn();
    GetPortfolioSalesData();
  }, [profile.id]);

  useEffect(() => {
    const GetSummarySectionData = async () => {
      console.log("getting...", yearData, profile);
      await axios
        .get(SummaryEndpoint + "?id=" + profile.id + "&year=" + yearData)
        .then((response) => {
          setSummaryData(response.data);
          let newColumns = [...initColumns];
          Object.keys(response.data).forEach((key) => {
            if (key !== "date") {
              newColumns.push({ Header: key, accessor: key });
            }
          });
          setSummaryDataColumns(newColumns);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (yearData) {
      GetSummarySectionData();
    }
  }, [yearData, profile.id]);
  useEffect(() => {
    if (availableYears) {
      setEnabledArrows({
        ...enabledArrows,
        ["right_arrow"]: hasYear(yearData + 1),
        ["left_arrow"]: hasYear(yearData - 1),
      });
    }
  }, [yearData, profile.id]);

  return (
    <div className="portfolio-container">
      {summaryData && summaryDataColumns && cardsData["LastPaymentDate"] && (
        <>
          <section>
            <h4 className="page-title">Portafolio</h4>
            <p className="section-title">Rendimientos inversiones </p>
            {cardsData["LastPaymentDate"] && (
              <p className="date-title date">
                Última fecha de actualización:{" "}
                {cardsData["LastPaymentDate"].replace(/-/gi, "/")}
              </p>
            )}
            <p className="incomes-chart-title">{yearData}</p>
            {summaryData && summaryDataColumns && (
              <div className="report-table-container">
                <button
                  className="chart-control prev"
                  disabled={!enabledArrows["left_arrow"]}
                  onClick={decreaseYear}
                >
                  <div className="icon"></div>
                </button>
                <div className="table-container">
                  <InvestorsTable
                    columns={summaryDataColumns}
                    data={PrepareTableData(summaryData)}
                  />
                </div>
                <button
                  className="chart-control"
                  disabled={!enabledArrows["right_arrow"]}
                  onClick={increaseYear}
                >
                  <div className="icon"></div>
                </button>
              </div>
            )}
          </section>
        </>
      )}
      <section>
        <p className="section-title date">Resumen</p>
        {summaryData && unsoldPortfolioValue && (
          <div className="financial-card-container">
            <FinancialCard
              key="Monto invertido"
              title="Monto invertido"
              tooltip=""
              value={
                cardsData["InvestedAmount"]
                  ? COPLocale.format(cardsData["InvestedAmount"])
                  : "- -"
              }
            />
            <FinancialCard
              key="Pagos recibidos"
              title="Pagos recibidos"
              tooltip=""
              value={
                cardsData["DividendsReceived"]
                  ? COPLocale.format(cardsData["DividendsReceived"])
                  : "$-,---,---"
              }
            />
            <FinancialCard
              key="Rentabilidad estimada"
              title="Rentabilidad estimada"
              tooltip=""
              value={
                cardsData["EstimatedReturn"]
                  ? (cardsData["EstimatedReturn"] * 100).toFixed(2) + "%"
                  : "- -"
              }
            />
          </div>
        )}
      </section>
      <>
        <p className="section-title">Detalle del portafolio</p>
        <p className="date-title date">
          Ver detalle de las participaciones que tienes activas.
        </p>
        <div className="sales-container">
          <div className="cards-container">
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={2}
              loop={true}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              pagination={true}
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper"
            >
              {associatedProperties ? (
                <>
                  {associatedProperties.map((CardInfo) => {
                    return (
                      <SwiperSlide key={CardInfo.id}>
                        <Card
                          key={CardInfo.id}
                          type="invest"
                          invest={
                            CardInfo.money_invested < CardInfo.acquisition_cost
                              ? "Abierta"
                              : "Financiada"
                          }
                          title={CardInfo.investment_name}
                          address={CardInfo.address}
                          images_location={CardInfo.images_location}
                          invested={COPLocale.format(CardInfo.money_invested)}
                          cost={COPLocale.format(CardInfo.acquisition_cost)}
                          link="PortfolioInvestment"
                          id={CardInfo.id}
                          investmentDuration={CardInfo["investment_duration"]}
                          portfolioCard={true}
                          isVisible={true}
                        />
                      </SwiperSlide>
                    );
                  })}
                  <SwiperSlide>
                    <Card
                      type="invest"
                      invest="Pronto"
                      link="PortfolioInvestment"
                      page="portfolio"
                      portfolioCard={true}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Card
                      type="invest"
                      invest="Pronto"
                      link="PortfolioInvestment"
                      page="portfolio"
                      portfolioCard={true}
                    />
                  </SwiperSlide>
                </>
              ) : (
                [...Array(4)].map((_, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Card
                        key={index}
                        type="invest"
                        invest="Pronto"
                        link="PortfolioInvestment"
                        id={index + 1}
                        page="portfolio"
                        portfolioCard={true}
                      />
                    </SwiperSlide>
                  );
                })
              )}
            </Swiper>
          </div>
        </div>
      </>
    </div>
  );
}
