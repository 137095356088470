import { usePagination, useTable } from "react-table";
import TablePagination from "./Pagination";
import { monthsDict } from "../utils/colorsPallete";

function finalRow(array, position) {
  let sum = 0;
  array.map((element) => {
    sum += element["cells"][position]["value"];
  });
  return sum;
}
export function Table({ columns, data }) {
  // Use the useTable Hook to send the columns and data to build the table
  const columnsFormat = {
    cop: [
      "Abono a capital",
      "Pago de arriendo",
      "Pago total",
      "Rendimiento por arriendo",
      "Adquisición($)",
    ],
    percent: ["Adquisición(%)"],
  };
  let COPLocale = Intl.NumberFormat("es-CO");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 12 },
    },
    usePagination
  );

  return (
    <div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (columnsFormat.cop.includes(cell.column.Header)) {
                    return (
                      <td {...cell.getCellProps()}>
                        ${COPLocale.format(cell.value.toFixed(0))}
                      </td>
                    );
                  } else if (
                    columnsFormat.percent.includes(cell.column.Header)
                  ) {
                    return (
                      <td {...cell.getCellProps()}>
                        {(cell.value * 100).toFixed(1)}%
                      </td>
                    );
                  }
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
          <tr className="table-footer">
            <td>Total año</td>
            <td>${COPLocale.format(finalRow(page, 1).toFixed(0))}</td>
            <td>${COPLocale.format(finalRow(page, 2).toFixed(0))}</td>
            <td>${COPLocale.format(finalRow(page, 3).toFixed(0))}</td>
            <td>${COPLocale.format(finalRow(page, 4).toFixed(0))}</td>
            <td>
              $
              {COPLocale.format(
                page[page.length - 1]["cells"][5]["value"].toFixed(0)
              )}
            </td>
            <td>
              {(page[page.length - 1]["cells"][6]["value"] * 100).toFixed(1)}%
            </td>
          </tr>
        </tbody>
      </table>
      <TablePagination
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
      />
    </div>
  );
}

export function FinancialTable({ columns, data }) {
  // Use the useTable Hook to send the columns and data to build the table
  const columnsFormat = {
    cop: [
      "Ingreso por arriendo",
      "Venta parcial inmueble",
      "Ingreso total",
      "Costos operativos",
      "Flujo distribuible",
    ],
    percent: ["Venta acumulada (%)"],
  };
  let COPLocale = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 12 },
    },
    usePagination
  );

  return (
    <div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (columnsFormat.cop.includes(cell.column.Header)) {
                    return (
                      <td {...cell.getCellProps()}>
                        {COPLocale.format(cell.value.toFixed(0))}
                      </td>
                    );
                  } else if (
                    columnsFormat.percent.includes(cell.column.Header)
                  ) {
                    return (
                      <td {...cell.getCellProps()}>
                        {(cell.value * 100).toFixed(1)}%
                      </td>
                    );
                  }
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
          <tr className="table-footer">
            <td>Total año</td>
            <td>{COPLocale.format(finalRow(page, 1).toFixed(0))}</td>
            <td>{COPLocale.format(finalRow(page, 2).toFixed(0))}</td>
            <td>{COPLocale.format(finalRow(page, 3).toFixed(0))}</td>
            <td>{COPLocale.format(finalRow(page, 4).toFixed(0))}</td>
            <td>{COPLocale.format(finalRow(page, 5).toFixed(0))}</td>
          </tr>
        </tbody>
      </table>
      <TablePagination
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
      />
    </div>
  );
}

export function InvestorsTable({ columns, data }) {
  // Use the useTable Hook to send the columns and data to build the table
  if (!isNaN(data[0]["date"])) {
    for (let i = 0; i < data.length; i++) {
      data[i]["date"] = monthsDict[data[i]["date"]];
    }
  }
  const columnsFormat = {
    cop: columns
      .filter((column) => column.accessor !== "date")
      .map((column) => column.accessor),
  };
  let COPLocale = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 12 },
    },
    usePagination
  );

  return (
    <div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (columnsFormat.cop.includes(cell.column.Header)) {
                    return (
                      <td {...cell.getCellProps()}>
                        {COPLocale.format(cell.value.toFixed(0))}
                      </td>
                    );
                  }
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
