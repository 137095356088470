import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FinancialCard } from "../../Components/FinancialCard";
import { InvestorsTable } from "../../Components/Table";
import DonutChart from "../../Components/PortfolioSalesChart";
import { colors, monthsDict } from "../../utils/colorsPallete";
import { StoreContext } from "../../utils/store";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { useParams } from "react-router-dom";
function PrepareTableData(data) {
  const arrayOfObjects = data.date.map((_, index) => {
    let obj = {};
    for (let key in data) {
      obj[key] = data[key][index];
    }
    return obj;
  });
  return arrayOfObjects;
}

export function InvestmentOpportunity() {
  const params = useParams();
  const ColumnNames = {
    total_incomes: "Ingreso Total",
    rent: "Arriendo",
    principal: "Compra Parcial Inmueble",
    total_costs: "Descuentos Inversionista",
  };
  const initColumns = [
    {
      Header: "Mes",
      accessor: "date",
    },
  ];
  const [paymentsDataColumns, setPaymentsDataColumns] = useState(initColumns);
  const PaymentsEndpoint = "/investments_api/investors/investment/payments";
  const DetailsEndpoint = "/investments_api/investors/investment/details";
  const UnsoldPropertyEndpoint =
    "/investments_api/investors/investment/portfolio_unsold";
  const AvailableYearsEndpoint =
    "/investments_api/investors/specifyc_investment/available_years";
  const keyProgramDataEndpoint = "/investments_api/investors/key_program_data";
  const [keyProgramData, setKeyProgramData] = useState(null);
  const [paymentsData, setPaymentsData] = useState(null);
  const [availableYears, setAvailableYears] = useState(null);
  const [yearData, setYearData] = useState(null);
  const [unsoldPropertyValue, setUnsoldPropertyValue] = useState(null);

  const { profile } = useContext(StoreContext);
  const [cardsData, setCardsData] = useState({
    DividendsReceived: 0,
    EstimatedReturn: 0,
  });
  const [enabledArrows, setEnabledArrows] = useState({
    right_arrow: false,
    left_arrow: true,
  });
  const [incomesChartData, setIncomesChartData] = useState({
    ChartLabels: null,
    values: null,
    associatedColors: null,
  });
  let COPLocale = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });

  const hasYear = (value) => {
    return availableYears.some((item) => value === item);
  };
  const increaseYear = () => {
    if (hasYear(yearData + 1)) {
      setYearData(yearData + 1);
    }
  };
  const decreaseYear = () => {
    if (hasYear(yearData - 1)) {
      setYearData(yearData - 1);
    }
  };
  function GetMonthsElapsed(date) {
    const current_date = new Date();
    let LastDate = new Date(date);
    let months;
    months = (current_date.getFullYear() - LastDate.getFullYear()) * 12;
    months -= LastDate.getMonth();
    months += current_date.getMonth();

    if (current_date.getDate() > LastDate.getDate()) {
      months++;
    }
    return months === 0 ? 1 : months;
  }
  useEffect(() => {
    const getUnsoldValue = async () => {
      axios
        .get(
          UnsoldPropertyEndpoint +
            "?id=" +
            profile.id +
            "&prop_id=" +
            params.prop_id
        )
        .then((response) => {
          setUnsoldPropertyValue(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (profile.id) {
      getUnsoldValue();
    }
  }, [profile.id, params]);
  useEffect(() => {
    const GetAvailableYears = async () => {
      axios
        .get(
          AvailableYearsEndpoint +
            "?id=" +
            profile.id +
            "&prop_id=" +
            params.prop_id
        )
        .then((response) => {
          setAvailableYears(response.data["response"]);
          setYearData(
            response.data["response"][response.data["response"].length - 1]
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const GetDetailsData = async () => {
      await axios
        .get(
          DetailsEndpoint + "?id=" + profile.id + "&prop_id=" + params.prop_id
        )
        .then((response) => {
          setCardsData({
            ...cardsData,
            ["InvestmentName"]: response.data["investment_name"],
            ["EstimatedReturn"]: response.data["real_irr"],
            ["InvestedAmount"]: response.data["money_invested"],
            ["EstimatedDuration"]: response.data["estimated_duration"],
            ["CompletionPercentage"]: response.data["completion_percentage"],
            ["DividendsReceived"]: response.data["total_dividends"].toFixed(0),
            ["LastPaymentDate"]: response.data["last_payment_date"],
            ["UnsoldValue"]: response.data["unsold_value"],
            ["TargetOwnershipPercentage"]:
              response.data["target_ownership_percentage"],
            ["TotalInversion"]: response.data["total_inversion"],
            ["PropertyMoneyInvested"]: response.data["property_money_invested"],
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const GetKeyProgramData = async () => {
      await axios
        .get(keyProgramDataEndpoint + "?prop_id=" + params.prop_id)
        .then((response) => {
          setKeyProgramData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    GetAvailableYears();
    GetKeyProgramData();
    GetDetailsData();
  }, [params.prop_id, profile.id]);

  useEffect(() => {
    const GetPaymentsData = async () => {
      await axios
        .get(
          PaymentsEndpoint +
            "?id=" +
            profile.id +
            "&prop_id=" +
            params.prop_id +
            "&year=" +
            yearData
        )
        .then((response) => {
          setPaymentsData({
            ...paymentsData,
            ["date"]: response.data["date"],
            ["Arriendo"]: response.data["rent"],
            ["Compra Parcial Inmueble"]: response.data["principal"],
            ["Ingreso Total"]: response.data["total_incomes"],
            ["Descuentos Inversionista"]: response.data["total_costs"],
          });
          let newColumns = [...initColumns];
          Object.keys(response.data).forEach((key) => {
            if (!["date", "refunds", "recoveries"].includes(key)) {
              newColumns.push({
                Header: ColumnNames[key],
                accessor: ColumnNames[key],
              });
            }
          });
          setPaymentsDataColumns(newColumns);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    GetPaymentsData();
  }, [params.prop_id, yearData]);

  const GenerateChartData = (idx, associatedColors) => {
    let SerieValues = [];
    for (const [key, values] of Object.entries(paymentsData)) {
      if (key !== "date") {
        SerieValues.push({
          value: values[paymentsData["date"].indexOf(idx)],
          color: associatedColors[key],
        });
      }
    }

    return SerieValues;
  };

  useEffect(() => {
    if (paymentsData) {
      let associatedColors = {};
      let idx = 0;
      let data = [];
      let emptyMonths = [];
      for (const key of Object.keys(paymentsData)) {
        if (key !== "date") {
          associatedColors[key] = colors[idx];
          idx += 1;
        }
      }
      Object.keys(paymentsData).forEach((element, index) => {
        if (element !== "date") {
          emptyMonths.push({ value: 0, color: colors[index] });
        }
      });

      for (let i = 1; i <= Object.keys(monthsDict).length; i++) {
        let processedData = {};
        if (paymentsData["date"].includes(i)) {
          processedData = {
            name: i,
            values: GenerateChartData(i, associatedColors),
          };
        } else {
          processedData = {
            name: i,
            values: emptyMonths,
          };
        }
        data.push(processedData);
      }
      setIncomesChartData({
        ...incomesChartData,
        ["associatedColors"]: associatedColors,
        ["values"]: data,
        ["ChartLabels"]: paymentsData["date"],
      });
    }
  }, [paymentsData]);

  useEffect(() => {
    if (availableYears) {
      setEnabledArrows({
        ...enabledArrows,
        ["right_arrow"]: hasYear(yearData + 1),
        ["left_arrow"]: hasYear(yearData - 1),
      });
    }
  }, [yearData]);

  return (
    <div className="portfolio-container">
      <p className="page-title">{cardsData["InvestmentName"]}</p>
      {paymentsDataColumns && paymentsData && (
        <div className="row">
          <p className="section-title">Pagos recibidos</p>
          <a
            href={"/investment/" + params.prop_id + "/"}
            target="_blank"
            rel="noreferrer"
          >
            Ver inmueble
          </a>
          <p className="incomes-chart-title">{yearData}</p>
          <div className="report-table-container">
            <button
              className="chart-control prev"
              disabled={!enabledArrows["left_arrow"]}
              onClick={decreaseYear}
            >
              <div className="icon"></div>
            </button>
            <div class="table-container">
              <InvestorsTable
                columns={paymentsDataColumns}
                data={PrepareTableData(paymentsData)}
              />
            </div>
            <button
              className="chart-control"
              disabled={!enabledArrows["right_arrow"]}
              onClick={increaseYear}
            >
              <div className="icon"></div>
            </button>
          </div>
        </div>
      )}
      <section>
        <p className="section-title date">Resumen</p>
        {cardsData && unsoldPropertyValue && (
          <div className="financial-card-container">
            <FinancialCard
              key="Monto invertido"
              title="Monto invertido"
              tooltip=""
              value={COPLocale.format(cardsData["InvestedAmount"])}
            />
            <FinancialCard
              key="Suma de pagos recibidos"
              title="Suma de pagos recibidos"
              tooltip=""
              value={COPLocale.format(cardsData["DividendsReceived"])}
            />
            <FinancialCard
              key="Rentabilidad estimada"
              title="Rentabilidad estimada"
              tooltip=""
              value={(cardsData["EstimatedReturn"] * 100).toFixed(2) + "%"}
            />
            <FinancialCard
              key="Meses transcurridos"
              title="Meses transcurridos"
              tooltip=""
              value={
                keyProgramData
                  ? GetMonthsElapsed(keyProgramData["first_payment_date"])
                  : "- -"
              }
            />
            <FinancialCard
              key="Porcentaje del inmueble"
              title="Porcentaje del inmueble"
              tooltip=""
              value={
                cardsData["PropertyMoneyInvested"]
                  ? (
                      (cardsData["InvestedAmount"] /
                        cardsData["PropertyMoneyInvested"]) *
                      100
                    ).toFixed(2) + "%"
                  : "-.--%"
              }
            />
          </div>
        )}
      </section>
      <div className="row">
        <p className="section-title">Progreso del programa</p>
        <p className="title p4">
          Objetivo de adquisición usuario:{" "}
          {(cardsData["TargetOwnershipPercentage"] * 100).toFixed(1)}%
        </p>
        <div className="sales-container" id="opportunity-sales">
          {cardsData && (
            <div className="sales-card">
              <div className="donut-chart-container">
                <DonutChart
                  percentFilled={(
                    cardsData["CompletionPercentage"] * 100
                  ).toFixed(1)}
                  textContent={(
                    cardsData["CompletionPercentage"] * 100
                  ).toFixed(1)}
                  startAngle={cardsData ? -90 : 45}
                  filledColor={
                    cardsData ? "rgba(40, 5, 65, 1)" : "rgba(185, 185, 185, 1)"
                  }
                />
              </div>
              <div className="sales-data-container">
                <p className="title p4">
                  Porcentaje adquirido por parte del usuario respecto al
                  objetivo de adquisición.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
