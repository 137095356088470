import { React, useContext } from "react";
import { StoreContext } from "../utils/store";

export function Card(props) {
  let COPLocale = Intl.NumberFormat("es-CO");
  let link = "/" + props.link + "/" + props.id;
  props.min_principal_payment
    ? (link += "/" + props.min_principal_payment)
    : (link += "/");
  const { loggedIn, setShowLogin } = useContext(StoreContext);

  return (
    <>
      {props.invest === "Pronto" ? (
        <div className={`Pronto invest-card ${props.page}`}>
          <div className="prop-img"></div>
          <div className="tag">
            <p>{props.invest}</p>
          </div>
          <div className="prop-title">
            <span className="dark"></span>
            <span className="dark"></span>
          </div>
          <div className="prop-info">
            {props.page !== "portfolio" && (
              <>
                <div className="line">
                  <div className="text"></div>
                  <div className="text"></div>
                </div>
                <div className="line">
                  <div className="text"></div>
                  <div className="text"></div>
                </div>
              </>
            )}

            <div className="line">
              <div className="text"></div>
              <div className="text"></div>
            </div>
          </div>
          <div className="prop-footer">
            <div className="progress-bar sm mb-1">
              <div className="progress"></div>
            </div>
            <div className="justify-content-between progress-info">
              <div></div>
              <div></div>
            </div>
          </div>
          <button disabled="disabled" className="btn-rounded-gray">
            Próximamente
          </button>
        </div>
      ) : (
        <a
          href={link}
          onClick={(e) => {
            if (!loggedIn) {
              e.preventDefault();
              setShowLogin(true);
            }
          }}
          className="prop-link"
          style={{
            pointerEvents:
              props.invest === "Financiada" && !props.isVisible
                ? "none"
                : "auto",
          }}
        >
          <div className={`${props.invest} invest-card`} id={props.id}>
            <div className="prop-img">
              <img src={`${props.images_location}1.jpg`} alt="imagen" />
            </div>
            {props.type === "invest" && (
              <>
                {!props.isVisible && (
                  <div className="tag">
                    <p className="p5">{props.invest}</p>
                  </div>
                )}
                <div className="prop-title">
                  <h5 className="bold p3 ">{props.title}</h5>
                </div>
              </>
            )}

            <div className="prop-info">
              {props.type === "invest" ? (
                <>
                  {props.return && (
                    <>
                      <div>
                        <p className="subtitle p6">Retorno</p>
                      </div>
                      <div className="">
                        <h5 className="text-end p3">{props.return}</h5>
                      </div>
                    </>
                  )}
                  {props.term && (
                    <>
                      <div className="">
                        <p className="subtitle p6">Duración</p>
                      </div>
                      <div className="">
                        <h5 className="text-end p3">{props.term}</h5>
                      </div>
                    </>
                  )}
                  {props.minimal && (
                    <>
                      <div className="">
                        <p className="subtitle p6">Inversión mínima</p>
                      </div>
                      <div className="">
                        <h5 className="text-end p3">{props.minimal}</h5>
                      </div>
                    </>
                  )}
                  {props.invested && (
                    <>
                      <div className="">
                        <p className="subtitle p6">Inversión</p>
                      </div>
                      <div className="">
                        <h5 className="text-end p3">{props.invested}</h5>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <p className="subtitle pago-title">Precio total:</p>
                  <h5 className="bold price">
                    ${COPLocale.format(props.market_value)}
                  </h5>
                  <p className="subtitle pago-title">
                    Compra sin cuota inicial desde:
                  </p>
                  <h5 className="bold price">
                    ${COPLocale.format(props.rent_to_own_price)} por mes
                  </h5>
                  <p>{props.city_name}</p>
                </>
              )}
            </div>
            {props.percentage && (
              <div className="prop-footer">
                <div className="progress-bar sm">
                  <div
                    className="progress"
                    style={{
                      width:
                        props.percentage > 0 ? props.percentage + "%" : "1em",
                    }}
                  ></div>
                </div>
                <div className="justify-content-around progress-info">
                  <p>{props.percentage.toFixed(1) + "%"} invertido</p>
                  <p>{props.cost}</p>
                </div>
              </div>
            )}
            {props.completionPercentage && (
              <div className="prop-footer">
                <div className="progress-bar sm">
                  <div
                    className="progress"
                    style={{
                      width:
                        props.completionPercentage > 0
                          ? props.completionPercentage + "%"
                          : "1em",
                    }}
                  ></div>
                </div>
                <div className="justify-content-around progress-info">
                  <p>{props.completionPercentage.toFixed(1) + "%"}</p>
                  <p>{props.investmentDuration} meses</p>
                </div>
              </div>
            )}
            <button
              type="button"
              className="btn-rounded-gray"
              onClick={(e) => {
                if (!loggedIn) {
                  e.preventDefault();
                  setShowLogin(true);
                } else {
                  e.preventDefault();
                  window.location.href = link;
                }
              }}
            >
              {window.location.href.includes("/portfolio")
                ? "Ver inversión"
                : "Ver más"}
            </button>
          </div>
        </a>
      )}
    </>
  );
}
