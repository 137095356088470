export const generalMetrics = [
  {
    name: "Retorno estimado (Post-tax)",
    apiName: "real_irr",
    type: "percentage",
    tooltip:
      "Tasa interna de retorno esperada de la inversión después del pago " +
      "de impuestos",
  },
  {
    name: "Money multiple",
    apiName: "money_multiple",
    type: "text",
    tooltip:
      "Valor por el cuál se multplicará el valor nominal del monto " +
      "invertido a lo largo del programa",
  },
  {
    name: "Inversión total",
    apiName: "money_invested",
    type: "money",
    tooltip:
      "Total a invertir para este proyecto, se calcula como: precio" +
      " pactado del inmueble más gastos de cierre de la compra del inmueble menos" +
      " cuota inicial aportada por el usuario.",
  },
];

export const commercialMetrics = [
  {
    name: "Avalúo comercial ",
    apiName: "opportunity_market_value",
    type: "money",
    tooltip: "Precio por el cual se ha avaluado este inmueble por un tercero",
  },
  {
    name: "Valor de compra",
    apiName: "toperty_valuation",
    type: "money",
    tooltip:
      "Precio de compra que Toperty ha pactado con el dueño del inmueble",
  },
];

export const returnMetrics = [
  {
    name: "Going-in Yield Año 1 (Mensual / Anual)",
    apiName: "investment_yield",
    type: "multiple_percentage",
    tooltip:
      "Ingresos brutos recibidos mensualmente por parte del inquilino  " +
      "parte del usuario dividido la inversión total",
  },
  {
    name: "Going-in Cap Rate (Mensual / Anual)",
    apiName: "cap_rate",
    type: "multiple_percentage",
    tooltip:
      "Ingresos netos de gastos operativos recibidos mensualmente por " +
      "parte del inquilino dividido la inversión total",
  },
  {
    name: "Estructura de capital (Equity)",
    apiName: "equity_particiátion",
    type: "multiple_percentage",
    tooltip:
      "Porcentaje de la inversión que será financiada con equity" +
      " (el porcentaje restante será financiado con deuda)",
  },
  {
    name: "Retornos Anuales Estimados (Post Tax)",
    apiName: "real_irr",
    type: "percentage",
    tooltip:
      "Tasa de retorno estimada de la inversión expresada como tasa " +
      "efectiva anual",
  },
];

export const sellingMetrics = [
  {
    name: "Valor inmueble (final programa)",
    apiName: "selling_value",
    type: "money",
    tooltip:
      "Precio por el cual se venderá el inmueble al inquilino al final" +
      " del programa",
  },
  {
    name: "Venta parcial durante programa",
    apiName: "partial_bought_value",
    type: "money",
    tooltip:
      "Suma de todos los aportes que el inquilino habrá dado para la" +
      " compra del inmueble durante el programa",
  },
];
