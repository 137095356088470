import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { StoreContext } from '../../utils/store';
import { loginInteractions } from './LoginInteractions';
import { emailValidation } from './LoginValidations';
import { PasswordForgotten } from './PasswordForgotten';
import { Registered } from './Registered';

export function Login(props) {
  const { setLoggedIn } = useContext(StoreContext);
  const [investorData, setInvestorData] = useState({});
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [dataPolicyError, setDataPolicyError] = useState(false);
  const [loginConfirmationError, setLoginConfirmationError] = useState(false);
  const [loginAPIError, setLoginAPIError] = useState(false);
  const [state, setState] = useState('');
  const { user, setUser } = useContext(StoreContext);
  const { profile, setProfile } = useContext(StoreContext);

  const handleDataPolicy = () => {
    setIsChecked(isChecked === false ? true : false)
  }
  const handleFormData = (k, v) => {
    setInvestorData({
      ...investorData,
      [k]: v,
    });
  };

  function showRegister() {
    setState('register');
  }

  function showLogin() {
    setState('login');
  }

  function confirmLogin(investorData) {
    setLoggedIn(true);
    localStorage.setItem('loggedIn', true);
    setProfile(investorData);
    localStorage.setItem('profile', JSON.stringify(investorData));
    props.handleClose();
  }

  function showLoginError() {
    setLoginError(true);
  }

  function showLoginConfirmationError() {
    setLoginConfirmationError(true);
  }

  function showLoginAPIError() {
    setLoginAPIError(true);
  }

  function finishRegsitry() {
    setState('registered');
  }
  function showForgotPassword(e) {
    e.preventDefault();
    setState('forgot_password');
  }

  function finishForgotPassword() {
    setState('forgotten_password');
  }

  let interactions = new loginInteractions({
    showRegister: showRegister,
    showLogin: showLogin,
    confirmLogin: confirmLogin,
    showLoginError: showLoginError,
    showLoginConfirmationError: showLoginConfirmationError,
    showLoginAPIError: showLoginAPIError,
    finishForgotPassword: finishForgotPassword,
    finishRegsitry: finishRegsitry,
    handleClose: props.handleClose,
  });

  async function checkEmail(e) {
    e.preventDefault();
    let success = true;
    if (!isChecked) {
      setDataPolicyError(true);
      success = false;
    }
    else {
      setDataPolicyError(false);
    }
    if (!emailValidation(investorData['email'])) {
      setEmailError(true);
      success = false;
    } else {
      setEmailError(false);
    }
    if (success) {
      await interactions.checkEmail(investorData['email']);
    }
  }

  async function handleRegister(e) {
    e.preventDefault();
    if (investorData['password'] !== investorData['confirm_password']) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
      await interactions.register(investorData);
    }
  }

  async function handleLogin(e) {
    e.preventDefault();
    setLoginError(false);
    await interactions.login(investorData['email'], investorData['password']);
  }

  async function handleForgotPassword(e) {
    e.preventDefault();
    await interactions.forgotPassword(investorData['email']);
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setLoggedIn(true);
      localStorage.setItem('loggedIn', true);
      setUser(codeResponse);
      props.handleClose();
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json',
            },
          }
        )
        .then((res) => {
          interactions.storeEmail(res.data["email"]);
          setProfile(res.data);
        })
        .catch((err) => {
          return;
        });
    }
  }, [user]);

  return (
    <div className='login-container'>
      <div className='login-form'>
        {state === '' && (<h4 className='title'>Inicia sesión o regístrate</h4>)}
        {state === 'login' && <h4 className='title'>Inicia sesión</h4>}
        {state === 'register' && <h4 className='title'>Regístrate</h4>}
        {state === 'forgot_password' && (
          <h4 className='title'>Reestablecer contraseña</h4>
        )}

        <Form>
          <Form.Group controlId='correo'>
            {state === "" && (<div>
              <button
                type="button"
                className='google-btn mt-3'
                onClick={() => isChecked ? googleLogin() : setDataPolicyError(true)}>
                <img
                  className='google-icon'
                  src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg'
                  alt='Google logo'
                />
                <span>Continuar con Google</span>
              </button>
              <hr />

            </div>)

            }
            {state !== "registered" && state !== "forgotten_password" && (<Form.Control
              type='text'
              name=''
              className='rounded-input mt-2'
              placeholder='Email'
              onChange={(e) => handleFormData('email', e.target.value)}
              disabled={state !== ""}
            />)}
            {state === "" && (<div className='form-group'>
              <input
                type='checkbox'
                name='policy'
                id='policy'
                value={isChecked}
                onChange={() => handleDataPolicy()}
              />
              <label
                className='data-policy'
                htmlFor='policy'>
                Al continuar, aceptas nuestras <a href='/tyc'>Condiciones</a>{' '}
                y nuestra <a href='personal-data'>Policita de privacidad</a>
              </label>
            </div>)}
            {emailError && (
              <div className='error-msg'>
                <p>No has ingresado una dirección de correo válida</p>
              </div>
            )}
            {dataPolicyError && (
              <div className='error-msg'>
                <p>
                  Para poder continuar debes aceptar nuestras Condiciones y
                  Policitas de privacidad.
                </p>
              </div>
            )}
            {state === '' && (
              <div>
                <button
                  className='btn-rounded-invert w-100 py-3 mt-1'
                  onClick={checkEmail}>
                  Continuar
                </button>
              </div>
            )}
          </Form.Group>

          {state === 'login' && (
            <div>
              <Form.Group
                className='mb-3'
                controlId='contraseña'>
                <Form.Control
                  type='password'
                  name=''
                  className='rounded-input my-3'
                  placeholder='Contraseña'
                  autoComplete='off'
                  onChange={(e) => handleFormData('password', e.target.value)}
                />
              </Form.Group>
              {loginError && (
                <div className='error-msg'>
                  <p>
                    La contraseña ingresada no es correcta, por favor ingrésala
                    de nuevo
                  </p>
                </div>
              )}

              {loginAPIError && (
                <div className='error-msg'>
                  <p>
                    Algo ha fallado con nuestro sistema, inténtalo de nuevo en
                    unas horas.
                  </p>
                </div>
              )}

              {loginConfirmationError && (
                <div className='error-msg'>
                  <p>
                    No has confirmado la creación de tu cuenta. Ingresa a tu
                    correo y entra al correo de confirmación.
                  </p>
                </div>
              )}

              <button
                className='btn-rounded-green w-100 py-3'
                onClick={handleLogin}>
                Ingresa a tu cuenta
              </button>
              <button
                className='btn-rounded-invert w-100 py-3 mt-1'
                onClick={showForgotPassword}>
                ¿Olvidaste tu contraseña?
              </button>
            </div>
          )}

          {state === 'register' && (
            <div>
              <Form.Group
                className='mb-3'
                controlId='contraseña'>
                <Form.Control
                  type='password'
                  name=''
                  className='rounded-input my-3'
                  placeholder='Contraseña'
                  autoComplete='off'
                  onChange={(e) => handleFormData('password', e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className='mb-3'
                controlId='confirm'>
                <Form.Control
                  type='password'
                  name=''
                  className='rounded-input my-3'
                  placeholder='Confirmar contraseña'
                  autoComplete='off'
                  onChange={(e) =>
                    handleFormData('confirm_password', e.target.value)
                  }
                />
                {passwordError && (
                  <div className='error-msg'>
                    <p>
                      Las contraseñas no coinciden, por favor ingrésalas de
                      nuevo
                    </p>
                  </div>
                )}
              </Form.Group>
              <button
                className='btn-rounded-invert'
                onClick={handleRegister}>
                Continuar
              </button>
            </div>
          )}
          {state === 'forgot_password' && (
            <div>
              <button
                className='btn-rounded-green w-100 py-3'
                onClick={handleForgotPassword}>
                Enviar
              </button>
            </div>
          )}
          {state === 'forgotten_password' && (
            <PasswordForgotten handleClose={props.handleClose} />
          )}
          {state === 'registered' && (
            <Registered handleClose={props.handleClose} />
          )}
        </Form>
      </div>
    </div>
  );
}
