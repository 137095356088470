import React from 'react';

export function Registered(props) {
  return (
    <div className='login-container'>
      <div className='login-form'>
        <h4 className=''>Ultimo paso</h4>
        <h4 className='supertitle'>¡Revisa tu correo!</h4>
        <p className='p2'>
          Te hemos enviado un correo para activar tu cuenta. Puede estar en tu
          bandeja de entrada o tu bandeja de spam.
        </p>
        <button
          className='btn-rounded-invert w-100 py-3'
          onClick={() => props.handleClose()}>
          Cerrar
        </button>
      </div>
    </div>
  );
}
