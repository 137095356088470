export function Validations(investorData) {
  console.log(investorData);
  let errors = [];
  const emailValidation = new RegExp(
    "^$|^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
  );
  if (!emailValidation.test(investorData["email"])) {
    errors.push("email");
  }
  const namevalidation = new RegExp("^$|^[A-Za-zÀ-ÖØ-öø-ÿs\\s]+$");
  if (!namevalidation.test(investorData["name"])) {
    errors.push("name");
  }

  if (investorData["password"]) {
    if (investorData["password"] !== investorData["confirm_password"]) {
      errors.push("password");
    }
  }

  return errors;
}
