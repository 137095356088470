import React from "react";
import { Input } from "../../../Components/Input";

export function Credentials(props) {
  const investorData = props.investorData;
  const handleInvestorData = props.handleInvestorData;
  const inputErrors = props.inputErrors;
  const errorMessages = props.errorMessages;
  const showPasswordError = props.showPasswordError;

  return (
    <div className="profile-content">
      <div className="profile-input-container">
        <Input
          inputLabel="Email"
          inputType="mail"
          inputKey={"email"}
          disabled={true}
          inputValue={investorData["email"]}
          formData={investorData}
          handleFormData={handleInvestorData}
        />
      </div>
      {inputErrors.includes("email") && (
        <p className="error-msj">{errorMessages["email"]}</p>
      )}
      <div className="profile-input-container">
        <Input
          inputLabel="Contraseña actual"
          inputType="password"
          inputKey={"current_password"}
          formData={investorData}
          handleFormData={handleInvestorData}
          showPasswordError={showPasswordError}
        />
      </div>

      {inputErrors.includes("wrong_password") && (
        <p className="error-msj">{errorMessages["wrong_password"]}</p>
      )}
      <div className="pass-container">
        <div className="profile-input-container">
          <Input
            inputLabel="Nueva contraseña"
            inputType="password"
            inputKey={"password"}
            formData={investorData}
            handleFormData={handleInvestorData}
          />
        </div>
        <div className="profile-input-container">
          <Input
            inputLabel="Confirmar contraseña"
            inputType="password"
            inputKey={"confirm_password"}
            formData={investorData}
            handleFormData={handleInvestorData}
          />
          {inputErrors.includes("password") && (
            <p className="error-msj">{errorMessages["password"]}</p>
          )}
        </div>
      </div>
    </div>
  );
}
