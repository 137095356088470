import React from "react";
import { Row, Container, Col } from "react-bootstrap";


export function Success() {
  return (
    <>
      <Container fluid className="p-sm-3 success-container">
        <Row className="align-items-center text-center justify-content-center">
          <Col md={6}>
            <h1 className="supertitle bolder ">Gracias</h1>
            <h3 className="mt-4">
              Datos enviados exitosamente.
            </h3>
          </Col>
        </Row>
      </Container>
    </>
  );
}
