import axios from "axios";
import React from "react";

export class successForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.cityEndpoint = "/api/city/id";
    this.agentEndpoint = "/api/agent/";
    this.investmentsPropertyEndpoint = "/investments_api/investments";
    this.investmentsTenantEndpoint = "/investments_api/tenants";
    this.propertyEndpoint = "/api/property/";
    this.imagesEndpoint = "/api/property/images";
    this.logoEndpoint = "/api/agent/image";
    this.cityId = "";
  }

  async searchInvestmentProperty(investmentName) {
    let searchUrl = `${this.investmentsPropertyEndpoint}?investment_name=
			${investmentName}`;
    const propData = await axios
      .get(searchUrl)
      .then((response) => {
        console.log(response.data[0]["InvestmentProperty"]);
        this.tenantId = response.data[0]["InvestmentProperty"]["tenant_id"];
        return response.data[0]["InvestmentProperty"];
      })
      .catch(function (error) {
        this.tenantId = "";
      });

    return propData;
  }

  async searchInvestmentTenant() {
    let searchUrl = `${this.investmentsTenantEndpoint}?tenant_id=
			${this.tenantId}`;
    const tenantData = await axios
      .get(searchUrl)
      .then((response) => {
        this.tenantId = response.data.tenant_id;
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
    return tenantData;
  }

  async postTenant(tenantData) {
    tenantData["car"] = tenantData["assets"].includes("Carro");
    tenantData["house"] = tenantData["assets"].includes("Vivienda");
    tenantData["motorcycle"] = tenantData["assets"].includes("Motocicleta");
    tenantData["commercial_property"] =
      tenantData["assets"].includes("Inmueble comercial");

    delete tenantData["assets"];
    let tenantKeys = Object.keys(tenantData);

    for (const key of tenantKeys) {
      if (tenantData[key] === "") {
        delete tenantData[key];
      }
    }
    const tenantId = await axios
      .post(this.investmentsTenantEndpoint, tenantData)
      .then((response) => {
        this.tenantId = response.data.id;
      });
    return tenantId;
  }

  async postAgent(propertyData) {
    this.down_payment = propertyData.down_payment;
    let agentData = {
      first_name: propertyData["agent"],
      last_name: "",
      email: propertyData["email"],
      user_type_id: "2",
      phone: propertyData["phone"],
      logo_location:
        "https://toperty-marketplace.s3.amazonaws.com/agents/7.jpeg",
    };

    const propId = await axios
      .post(this.agentEndpoint, agentData)
      .then((response) => {
        this.agentId = response.data.id;
      });
    return propId;
  }

  async postProperty(propertyData) {
    let nowDate = new Date();
    await axios
      .get(this.cityEndpoint + "?city_name=" + propertyData.city)
      .then((response) => {
        this.cityId = response.data.id;
      })
      .catch(function (error) {});
    const amenities = {
      lift: propertyData["amenities"].includes("Ascensor"),
      residential_security: propertyData["amenities"].includes("Seguridad"),
      private_deposit: propertyData["amenities"].includes("Depósito"),
      gym: propertyData["amenities"].includes("Gimnasio"),
      terrace: propertyData["amenities"].includes("Terraza"),
      community_room: propertyData["amenities"].includes("Salón comunal"),
      visitor_parking: propertyData["amenities"].includes(
        "Parqueadero visitantes"
      ),
      urban_green_spaces: propertyData["amenities"].includes("Zonas verdes"),
      laundry_room: propertyData["amenities"].includes("Lavandería"),
      childrens_area: propertyData["amenities"].includes("Parque infantil"),
      pool: propertyData["amenities"].includes("Piscina"),
      sauna: propertyData["amenities"].includes("Sauna"),
      squash_court: propertyData["amenities"].includes("cancha de squash"),
    };

    let newPropertyData = {
      agent_id: this.agentId,
      city_id: this.cityId,
      prop_type_id: propertyData["type"] === "Apartamento" ? 1 : 2,
      tenant_id: this.tenantId,
      investment_name: propertyData["investment_name"],
      creation_date: nowDate.toISOString(),
      latitude: "",
      longitude: "",
      bathrooms: propertyData["bathrooms"],
      bedrooms: propertyData["rooms"],
      living_area: propertyData["living_area"],
      total_area: propertyData["private_area"],
      market_value: propertyData["cost"],
      description: propertyData["description"],
      images_location: "",
      images_number: propertyData["images_number"],
      economic_classification: propertyData["economic_class"],
      parking_spaces: propertyData["parkings"],
      address: propertyData["address"],
      floor: propertyData["floor"],
      antiquity: propertyData["construction_date"],
      lift: amenities["lift"],
      terrace: amenities["terrace"],
      residential_security: amenities["residential_security"],
      private_deposit: amenities["private_deposit"],
      gym: amenities["gym"],
      community_room: amenities["community_room"],
      visitor_parking: amenities["visitor_parking"],
      urban_green_spaces: amenities["urban_green_spaces"],
      laundry_room: amenities["laundry_room"],
      childrens_area: amenities["childrens_area"],
      pool: amenities["pool"],
      sauna: amenities["sauna"],
      squash_court: amenities["squash_court"],
      administration_fee: propertyData["administration"],
      acquisition_cost: propertyData["acquisition_cost"],
      toperty_valuation: propertyData["toperty_valuation"],
    };
    let propertyKeys = Object.keys(newPropertyData);
    for (const key of propertyKeys) {
      if (newPropertyData[key] === "") {
        delete newPropertyData[key];
      }
    }
    await axios
      .post(this.investmentsPropertyEndpoint, newPropertyData)
      .then((response) => {
        this.propertyId = response.data.id;
      });
  }

  async postImages(propertyData) {
    let imagesData = propertyData["photos"].map((data) => data.image_url);
    await axios.post(
      this.imagesEndpoint + "?property_id=" + this.propertyId,
      imagesData
    );
  }

  async postAgentLogo(propertyData) {
    await axios.post(this.logoEndpoint, null, {
      params: {
        image: propertyData["logo"],
        agent_id: this.agentId,
      },
    });
  }

  async main(tenantData, propertyData) {
    await this.postTenant(tenantData);
    //await this.postAgent(data);
    //wait this.postAgentLogo(data);
    await this.postProperty(propertyData);
    //await this.postImages(data)
  }
}
