export const financialColumns = [
  {
    Header: "Cuota",
    accessor: "Mes",
  },
  {
    Header: "Ingreso por arriendo",
    accessor: "Pago de arriendo",
  },
  {
    Header: "Venta parcial inmueble",
    accessor: "Abono a capital",
  },
  {
    Header: "Ingreso total",
    accessor: "Pago total",
  },
  {
    Header: "Costos operativos",
    accessor: "Costos",
  },
  {
    Header: "Flujo distribuible",
    accessor: "Utilidades",
  },
];
