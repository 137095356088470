import React from 'react';

export function Amenities(props) {
  return (
    <div className='info-lg-container'>
      {props.amenities.map(({ title, apiName }) => {
        if (props.propData[apiName]) {
          return (
            <div key={title} className='info-lg'>
              <div className='ammenitie '>
                <div className='icon'></div>
                <p className='bold p5'>{title}</p>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}
