import React from 'react';
import { Input } from '../../../Components/Input';
import { bankingData } from '../../../utils/investorProfileFeatures';

export function BankingInfo(props) {
  const investorData = props.investorData;
  const inputErrors = props.inputErrors;
  const errorMessages = props.errorMessages;
  const handleInvestorData = props.handleInvestorData;

  return (
    <div className='profile-content'>
      {bankingData.map((inputElement) => {
        return (
          <div
            className='profile-input-container'
            key={inputElement['inputKey']}>
            <Input
              inputLabel={inputElement['inputLabel']}
              key={inputElement['inputId']}
              inputType={inputElement['inputType']}
              inputKey={inputElement['inputKey']}
              select={inputElement['options']}
              inputId={inputElement['inputId']}
              inputValue={investorData[inputElement['inputKey']]}
              formData={investorData}
              handleFormData={handleInvestorData}
            />
            {inputErrors.includes(inputElement['inputKey']) && (
              <p className='error-msj'>
                {errorMessages[inputElement['inputKey']]}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
}
