export const colors = [
      'rgba(40, 5, 65, 1)', 'rgba(83, 148, 208, 1)', 'rgba(0, 193, 175, 1)',
      "rgba(245, 245, 245, 1)", "rgba(201, 207, 218, 1)",
      "rgba(176, 186, 201, 1)", "rgba(158, 158, 158, 1)"
    ]

export const unavailableColors = [
  "rgba(158, 158, 158, 1)", "rgba(185, 185, 185, 1)", "rgba(230, 230, 230, 1)"
]

export const monthsDict = {
  1:'Ene', 2:'Feb', 3:'Mar', 4:'Abr', 5:'May', 6:'Jun', 7:'Jul', 8:'Ago',
  9:'Sep', 10:'Oct', 11:'Nov', 12:'Dic'
}
