import React from 'react';

export function InfoPropCard(props) {
  let COPLocale = Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    maximumFractionDigits: 0,
  });
  var value =
    props.concept === 'rent_to_own_price' ||
    props.concept === 'market_value' ||
    props.concept === 'administration_fee' ||
    props.concept === 'toperty_valuation'
      ? COPLocale.format(props.info)
      : props.concept === 'Adquisición'
      ? '20% / ' + COPLocale.format(props.market_value * 0.2)
      : props.concept === 'estimated_duration'
      ? '52-54 meses'
      : props.info;
  return (
    <>
      <div className='info-lg'>
        <div className={props.row}>
          <div className='icon'></div>
          <>
            <p className='bold p5 dark-purple'>{value}</p>
            <p className='title p7'>{props.title}</p>
          </>
        </div>
      </div>
    </>
  );
}
