import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NotificationModal } from "../Components//ModalView";
import Alert from "../Components/Alert";
import { Card } from "../Components/Card";
import { InvestModal as Modal } from "../Components/InvestModal";
import { StoreContext } from "../utils/store";

export function InvestmentMarkeplace(props) {
  const params = useParams();
  const { loggedIn, setLoggedIn } = useContext(StoreContext);
  const { profile, setProfile } = useContext(StoreContext);
  const [cardsData, setCardsData] = useState(null);
  const investmentsInvestorsEndpoint = "/investments_api/investors";
  let COPLocale = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumFractionDigits: 0,
  });
  const [show, setShow] = useState(false);
  const [showRegisteredModal, setShowRegisteredModal] = useState(false);
  const closeModal = () => {
    setShow(false);
  };

  useEffect(() => {
    const fetch = async () => {
      const InvestmentsEndpoint = "/investments_api/investments/all";
      await axios
        .get(InvestmentsEndpoint)
        .then((response) => {
          setCardsData(response.data);
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    };
    if (!loggedIn) {
      setShow(true);
    }
    fetch();
  }, []);

  useEffect(() => {
    if (params.registration_user) {
      setShow(false);
      async function getData() {
        await axios
          .get(
            investmentsInvestorsEndpoint +
              "/confirm_signup?registration_user=" +
              params.registration_user
          )
          .then((response) => {
            setLoggedIn(true);
            localStorage.setItem("loggedIn", true);
            localStorage.setItem("profile", JSON.stringify(response.data));
            setProfile(response.data);
            setShowRegisteredModal(true);
            return response.data;
          });
      }
      getData();
    }
  }, [params]);

  return (
    <div className="marketplace-container">
      {showRegisteredModal && <Alert alert={"Te has registrado con éxito"} />}

      <Modal show={show} handleClose={closeModal} type="tutorial" />
      <div className="cards-container">
        <h4 className="mt-5 mb-4 w-100">Oportunidades</h4>
        {cardsData &&
          cardsData.map((card) => {
            return (
              <Card
                key={card.id}
                type="invest"
                invest={
                  card.money_invested < card.acquisition_cost
                    ? "Abierta"
                    : "Financiada"
                }
                return={(card.real_irr * 100).toFixed(2) + "%"}
                title={card.investment_name}
                term={card.investment_duration + " Meses"}
                images_location={card.images_location}
                minimal={COPLocale.format(
                  Math.ceil(card.money_invested / 19 / 100000) * 100000
                )}
                percentage={(100 * card.money_invested) / card.acquisition_cost}
                cost={COPLocale.format(card.money_invested)}
                link="investment"
                id={card.id}
              />
            );
          })}
        {cardsData &&
          cardsData.length < 6 &&
          Array.from({
            length: 6 - cardsData.length,
          }).map((_, index) => (
            <Card
              key={index}
              type="invest"
              invest="Pronto"
              link="investment"
              id={index + 1}
            />
          ))}
      </div>
    </div>
  );
}
