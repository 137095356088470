export const labels = [
    {
        name: "Casa",
        apiName: "house",
        id:1
    },
    {
        name: "Apartamento",
        apiName: "apartment",
        id:2
    }
];
